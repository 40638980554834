import { Typography } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export const Placeholder: FC<{
  title: ReactNode;
  text: ReactNode;
  icon: ReactNode;
  action?: ReactNode;
  className?: string;
}> = ({ title, text, icon, action, className }) => {
  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'px-[100px]',
        className,
      )}
    >
      <span className="mb-3 text-[32px] leading-[38px]">{icon}</span>
      <Typography variant="body1" mb={0.25} align="center">
        {title}
      </Typography>
      <Typography variant="body2" align="center">
        <span className="opacity-[0.48]">{text}</span>
      </Typography>
      {action && (
        <div className="mt-5 flex flex-col items-center gap-2">{action}</div>
      )}
    </div>
  );
};
