import { AppContext } from '../../AppContext';
import API from '../../api';
import { SavedQuestionnaireDtoLegalFormEnum } from '../../api/generated';
import { routePaths } from '../../router';
import { ClientError } from '../ClientError';
import { LegalForm } from './components/LegalForm';
import { Stepper } from './components/Stepper';
import { CircularProgress, Typography } from '@mui/material';
import { PageContainer } from 'components/PageContainer';
import { useFormik } from 'formik';
import { useMerchant } from 'queries/merchant/useMerchant';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

export const NewApplication = () => {
  const { data: merchant, isFetching: isMerchantFetching } = useMerchant();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);

  const legalFormik = useFormik<{
    legalForm: SavedQuestionnaireDtoLegalFormEnum;
  }>({
    initialValues: {
      legalForm: 'COMPANY',
    },
    onSubmit: async (values) => {
      try {
        let result;
        if (values.legalForm === 'COMPANY') {
          result = await API.Merchant.createCompanyApplication({
            questionnaire: {},
          });
        } else {
          result = await API.Merchant.createIndividualApplication({
            questionnaire: {},
          });
        }

        if (result.data.data) {
          navigate(
            generatePath(routePaths.APPLICATION, {
              id: result.data.data.id,
            }),
          );
        } else {
          throw Error;
        }
      } catch (e) {
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      }
    },
  });

  if (isMerchantFetching) {
    return (
      <PageContainer narrow stretch={false} adaptive>
        <div className="h-[550px] flex items-center justify-center">
          <CircularProgress />
        </div>
      </PageContainer>
    );
  }

  if (!merchant) {
    return (
      <PageContainer narrow stretch={false} adaptive>
        <ClientError />
      </PageContainer>
    );
  }

  return (
    <PageContainer narrow stretch={false} adaptive>
      <div className="py-4 lg:p-8">
        <Typography variant="h5">{t('questionnaire.title')}</Typography>
        <Stepper activeStep={0} />
        <LegalForm formik={legalFormik} />
      </div>
    </PageContainer>
  );
};
