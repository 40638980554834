import { Footer } from '../containers/Footer/Footer';
import { Header } from '../containers/Header';
import { Container, ContainerProps } from './Container';
import classNames from 'classnames';
import { FC } from 'react';

export const PageContainer: FC<ContainerProps & { stretch?: boolean }> = ({
  className,
  adaptive,
  stretch,
  ...restProps
}) => {
  return (
    <div
      className={classNames(
        'min-h-screen box-content flex flex-col',
        adaptive
          ? 'px-5 lg:bg-grayBg lg:px-10 lg:min-w-min'
          : 'bg-grayBg px-10 min-w-min',
      )}
    >
      <Header adaptive={adaptive} />
      <main className="flex flex-col grow">
        <Container
          {...restProps}
          adaptive={adaptive}
          className={classNames(
            'bg-white',
            'rounded-2xl',
            stretch && 'grow',
            className,
          )}
        />
      </main>
      <Footer adaptive={adaptive} />
    </div>
  );
};
