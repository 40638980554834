import { TERMS_OF_USE_PATH } from '../../../config';
import { useMerchant } from '../../../queries/merchant/useMerchant';
import { LoadingButton } from '@mui/lab';
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { SavedQuestionnaireDtoLegalFormEnum } from 'api/generated';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const LegalForm: FC<{
  formik: FormikProps<{ legalForm: SavedQuestionnaireDtoLegalFormEnum }>;
}> = ({ formik }) => {
  const { t } = useTranslation();
  const { data: merchant } = useMerchant();

  if (!merchant) {
    return null;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLabel>{t('questionnaire.legal_form_title')}</FormLabel>
      <RadioGroup
        value={formik.values.legalForm}
        name="legalForm"
        onChange={formik.handleChange}
      >
        <FormControlLabel
          value={SavedQuestionnaireDtoLegalFormEnum.Company}
          control={<Radio />}
          label={t('questionnaire.company')}
        />
        <FormControlLabel
          value={SavedQuestionnaireDtoLegalFormEnum.Individual}
          control={<Radio />}
          label={t('questionnaire.individual')}
        />
      </RadioGroup>
      {merchant.status === 'NOT_APPROVED' && (
        <div className="mt-3">
          <Typography variant="body2" color="text.secondary">
            <Trans
              i18nKey="questionnaire.legal_disclaimer"
              t={t}
              components={[
                <Typography
                  component="a"
                  href={TERMS_OF_USE_PATH}
                  target="_blank"
                  key="terms"
                  variant="body2"
                  color="primary"
                />,
              ]}
            />
          </Typography>
        </div>
      )}
      <div className="mt-5">
        <LoadingButton
          variant="contained"
          size="large"
          type="submit"
          loading={formik.isSubmitting}
        >
          {t('common.next')}
        </LoadingButton>
      </div>
    </form>
  );
};
