import { Typography } from '@mui/material';
import { PageContainer } from 'components/PageContainer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const StoreError: FC<{ reason: 'NOT_FOUND' | 'ACCESS_DENIED' }> = ({
  reason,
}) => {
  const { t } = useTranslation();
  return (
    <PageContainer stretch className="justify-center">
      <Typography variant="h5">
        {reason === 'NOT_FOUND' && t('store.not_found')}
        {reason === 'ACCESS_DENIED' && t('store.access_denied')}
      </Typography>
    </PageContainer>
  );
};
