import { BOT_USERNAME, PRODUCTION_BOT_USERNAME } from './config';
import './i18n';
import { router, routes } from './router';
import { store } from './store';
import './styles.tailwind.css';
import { init, reactRouterV6Instrumentation, setTag } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from '@tanstack/react-query';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { queryClient } from 'queries/client';
import { useEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const isProduction = BOT_USERNAME === PRODUCTION_BOT_USERNAME;

const dsn = isProduction
  ? 'https://f06bb85740784985908fda7572382d06@sentry.walletbot.me/40'
  : 'https://4e467c24a2a9458aaeed0cf20e424ebc@sentry.walletbot.me/41';

if (!isProduction) {
  setTag('bot_username', BOT_USERNAME);
}

init({
  dsn,
  integrations: [
    new BrowserTracing({
      beforeNavigate(context) {
        // converting /store/238428947/TON into /store/:id/:currency

        let name = context.name;
        const matches = matchRoutes(routes, window.location.pathname);
        if (Array.isArray(matches)) {
          const lastMatch = matches[matches.length - 1];
          name = lastMatch.route.path;
        }
        return {
          ...context,
          name,
        };
      },
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1,
});

const persister = persistStore(store, {}, () => {
  render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  );
});
