import { BOT_USERNAME, withAppPrefix } from 'config';
import { FC, useEffect, useRef } from 'react';
import { Auth, TGUser, auth } from 'utils/auth';

interface CustomEventMap {
  'wpayMerchant-TGAuth': CustomEvent<TGUser>;
}

declare global {
  interface Window {
    wpayMerchantTgAuthCallback: (tgUser: TGUser) => void;
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): void;
  }
}

window.wpayMerchantTgAuthCallback = function (tgUser: TGUser) {
  window.dispatchEvent(
    new CustomEvent(withAppPrefix('TGAuth'), {
      detail: tgUser,
    }),
  );
};

export const LoginButton: FC<{
  onAuth: (auth: Auth) => void;
  onAuthStart: VoidFunction;
}> = ({ onAuth, onAuthStart }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onAuthWrapper = async (e: CustomEvent<TGUser>) => {
      onAuthStart();
      onAuth(await auth(e.detail));
    };
    window.addEventListener(withAppPrefix('TGAuth'), onAuthWrapper);

    return () => {
      window.removeEventListener(withAppPrefix('TGAuth'), onAuthWrapper);
    };
  }, [onAuth]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?21';
    script.setAttribute('data-telegram-login', BOT_USERNAME);
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-radius', '4');
    script.setAttribute('data-userpic', 'false');
    script.setAttribute('data-onauth', 'wpayMerchantTgAuthCallback(user)');
    script.setAttribute('data-request-access', 'write');

    if (ref.current) {
      ref.current.appendChild(script);
    }
  }, []);

  return <div className="h-[42.25px]" ref={ref} />;
};
