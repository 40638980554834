import { AssetBalance, AssetBalanceSkeleton } from './components/AssetBalance';
import { StoreContent } from './components/StoreContent';
import { WithdrawModal } from './components/WithdrawModal';
import { Withdrawals } from './components/Withdrawals';
import { LoadingButton } from '@mui/lab';
import { Breadcrumbs, Link as MUILink, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AvailableBalanceHint } from 'containers/AvailableBalanceHint';
import { useStore, useStoreIfExists } from 'queries/store/useStore';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import { routePaths } from 'router';
import { CurrencyEnum } from 'types';
import { decodeServerNumber } from 'utils/number';

export const StoreAssetBalance: FC = () => {
  const { currency, id } = useParams();
  const queryClient = useQueryClient();
  const [withdrawModalOpened, setWithdrawModalOpened] = useState(false);
  const { t } = useTranslation();
  const resolvedCurrency = currency as CurrencyEnum;
  const { data, refetch, isRefetching } = useStore(id);
  const store = useStoreIfExists(data);

  const balance =
    store &&
    store.balances.find((balance) => balance.currency === resolvedCurrency);

  const hasAvailableBalance =
    !!balance &&
    balance.availableForPayoutGross &&
    decodeServerNumber(balance.availableForPayoutGross) > 0;

  async function resetAndRefetchWithdrawals() {
    await Promise.all([
      refetch(),
      queryClient.invalidateQueries({ queryKey: ['withdrawalList'] }),
    ]);
  }

  return (
    <StoreContent className="flex flex-col">
      <Breadcrumbs aria-label="breadcrumb">
        <MUILink
          component={Link}
          underline="hover"
          color="inherit"
          to={generatePath(routePaths.STORE, { id })}
        >
          {t('store.balance')}
        </MUILink>
        <Typography color="text.primary">{resolvedCurrency}</Typography>
      </Breadcrumbs>
      <div className="flex items-center justify-between pt-5 pb-[26px] border-b mb-3">
        <div className="flex gap-4">
          {balance ? (
            <AssetBalance
              amount={decodeServerNumber(balance.total)}
              currency={resolvedCurrency}
              bottom={t('balance.total')}
            />
          ) : (
            <AssetBalanceSkeleton currency={resolvedCurrency} />
          )}
          {balance ? (
            <AssetBalance
              amount={decodeServerNumber(balance?.availableForPayoutGross)}
              currency={resolvedCurrency}
              bottom={t('balance.available')}
              bottomIcon={
                <AvailableBalanceHint
                  fundsHoldingDuration={store.fundsHoldingDuration}
                />
              }
            />
          ) : (
            <AssetBalanceSkeleton currency={resolvedCurrency} />
          )}
        </div>
        <LoadingButton
          loading={isRefetching}
          onClick={async () => {
            await refetch();
            setWithdrawModalOpened(true);
          }}
          variant="contained"
          size="large"
          disabled={!hasAvailableBalance}
        >
          {t('balance.withdraw')}
        </LoadingButton>
      </div>
      <Withdrawals
        storeId={store?.id}
        currency={resolvedCurrency}
        payoutFeePercent={store?.payoutFeePercent}
      />
      {hasAvailableBalance && (
        <WithdrawModal
          balance={balance}
          onSuccess={resetAndRefetchWithdrawals}
          open={withdrawModalOpened}
          onClose={() => setWithdrawModalOpened(false)}
        />
      )}
    </StoreContent>
  );
};
