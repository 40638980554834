import { useQuery } from '@tanstack/react-query';
import API from 'api';

export const useIpCountry = () => {
  return useQuery({
    queryKey: ['ip-country'],
    queryFn: async () => {
      return await API.Auth.getIpCountry();
    },
  });
};
