import classNames from 'classnames';
import { FC } from 'react';

export const StoreContent: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames('self-stretch', 'px-8', 'py-6', 'grow', className)}
    >
      {children}
    </div>
  );
};
