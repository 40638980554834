import { OpenInNew } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import API from 'api';
import { Placeholder } from 'components/Placeholder';
import {
  DEMO_BOT_LINK,
  DOCUMENTATION_LINK,
  TELEGRAM_STARS_DOCUMENTATION_LINK,
} from 'config';
import { useMerchant } from 'queries/merchant/useMerchant';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { merchantHomePage } from 'utils/merchant';

export const OnApproval: FC<{ applicationId: string }> = ({
  applicationId,
}) => {
  const { t } = useTranslation();
  const { data: merchant } = useMerchant();
  const queryClient = useQueryClient();
  const [canceling, setCanceling] = useState(false);

  if (!merchant) {
    return null;
  }

  const revokeButton = (
    <LoadingButton
      size="medium"
      type="button"
      color="error"
      loading={canceling}
      onClick={async () => {
        setCanceling(true);
        await API.Merchant.revokeApplication({ applicationId });
        await queryClient.invalidateQueries(['application']);
        setCanceling(false);
      }}
    >
      {t('on_approval.revoke')}
    </LoadingButton>
  );

  return (
    <div className="relative">
      <Placeholder
        className="py-8 lg:h-[550px]"
        title={t('on_approval.title')}
        text={t('on_approval.text')}
        icon="🕒"
        action={
          merchant.status === 'APPROVED' ? (
            <>
              <Button
                size="medium"
                component="a"
                target="_blank"
                href={DOCUMENTATION_LINK}
                endIcon={<OpenInNew />}
              >
                {t('on_approval.read_documentation')}
              </Button>
              <Button
                size="medium"
                component="a"
                target="_blank"
                href={TELEGRAM_STARS_DOCUMENTATION_LINK}
                endIcon={<OpenInNew />}
              >
                {t('on_approval.telegram_stars_documentation')}
              </Button>
              <Button
                size="medium"
                variant="contained"
                component={Link}
                to={merchantHomePage(merchant)}
              >
                {t('on_approval.back_to_wallet_pay')}
              </Button>
              {revokeButton}
            </>
          ) : (
            <>
              <Button
                size="medium"
                component="a"
                target="_blank"
                href={DOCUMENTATION_LINK}
                endIcon={<OpenInNew />}
              >
                {t('on_approval.read_documentation')}
              </Button>
              <Button
                size="medium"
                component="a"
                target="_blank"
                href={TELEGRAM_STARS_DOCUMENTATION_LINK}
                endIcon={<OpenInNew />}
              >
                {t('on_approval.telegram_stars_documentation')}
              </Button>
              <Button
                size="medium"
                component="a"
                target="_blank"
                href={DEMO_BOT_LINK}
                endIcon={<OpenInNew />}
              >
                {t('on_approval.try_demo')}
              </Button>
              {revokeButton}
            </>
          )
        }
      />
    </div>
  );
};
