import { ClientError } from '../ClientError';
import { Approved } from './components/Approved';
import { Declined } from './components/Declined';
import { DeclinedDueTelegramStars } from './components/DeclinedDueTelegramStars';
import { OnApproval } from './components/OnApproval';
import { Questionnaire } from './components/Questionnaire';
import { Revoked } from './components/Revoked';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  MerchantApplicationDto,
  MerchantApplicationDtoDeclineReasonEnum,
} from 'api/generated';
import { PageContainer } from 'components/PageContainer';
import { useApplication } from 'queries/merchant/useApplication';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

export const Application = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data: application, isInitialLoading: isApplicationInitialLoading } =
    useApplication(id as string);

  function renderDependOnApplicationStatus(
    application: MerchantApplicationDto,
  ): ReactNode {
    switch (application.status) {
      case 'APPROVED':
        return <Approved />;
      case 'ON_APPROVAL':
      case 'VERIFIED':
        return <OnApproval applicationId={application.id} />;
      case 'REVOKED':
        return <Revoked />;
      case 'DECLINED':
      case 'VERIFICATION_FAILED':
        if (
          application.declineReason ==
          MerchantApplicationDtoDeclineReasonEnum.UseTelegramStars
        ) {
          return <DeclinedDueTelegramStars />;
        } else {
          return <Declined />;
        }
      case 'DRAFT':
      case 'VERIFICATION_REQUIRED':
      case 'ON_VERIFICATION':
        return (
          <Questionnaire
            {...application.questionnaire}
            applicationId={application.id}
            applicationStatus={application.status}
            onFinish={() => {
              queryClient.invalidateQueries(['application']);
            }}
          />
        );
    }
  }

  if (isApplicationInitialLoading) {
    return (
      <PageContainer narrow stretch={false} adaptive>
        <div className="h-[550px] flex items-center justify-center">
          <CircularProgress />
        </div>
      </PageContainer>
    );
  }

  if (!application) {
    return (
      <PageContainer narrow stretch={false} adaptive>
        <ClientError />
      </PageContainer>
    );
  }

  return (
    <PageContainer narrow stretch={false} adaptive>
      {renderDependOnApplicationStatus(application)}
    </PageContainer>
  );
};
