export default {
  common: {
    download_csv: 'Download .csv',
    csv_date_ranger_error: 'Date range is too big',
    search: 'Search',
    date_range: 'Date Range',
    cancel: 'Cancel',
    done: 'Done',
    next: 'Next',
    back: 'Back',
    something_went_wrong: 'Something went wrong',
    fiat_unavailable: 'Fiat equivalent is unavailable',
    contact_support: 'Contact support',
  },
  questionnaire: {
    title: 'Fill Out Form',
    legal_form_step: 'Legal Form',
    general_step: 'General Information',
    about_step: 'About Business',
    kyc_step: 'KYC',
    legal_form_title: 'Select your status',
    company: 'Legal entity',
    individual: 'Individual customer',
    legal_disclaimer:
      'By clicking Next, you confirm your acceptance of <0>Terms of Use</0>.',
    company_phone: 'Phone Number of Representative',
    company_email: 'E-mail Address of Representative',
    company_address: 'Residential Address of Representative',
    individual_phone: 'Phone Number',
    individual_email: 'E-mail Address',
    individual_address: 'Residential Address',
    activity:
      'Business activity (Describe products or services that you offer)',
    activity_details: 'What products or services do you offer',
    purchase_amount: 'Expected average purchase amount (EUR-equivalent)',
    expected_revenue: 'Expected monthly turnover (EUR equivalent)',
    license: 'License № and issuing regulator (If applicable)',
    telegram:
      'Telegram URL of stores (Specify Telegram Bots through which you provide your services or sell products',
    marketing:
      'Marketing channels (Website, Telegram Channel, Twitter Account where you promote your products or services)',
    activities: {
      goods: 'Physical goods and stores (e.g. clothes, electronics, tickets)',
      services:
        'Services (any professional or business services, e.g. consulting, beauty services, bookkeeping)',
      digital_goods:
        'Digital goods (Content, software and games, in-game currency, except for subscriptions)',
      subscriptions: 'Subscriptions',
      betting: 'Licensed betting or gambling',
      other: 'Other',
    },
    subscriptions_warning:
      'Recurring payments are not available yet. However, you can utilize the current integration to sell subscriptions for future periods. We will notify you when recurring payments become available.',
    max_length_error:
      'Field value should not be longer than {{max}} characters',
  },
  logout_reasons: {
    EXPIRED: 'Your session has been expired',
  },
  client_error: {
    title: 'Oops.. Something went wrong',
    text: 'No worries, we are already aware of it',
  },
  not_found: {
    title: '404',
    text: 'Page not found',
  },
  declined: {
    title: 'Your application has been declined',
    text: 'Please contact support for detailed information.',
  },
  declined_due_to_telegram_stars: {
    title: 'Your application has been declined',
    text: 'Starting June 12, payments for digital goods and services must be processed through Telegram Stars. Therefore, your project can’t be integrated with Wallet Pay. Please refer to the Telegram Stars documentation for instructions on integrating with the new payment method.',
    telegram_stars_documentation: 'Telegram Stars documentation',
  },
  on_approval: {
    title: 'Your application is being reviewed ',
    text: 'We have temporarily paused the review process for new applicants. Your application will be reviewed if it meets the guidelines for using Wallet Pay and Telegram. This may take some time. While you wait for approval, explore these useful links:',
    back_to_wallet_pay: 'Back to wallet pay',
    read_documentation: 'Read Wallet Pay Documentation',
    telegram_stars_documentation: 'Learn more on Telegram Stars',
    try_demo: 'Try Demo',
    revoke: 'Cancel Application',
  },
  revoked: {
    title: 'Your application has been cancelled',
    text: 'You can submit the new one.',
    create: 'Submit',
  },
  approved: {
    title: 'Your application has been approved',
    first_application_text:
      'Proceed to use Wallet Pay. There is a {{paymentFeePercent}}% transaction fee and a {{payoutFeePercent}}% withdrawal fee applied.',
    text: 'Your profile has been updated. There is a {{paymentFeePercent}}% transaction fee and a {{payoutFeePercent}}% withdrawal fee applied.',
    go_to_wallet_pay: 'go to wallet pay',
  },
  header: {
    api_documents: 'Documentation',
    support: 'Support',
    faq: 'FAQ',
    logout: 'Sign out',
    verification_not_completed: 'Verification not completed',
    verification_completed: 'Verification completed',
    complete: 'Complete',
    change_info: 'Change info',
  },
  footer: {
    about: 'About',
    terms: 'Terms of use',
    privacy: 'Privacy',
  },
  welcome: {
    title: 'Start accepting crypto payments in Telegram',
    text: 'Track your orders statistics and easily withdraw your funds to Wallet',
    go_to_account: 'Go to the Account',
    create_store: 'Create Store',
    start_wallet: 'Start Wallet',
    access_denied: 'Access Denied',
  },
  not_wallet_user: {
    title: 'Open Wallet Bot',
    text: 'Press Start in the Bot to be able using\nWallet Pay',
    open: 'Open',
  },
  denied: {
    title: 'Access to Wallet Pay is denied',
    text: 'Please contact support for detailed information.',
  },
  create_store: {
    title: 'Create Store',
    name_hint: 'Any suitable name between {{min}} and {{max}} characters',
    name_placeholder: 'Enter Store Name',
    name_validation_min: 'Name must be at least {{min}} characters',
    name_validation_max: 'Name should not be more than {{max}} characters',
    bot_hint: '@name_of_the_bot or https://t.me/name_of_the_bot',
    bot_placeholder:
      'Enter Store URL (specify the link of the Telegram bot you use to provide services or sell products)',
    bot_validation:
      'Please enter the link in one of the following formats: @name_of_the_bot or https://t.me/name_of_the_bot',
    create: 'Create',
    created: '"{{ storeName }}" has been created',
  },
  store: {
    add: 'Add new store',
    create_new: 'Create New Store',
    setup_title: 'Set up API key to start using Wallet Pay',
    setup_text: 'You can always do this in Settings',
    setup_button: 'Set up',
    balance: 'Balance',
    orders: 'Order History',
    settings: 'Settings',
    not_found: 'Store not found',
    access_denied: 'Access to this store is denied',
  },
  settings: {
    store: 'Store settings',
    integration: 'Integration',
    store_name: 'Name',
    store_bot: 'Store URL',
    store_bot_hint:
      'Please contact <0>support</0> if you want to change URL of your store',
    save: 'Save',
    api: 'API',
    generate_api_key: 'Generate key',
    show_api_key: 'Show',
    hide_api_key: 'Hide',
    api_key_generated: 'Key has been generated',
    regenerate_api_key: 'Regenerate key',
    api_key_regenerated: 'Key has been regenerated',
    api_key_copied: 'Key has been copied',
    saved: 'Changes have been saved',
    documents: 'API Documentation',
    webhooks: 'Webhooks',
    enable_webhooks: 'Enable Webhooks',
    no_api_key_title: 'You don’t have a key yet',
    no_api_key_text: 'You can only have one key at a time',
    api_key: 'Key',
    api_copy: 'Copy',
    api_created: 'Created',
    callback_url: 'Callback URL',
  },
  orders: {
    search_placeholder: 'ID',
    external_id: 'External ID',
    order_id: 'Order ID',
    description: 'Description',
    created_at: 'Created At',
    order_amount: 'Order Amount',
    payment_gross: 'Payment Gross',
    payment_net: 'Payment Net',
    status: 'Status',
    payment_currency: 'Payment Currency',
    order_currency: 'Order Currency',
    active: 'Active',
    paid: 'Paid',
    expired: 'Expired',
    cancelled: 'Cancelled',
    no_orders_title: 'No orders yet',
    no_orders_text: 'Once you create an order, it will appear here',
  },
  balance: {
    assets: 'My assets',
    assets_description: 'Assets for withdrawal',
    total: 'Total Balance',
    available: 'Available for withdrawal',
    available_hint:
      'Newly received money is held\nfor {{fundsHoldingDuration}} for security reasons',
    withdraw: 'Withdraw',
    withdrawal_history: 'Withdrawal History',
    no_withdrawals_title: 'No withdrawals yet',
    no_withdrawals_text: 'Once you make a withdrawal, it will appear here',
  },
  withdrawal: {
    title: 'Withdrawal',
    text: 'You withdraw the total amount of ≈ <0>{{amount}}</0>',
    fee_text: 'Wallet Pay takes {{percent}}% of fee for each asset:',
    zero_fee_text: 'Withdraw fee is 0%',
    after_fee: 'After fee: ≈ <0>{{amount}}</0>',
    total: 'Total: ≈ <0>{{amount}}</0>',
    progress_title: 'Please wait',
    progress_text: 'Processing withdrawal...',
    success_title: "It's all set",
    success_text: 'Funds were withdrawn to your wallet',
  },
  withdrawals: {
    status: 'Status',
    currency: 'Currency',
    amount: 'Amount',
    amount_after_fee: 'After Fee',
    created_at: 'Created At',
    created: 'Pending',
    transferred: 'Paid',
    failed: 'Failed',
  },
};
