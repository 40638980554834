import { AppContext } from '../AppContext';
import { StorePreviewDto } from '../api/generated';
import { CreateStoreForm } from '../containers/CreateStoreForm';
import { setStoreId } from '../reducers/userSlice';
import { useAppDispatch } from '../store';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import API from 'api';
import { PageContainer } from 'components/PageContainer';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from 'react-router-dom';
import { routePaths } from 'router';

export const CreateStore: FC = () => {
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useContext(AppContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const create: (params: {
    name: string;
    telegramBotUsername: string;
  }) => unknown = async ({ name, telegramBotUsername }) => {
    setCreating(true);
    try {
      const createStoreResponse = await API.Store.createStore({
        name,
        telegramBotUsername,
      });
      if (createStoreResponse.data.status === 'SUCCESS') {
        const store = createStoreResponse.data.data as StorePreviewDto;
        dispatch(setStoreId(store.id));
        showSnackbar({
          autoHideDuration: 4000,
          message: t('create_store.created', { storeName: store.name }),
        });
        navigate({
          pathname: generatePath(routePaths.STORE, { id: store.id }),
          search: createSearchParams({
            onboarding: 'true',
          }).toString(),
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      showSnackbar({
        autoHideDuration: 2000,
        message: t('common.something_went_wrong'),
      });
    } finally {
      setCreating(false);
    }
  };

  return (
    <PageContainer narrow className="p-8">
      <Typography variant="h5" mb={4.5}>
        {t('create_store.title')}
      </Typography>

      <CreateStoreForm
        onSubmit={create}
        renderActions={({ submit, valid }) => {
          return (
            <div className="mt-6">
              <LoadingButton
                disabled={!valid}
                onClick={submit}
                type="button"
                loading={creating}
                variant="contained"
                size="large"
              >
                {t('create_store.create')}
              </LoadingButton>
            </div>
          );
        }}
      />
    </PageContainer>
  );
};
