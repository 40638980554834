import { Typography } from '@mui/material';
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import {
  GetStoreOrdersAsFileRestRequestStatusesEnum,
  GetStoreOrdersResponse,
} from 'api/generated';
import { useLocale } from 'hooks/useLocale';
import { ReactComponent as GridArrowBackwardSVG } from 'images/gridArrowBackward.svg';
import { ReactComponent as GridArrowForwardSVG } from 'images/gridArrowForward.svg';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isCrypto, printCryptoAmount, printFiatAmount } from 'utils/amount';

export const StoreOrdersTable: FC<{
  page: number;
  pageSize: number;
  isRefetching: boolean;
  updatePageSize: (newValue: number) => void;
  updatePage: (newValue: number) => void;
  ordersList: GetStoreOrdersResponse;
}> = ({
  page,
  pageSize,
  updatePage,
  updatePageSize,
  isRefetching,
  ordersList,
}) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridApi = useGridApiRef();
  const [leftGridArrowVisibility, setLeftGridArrowVisibility] =
    useState<boolean>(false);
  const [rightGridArrowVisibility, setRightGridArrowVisibility] =
    useState<boolean>(true);
  const [gridMaxLeftScrollPosition, setGridMaxLeftScrollPosition] =
    useState<number>(0);

  useEffect(() => {
    gridApi.current.subscribeEvent('virtualScrollerContentSizeChange', () => {
      const dimensions = gridApi.current.getRootDimensions();

      if (dimensions && gridContainerRef.current) {
        const gridInnerElement = gridContainerRef.current.querySelector(
          '.MuiDataGrid-virtualScrollerContent',
        );

        if (gridInnerElement) {
          setGridMaxLeftScrollPosition(
            (gridInnerElement as HTMLElement).offsetWidth -
              dimensions.viewportInnerSize.width,
          );
        }
      }
    });
  }, [gridApi]);

  useEffect(() => {
    if (gridMaxLeftScrollPosition > 0) {
      gridApi.current.subscribeEvent('scrollPositionChange', (params) => {
        if (params.left <= 0) {
          setLeftGridArrowVisibility(false);
          setRightGridArrowVisibility(true);
        } else if (params.left >= gridMaxLeftScrollPosition) {
          setLeftGridArrowVisibility(true);
          setRightGridArrowVisibility(false);
        } else {
          setLeftGridArrowVisibility(true);
          setRightGridArrowVisibility(true);
        }
      });
    }
  }, [gridMaxLeftScrollPosition, gridApi]);

  const columns: GridColDef[] = [
    {
      field: 'externalId',
      sortable: false,
      headerName: t('orders.external_id') as string,
      width: 200,
      renderCell: (params) => {
        return (
          <span className="break-all whitespace-normal max-w-full">
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'orderId',
      sortable: false,
      headerName: t('orders.order_id') as string,
      width: 200,
      renderCell: (params) => {
        return (
          <span className="break-all whitespace-normal max-w-full">
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'description',
      sortable: false,
      headerName: t('orders.description') as string,
      width: 170,
      renderCell: (params) => {
        return (
          <span className="whitespace-normal line-clamp-2" title={params.value}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: 'createdAt',
      sortable: false,
      headerName: t('orders.created_at') as string,
      width: 150,
      renderCell: (params) => {
        return Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(params.value));
      },
    },
    {
      field: 'orderAmount',
      sortable: false,
      headerName: t('orders.order_amount') as string,
      width: 150,
      renderCell: (params) => {
        return isCrypto(params.value.currencyCode)
          ? printCryptoAmount({
              languageCode: locale,
              amount: params.value.amount,
              currency: params.value.currencyCode,
            })
          : printFiatAmount({
              languageCode: locale,
              amount: params.value.amount,
              currency: params.value.currencyCode,
              currencyDisplay: 'code',
            });
      },
    },
    {
      field: 'paymentGross',
      sortable: false,
      headerName: t('orders.payment_gross') as string,
      width: 150,
      renderCell: (params) => {
        return params.value
          ? printCryptoAmount({
              languageCode: locale,
              amount: params.value.amount,
              currency: params.value.currencyCode,
            })
          : '';
      },
    },
    {
      field: 'paymentNet',
      sortable: false,
      headerName: t('orders.payment_net') as string,
      width: 150,
      renderCell: (params) => {
        return params.value
          ? printCryptoAmount({
              amount: params.value.amount,
              currency: params.value.currencyCode,
            })
          : '';
      },
    },
    {
      field: 'status',
      sortable: false,
      headerName: t('orders.status') as string,
      width: 150,
      renderCell: (params) => {
        switch (params.value) {
          case GetStoreOrdersAsFileRestRequestStatusesEnum.Active:
            return (
              <Typography variant="body2" color="#2196F3">
                {t('orders.active').toLocaleUpperCase()}
              </Typography>
            );
          case GetStoreOrdersAsFileRestRequestStatusesEnum.Expired:
            return (
              <Typography variant="body2" color="#D32F2F">
                {t('orders.expired').toLocaleUpperCase()}
              </Typography>
            );
          case GetStoreOrdersAsFileRestRequestStatusesEnum.Paid:
            return (
              <Typography variant="body2" color="#00C853">
                {t('orders.paid').toLocaleUpperCase()}
              </Typography>
            );
          default:
            return (
              <Typography variant="body2">
                {params.value.toLocaleUpperCase()}
              </Typography>
            );
        }
      },
    },
  ];

  return (
    <div className="flex-grow relative">
      {leftGridArrowVisibility && (
        <button
          onClick={() => {
            gridContainerRef.current
              ?.querySelector('.MuiDataGrid-virtualScroller')
              ?.scrollTo({
                left: 0,
                behavior: 'smooth',
              });
          }}
          className="w-10 h-10 top-2/4 -mt-5 absolute bg-white z-10 left-5 flex items-center justify-center rounded-full shadow-gridArrows"
        >
          <GridArrowBackwardSVG />
        </button>
      )}
      {rightGridArrowVisibility && gridMaxLeftScrollPosition > 0 && (
        <button
          onClick={() => {
            gridContainerRef.current
              ?.querySelector('.MuiDataGrid-virtualScroller')
              ?.scrollTo({
                left: gridMaxLeftScrollPosition,
                behavior: 'smooth',
              });
          }}
          className="w-10 h-10 top-2/4 -mt-5 absolute bg-white z-10 right-5 flex items-center justify-center rounded-full shadow-gridArrows"
        >
          <GridArrowForwardSVG />
        </button>
      )}
      <DataGrid
        apiRef={gridApi}
        ref={gridContainerRef}
        paginationModel={{
          page,
          pageSize,
        }}
        onPaginationModelChange={({ page, pageSize }) => {
          updatePage(page);
          updatePageSize(pageSize);
        }}
        loading={isRefetching}
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={ordersList.total}
        rows={ordersList.items.map((order) => {
          return {
            id: order.orderId,
            externalId: order.externalId,
            orderId: order.orderId,
            createdAt: order.createdDateTime,
            orderAmount: order.orderAmount,
            paymentGross: order.selectedPaymentOption?.amount,
            paymentNet: order.selectedPaymentOption?.amountNet,
            status: order.orderStatus,
            description: order.description,
          };
        })}
        columns={columns}
        disableColumnMenu
        sx={{
          borderWidth: 0,
        }}
      />
    </div>
  );
};
