import { Stepper as MUIStepper, Step, StepLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Stepper: FC<{ activeStep: number }> = ({ activeStep }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <MUIStepper
      className="mt-6 mb-8 lg:-mx-2"
      activeStep={activeStep}
      orientation={lgMatch ? 'horizontal' : 'vertical'}
    >
      <Step>
        <StepLabel>{t('questionnaire.legal_form_step')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{t('questionnaire.general_step')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{t('questionnaire.about_step')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{t('questionnaire.kyc_step')}</StepLabel>
      </Step>
    </MUIStepper>
  );
};
