import { OpenInNew } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Placeholder } from 'components/Placeholder';
import { TELEGRAM_STARS_DOCUMENTATION_LINK } from 'config';
import { useTranslation } from 'react-i18next';

export const DeclinedDueTelegramStars = () => {
  const { t } = useTranslation();

  return (
    <Placeholder
      className="py-8 lg:h-[550px]"
      title={t('declined_due_to_telegram_stars.title')}
      text={t('declined_due_to_telegram_stars.text')}
      icon="⛔️"
      action={
        <Button
          size="medium"
          component="a"
          target="_blank"
          href={TELEGRAM_STARS_DOCUMENTATION_LINK}
          endIcon={<OpenInNew />}
        >
          {t('declined_due_to_telegram_stars.telegram_stars_documentation')}
        </Button>
      }
    />
  );
};
