import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

export function useNumberParam(
  name: string,
  initialValue: number,
): [value: number, setValue: (newValue: number) => void];
export function useNumberParam(
  name: string,
): [value: number | undefined, setValue: (newValue: number) => void];
export function useNumberParam(
  name: string,
  initialValue?: number,
): [value: number | undefined, setValue: (newValue: number) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const valueFromSearch = searchParams.get(name);
  const value = valueFromSearch ? Number(valueFromSearch) : initialValue;

  return [
    value,
    (newValue) => {
      const urlSearchParams = new URLSearchParams(location.search);
      newValue
        ? urlSearchParams.set(name, String(newValue))
        : urlSearchParams.delete(name);
      setSearchParams(urlSearchParams);
    },
  ];
}

export function useStringParam(
  name: string,
  initialValue: string,
): [value: string, setValue: (newValue: string) => void];
export function useStringParam(
  name: string,
): [value: string | undefined, setValue: (newValue: string) => void];
export function useStringParam(
  name: string,
  initialValue?: string,
): [value: string | undefined, setValue: (newValue: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const valueFromSearch = searchParams.get(name);
  const value = valueFromSearch ? valueFromSearch : initialValue;

  return [
    value,
    (newValue) => {
      const urlSearchParams = new URLSearchParams(location.search);
      newValue
        ? urlSearchParams.set(name, newValue)
        : urlSearchParams.delete(name);
      setSearchParams(urlSearchParams);
    },
  ];
}

export function useDateParam(
  name: string,
  initialValue: Dayjs,
): [value: Dayjs, setValue: (newValue: string | null) => void];
export function useDateParam(
  name: string,
): [value: Dayjs | undefined, setValue: (newValue: string | null) => void];
export function useDateParam(
  name: string,
  initialValue?: Dayjs,
): [value: Dayjs | undefined, setValue: (newValue: string | null) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const valueFromSearch = searchParams.get(name);
  const value = valueFromSearch ? dayjs(valueFromSearch) : initialValue;

  return [
    value,
    (newValue) => {
      const urlSearchParams = new URLSearchParams(location.search);
      newValue
        ? urlSearchParams.set(name, newValue)
        : urlSearchParams.delete(name);
      setSearchParams(urlSearchParams);
    },
  ];
}

export function useArrayParam(
  name: string,
  initialValue: string[],
): [
  value: string[],
  add: (newValue: string) => void,
  remove: (newValue: string) => void,
];
export function useArrayParam(
  name: string,
): [
  value: string[] | undefined,
  add: (newValue: string) => void,
  remove: (newValue: string) => void,
];
export function useArrayParam(
  name: string,
  initialValue?: string[],
): [
  value: string[] | undefined,
  add: (newValue: string) => void,
  remove: (newValue: string) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();
  const valueFromSearch = searchParams.get(name);
  const value = valueFromSearch ? valueFromSearch.split('|') : initialValue;

  return [
    value,
    (newValue) => {
      const urlSearchParams = new URLSearchParams(location.search);
      Array.isArray(value) &&
        !value.includes(newValue) &&
        urlSearchParams.set(name, [...value, newValue].join('|'));
      setSearchParams(urlSearchParams);
    },
    (newValue) => {
      const urlSearchParams = new URLSearchParams(location.search);
      if (Array.isArray(value) && value.includes(newValue)) {
        value.length === 1
          ? urlSearchParams.delete(name)
          : urlSearchParams.set(
              name,
              value.filter((item) => item !== newValue).join('|'),
            );
      }
      setSearchParams(urlSearchParams);
    },
  ];
}
