import { ReactComponent as BTCSVG } from 'images/BTC.svg';
import { ReactComponent as NOTSVG } from 'images/NOT.svg';
import { ReactComponent as TONSVG } from 'images/TON.svg';
import { ReactComponent as USDTSVG } from 'images/USDT.svg';
import { FC, SVGProps } from 'react';
import { Currency } from 'types';

const CURRENCY_LOGO_COMPONENT: Record<
  Currency | 'BTC',
  FC<SVGProps<SVGSVGElement>>
> = {
  TON: TONSVG,
  NOT: NOTSVG,
  USDT: USDTSVG,
  BTC: BTCSVG,
};

export const CurrencyLogo: FC<{
  currency: Currency | 'BTC';
  size?: number;
  className?: string;
}> = ({ size = 40, currency, className }) => {
  const Component = CURRENCY_LOGO_COMPONENT[currency];
  return <Component className={className} width={size} height={size} />;
};
