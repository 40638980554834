import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { AppContext } from 'AppContext';
import {
  GetStorePayoutsRestRequestCurrenciesEnum,
  MoneyAmountDto,
  PayoutDtoStatusEnum,
} from 'api/generated';
import axios from 'axios';
import { MUIDataGridSkeleton } from 'components/MUIDataGridSkeleton';
import { Placeholder } from 'components/Placeholder';
import { FiltersDropdown } from 'containers/FiltersDropdown';
import { TableCommonFilters } from 'containers/TableCommonFilters';
import * as dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import {
  useArrayParam,
  useDateParam,
  useNumberParam,
} from 'hooks/useSearchParam';
import {
  getWithdrawalListCsv,
  useWithdrawalList,
} from 'queries/withdrawal/useWithdrawalList';
import { FC, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency, CurrencyEnum } from 'types';
import { printCryptoAmount } from 'utils/amount';
import { downloadBlob } from 'utils/downloadBlob';
import { decodeServerNumber } from 'utils/number';

type WithdrawalsRow = {
  id: string;
  createdAt: string;
  amountGross?: MoneyAmountDto;
  amountNet: MoneyAmountDto;
  status: PayoutDtoStatusEnum;
};

export const Withdrawals: FC<{
  storeId?: string;
  payoutFeePercent?: number;
  currency?: Currency;
}> = ({ storeId, payoutFeePercent, currency }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const { showSnackbar } = useContext(AppContext);
  const fromInputRef = useRef<HTMLInputElement>(null);
  const toInputRef = useRef<HTMLInputElement>(null);

  const [from, updateFrom] = useDateParam('from');
  const [to, updateTo] = useDateParam('to');
  const [pageSize, updatePageSize] = useNumberParam('pageSize', 10);
  const [page, updatePage] = useNumberParam('page', 0);
  const [currencies, addCurrency, removeCurrency] = useArrayParam(
    'orderCurrencies',
    [],
  );
  const [statuses, addStatus, removeStatus] = useArrayParam('statuses', []);

  const {
    data: list,
    isRefetching,
    isLoading,
  } = useWithdrawalList({
    id: storeId,
    limit: pageSize,
    offset: page * pageSize,
    from,
    to,
    currencies: currency
      ? [currency]
      : (currencies as GetStorePayoutsRestRequestCurrenciesEnum[]),
    statuses: statuses as PayoutDtoStatusEnum[],
  });

  const hasList =
    list && list !== 'ACCESS_DENIED' && list !== 'NOT_FOUND' && list.total > 0;
  const areFiltersEmpty =
    !from && !to && statuses.length === 0 && currencies?.length === 0;
  const canDownload = !!storeId && hasList;

  const createdAt = {
    field: 'createdAt',
    headerName: t('withdrawals.created_at') as string,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(new Date(params.value));
    },
  };

  const amountGross = {
    field: 'amountGross',
    headerName: t('withdrawals.amount') as string,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return printCryptoAmount({
        languageCode: locale,
        amount: decodeServerNumber(params.value.amount as string),
        currency: params.value.currencyCode,
      });
    },
  };

  const amountNet = {
    field: 'amountNet',
    headerName: t('withdrawals.amount_after_fee') as string,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return printCryptoAmount({
        languageCode: locale,
        amount: decodeServerNumber(params.value.amount as string),
        currency: params.value.currencyCode,
      });
    },
  };

  const status = {
    field: 'status',
    headerName: t('withdrawals.status') as string,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case PayoutDtoStatusEnum.Created:
          return (
            <Typography variant="body2">
              <span className="opacity-60">
                {t('withdrawals.created').toLocaleUpperCase()}
              </span>
            </Typography>
          );
        case PayoutDtoStatusEnum.Failed:
          return (
            <Typography variant="body2" color="#D32F2F">
              {t('withdrawals.failed').toLocaleUpperCase()}
            </Typography>
          );
        case PayoutDtoStatusEnum.Transferred:
          return (
            <Typography variant="body2" color="#00C853">
              {t('withdrawals.transferred').toLocaleUpperCase()}
            </Typography>
          );
        default:
          return (
            <Typography variant="body2">
              {params.value.toLocaleUpperCase()}
            </Typography>
          );
      }
    },
  };

  const columns: GridColDef<WithdrawalsRow>[] =
    payoutFeePercent && payoutFeePercent > 0
      ? [createdAt, amountGross, amountNet, status]
      : [createdAt, amountGross, status];

  return (
    <>
      <div className="flex justify-between py-4 shrink-0">
        <Typography variant="h5">{t('balance.withdrawal_history')}</Typography>
        <LoadingButton
          loading={downloadingCSV}
          size="small"
          variant="outlined"
          startIcon={<Download />}
          disabled={!canDownload}
          onClick={async () => {
            if (!from) {
              fromInputRef.current?.focus();
            } else if (!to) {
              toInputRef.current?.focus();
            } else if (canDownload) {
              try {
                setDownloadingCSV(true);
                const data = await getWithdrawalListCsv({
                  from,
                  to,
                  statuses: statuses,
                  currencies: currencies?.length
                    ? currencies
                    : currency
                    ? [currency]
                    : undefined,
                  storeId,
                });

                downloadBlob(
                  data,
                  `withdrawals-${dayjs(Date.now()).format(
                    'YYYY-MM-DD-hh-mm',
                  )}.csv`,
                );
              } catch (e) {
                if (axios.isAxiosError(e) && e.response?.status === 400) {
                  showSnackbar({
                    message: t('common.csv_date_ranger_error'),
                  });
                } else {
                  showSnackbar({
                    message: t('common.something_went_wrong'),
                  });
                }
              } finally {
                setDownloadingCSV(false);
              }
            }
          }}
        >
          {t('common.download_csv')}
        </LoadingButton>
      </div>
      {!isLoading && (hasList || (!hasList && !areFiltersEmpty)) && (
        <div className="flex items-center gap-4 mb-4">
          <TableCommonFilters
            className="grow"
            from={from}
            fromInputRef={fromInputRef}
            to={to}
            toInputRef={toInputRef}
            setFrom={({ formattedDate }) => {
              updateFrom(formattedDate);
            }}
            setTo={({ formattedDate }) => {
              updateTo(formattedDate);
            }}
          />
          <FiltersDropdown
            active={
              statuses.length > 0 || (currencies && currencies.length > 0)
            }
          >
            <FormControl sx={{ p: 2 }} component="fieldset" variant="standard">
              <Typography variant="body1">
                <span className="opacity-60">{t('withdrawals.status')}</span>
              </Typography>
              <FormGroup>
                {Object.values(PayoutDtoStatusEnum).map((status) => (
                  <FormControlLabel
                    key={status}
                    sx={{ ml: 0 }}
                    control={
                      <Checkbox
                        name="statuses"
                        value={status}
                        checked={statuses.includes(status)}
                        onChange={(e) => {
                          e.target.checked
                            ? addStatus(e.target.value)
                            : removeStatus(e.target.value);
                        }}
                      />
                    }
                    label={t(`withdrawals.${status.toLowerCase()}`)}
                  />
                ))}
              </FormGroup>
              {!currency && (
                <>
                  <Typography variant="body1">
                    <span className="opacity-60">
                      {t('withdrawals.currency')}
                    </span>
                  </Typography>
                  <FormGroup>
                    {Object.values(CurrencyEnum).map((currency) => (
                      <FormControlLabel
                        key={currency}
                        sx={{ ml: 0 }}
                        control={
                          <Checkbox
                            name="currencies"
                            value={currency}
                            checked={currencies.includes(currency)}
                            onChange={(e) => {
                              e.target.checked
                                ? addCurrency(e.target.value)
                                : removeCurrency(e.target.value);
                            }}
                          />
                        }
                        label={currency}
                      />
                    ))}
                  </FormGroup>
                </>
              )}
            </FormControl>
          </FiltersDropdown>
        </div>
      )}
      {isLoading ? (
        <MUIDataGridSkeleton
          rowHeight={52}
          headerRowHeight={56}
          rowsCount={5}
        />
      ) : hasList ? (
        <DataGrid
          paginationModel={{
            page,
            pageSize,
          }}
          onPaginationModelChange={({ page, pageSize }) => {
            updatePage(page);
            updatePageSize(pageSize);
          }}
          autoHeight
          disableRowSelectionOnClick
          paginationMode="server"
          rowCount={list.total}
          loading={isRefetching}
          rows={list.items.map((withdraw) => {
            return {
              id: withdraw.id,
              createdAt: withdraw.createdDateTime,
              amountGross: withdraw.amountGross,
              amountNet: withdraw.amountNet,
              status: withdraw.status,
            };
          })}
          columns={columns}
          disableColumnMenu
          sx={{
            borderWidth: 0,
          }}
        />
      ) : (
        <Placeholder
          title={t('balance.no_withdrawals_title')}
          text={t('balance.no_withdrawals_text')}
          icon="🕔"
          className="grow"
        />
      )}
    </>
  );
};
