import { Container } from '../components/Container';
import { ReactComponent as LogoSVG } from '../images/logo.svg';
import { routePaths } from '../router';
import { Nav } from './Nav';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Header: FC<{ adaptive?: boolean }> = ({ adaptive = false }) => {
  return (
    <header>
      <Container adaptive={adaptive}>
        <div className="flex justify-between items-center h-[74px]">
          <Link
            to={routePaths.ROOT}
            className="flex items-center gap-3 rounded"
          >
            <LogoSVG className="block" />
            <div className="rounded-lg px-2 text-white text-base uppercase bg-[rgba(0,0,0,0.32)]">
              beta
            </div>
          </Link>
          <Nav adaptive={adaptive} />
        </div>
      </Container>
    </header>
  );
};
