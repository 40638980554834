import API from 'api';
import { MerchantDto } from 'api/generated';
import { login, logout, setStoreId, setTGUser } from 'reducers/userSlice';
import { store } from 'store';

export type TGUser = {
  id: number;
  first_name?: string;
  last_name?: string;
  username?: string;
  photo_url?: string;
  auth_date: string;
  hash: string;
};

export enum AuthStatuses {
  MERCHANT_NOT_FOUND = 'MERCHANT_NOT_FOUND',
  SUCCESS = 'SUCCESS',
  ACCESS_DENIED = 'ACCESS_DENIED',
  GENERIC_ERROR = 'GENERIC_ERROR',
  NOT_WALLET_USER = 'NOT_WALLET_USER',
}

type User =
  | {
      status: AuthStatuses.SUCCESS;
      data: {
        jwtToken: string;
      };
    }
  | {
      status: AuthStatuses.NOT_WALLET_USER;
    };

export type Auth =
  | {
      status:
        | AuthStatuses.NOT_WALLET_USER
        | AuthStatuses.GENERIC_ERROR
        | AuthStatuses.MERCHANT_NOT_FOUND
        | AuthStatuses.ACCESS_DENIED;
    }
  | {
      status: AuthStatuses.SUCCESS;
      merchant: MerchantDto;
    };

export const auth: (tgUser: TGUser) => Promise<Auth> = async (tgUser) => {
  try {
    const authorize = await fetch(
      '/users/public-api/v1/auth/authorize-by-telegram',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tgUser),
      },
    );

    const user: User = await authorize.json();

    store.dispatch(setTGUser(tgUser));

    if (user.status === AuthStatuses.NOT_WALLET_USER) {
      return {
        status: AuthStatuses.NOT_WALLET_USER,
      };
    }

    store.dispatch(login(user.data.jwtToken));

    const merchant = await API.Merchant.findMerchantByUser();

    switch (merchant.data.status) {
      case 'ACCESS_DENIED':
        store.dispatch(logout());
        return {
          status: AuthStatuses.ACCESS_DENIED,
        };
      case 'NOT_FOUND':
        return {
          status: AuthStatuses.MERCHANT_NOT_FOUND,
        };
      case 'SUCCESS': {
        const merchantData = merchant.data.data as MerchantDto;
        const storeId =
          merchantData.stores.length > 0 ? merchantData.stores[0].id : null;
        store.dispatch(setStoreId(storeId));
        return {
          status: AuthStatuses.SUCCESS,
          merchant: merchantData,
        };
      }
    }
  } catch (e) {
    store.dispatch(logout());
    return {
      status: AuthStatuses.GENERIC_ERROR,
    };
  }
};
