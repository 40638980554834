import { FALLBACK_LANGUAGE } from '../i18n';
import { Currency, CurrencyEnum } from '../types';

const DEFAULT_MAXIMUM_FRACTION_DIGITS: Record<Currency, number> = {
  TON: 9,
  NOT: 9,
  USDT: 6,
  BTC: 8,
};

export function isCrypto(value: string): value is Currency {
  return Object.values(CurrencyEnum).includes(value as CurrencyEnum);
}

export const printCryptoAmount = ({
  languageCode = FALLBACK_LANGUAGE,
  amount,
  currency,
}: {
  languageCode?: string;
  amount: number;
  currency: Currency;
}) => {
  return `${Intl.NumberFormat(languageCode, {
    maximumFractionDigits: DEFAULT_MAXIMUM_FRACTION_DIGITS[currency],
  }).format(amount)} ${currency}`;
};

export const printFiatAmount = ({
  languageCode = FALLBACK_LANGUAGE,
  amount,
  currency,
  currencyDisplay,
}: {
  languageCode?: string;
  amount: number;
  currency: string;
  currencyDisplay?: 'code' | 'symbol' | 'narrowSymbol';
}) => {
  return Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency,
    currencyDisplay,
  }).format(amount);
};
