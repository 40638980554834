import API from '../api';
import { CircularProgress } from '@mui/material';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Modal } from 'components/Modal';
import { useLocale } from 'hooks/useLocale';
import { FC, useEffect, useState } from 'react';

export const VerificationModal: FC<{
  open: boolean;
  onClose: VoidFunction;
  onComplete: VoidFunction;
}> = ({ open, onClose, onComplete }) => {
  const [accessToken, setAccessToken] = useState<string>();
  const locale = useLocale();

  function getSumsubToken() {
    API.Merchant.getVerificationTokenForMerchant().then((response) => {
      if (response.data.data) {
        setAccessToken(response.data.data.verificationToken);
      }
    });
  }

  useEffect(() => {
    if (open) {
      getSumsubToken();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-[600px]">
        {accessToken ? (
          <SumsubWebSdk
            config={{
              lang: locale,
            }}
            accessToken={accessToken}
            expirationHandler={getSumsubToken}
            onMessage={async (message: string) => {
              if (message === 'idCheck.applicantReviewComplete') {
                onComplete();
              }
            }}
          />
        ) : (
          <div className="h-[200px] flex items-center justify-center">
            <CircularProgress />
          </div>
        )}
      </div>
    </Modal>
  );
};
