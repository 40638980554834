import {
  BalanceByAssetCell,
  BalanceByAssetCellSkeleton,
} from './components/BalanceByAssetCell';
import { FiatBalance, FiatBalanceSkeleton } from './components/FiatBalance';
import { StoreContent } from './components/StoreContent';
import { StoreOnboarding } from './components/StoreOnboarding';
import { WithdrawModal } from './components/WithdrawModal';
import { Withdrawals } from './components/Withdrawals';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { MUIButtonSkeleton } from 'components/MUIButtonSkeleton';
import { AvailableBalanceHint } from 'containers/AvailableBalanceHint';
import { useStore, useStoreIfExists } from 'queries/store/useStore';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams, useSearchParams } from 'react-router-dom';
import { routePaths } from 'router';
import { Currency } from 'types';
import { decodeServerNumber } from 'utils/number';

export const StoreBalance: FC = () => {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [withdrawModalOpened, setWithdrawModalOpened] = useState(false);
  const { id } = useParams();
  const { data, refetch, isRefetching } = useStore(id);
  const store = useStoreIfExists(data);

  async function resetAndRefetchWithdrawals() {
    await Promise.all([
      refetch(),
      queryClient.invalidateQueries({ queryKey: ['withdrawalList'] }),
    ]);
  }

  if (searchParams.get('onboarding')) {
    return <StoreOnboarding />;
  }

  return (
    <StoreContent className="flex flex-col">
      <div className="flex gap-4 mb-5 shrink-0 pt-2">
        <div className="rounded-2xl border grow p-5">
          <Typography variant="h5" mb={3}>
            {t('store.balance')}
          </Typography>
          {store && store.aggregatedBalance ? (
            <>
              <FiatBalance
                amount={decodeServerNumber(store.aggregatedBalance.total)}
                currency={store.aggregatedBalance.currency}
                bottom={t('balance.total')}
                className="mb-5"
              />
              <FiatBalance
                amount={decodeServerNumber(
                  store.aggregatedBalance.availableForPayoutGross,
                )}
                currency={store.aggregatedBalance.currency}
                bottom={t('balance.available')}
                bottomIcon={
                  <AvailableBalanceHint
                    fundsHoldingDuration={store.fundsHoldingDuration}
                  />
                }
                className="mb-5"
              />
              <LoadingButton
                loading={isRefetching}
                onClick={async () => {
                  await refetch();
                  setWithdrawModalOpened(true);
                }}
                variant="contained"
                size="large"
                disabled={
                  decodeServerNumber(
                    store.aggregatedBalance.availableForPayoutGross,
                  ) === 0
                }
              >
                {t('balance.withdraw')}
              </LoadingButton>
            </>
          ) : (
            <>
              <FiatBalanceSkeleton className="mb-5" />
              <FiatBalanceSkeleton className="mb-5" />
              <MUIButtonSkeleton variant="contained" size="large">
                {t('balance.withdraw')}
              </MUIButtonSkeleton>
            </>
          )}
        </div>
        <div className="rounded-2xl border w-[500px] shrink-0 p-5">
          <Typography variant="h5">{t('balance.assets')}</Typography>
          <Typography variant="body2">
            <span className="opacity-60">
              {t('balance.assets_description')}
            </span>
          </Typography>
          <div className="pt-2 -mx-5">
            {store && store.balances ? (
              store.balances.map((balance) => {
                return (
                  <BalanceByAssetCell
                    to={generatePath(routePaths.STORE_ASSET_BALANCE, {
                      currency: balance.currency,
                      id: store.id,
                    })}
                    key={balance.currency}
                    className="mt-3"
                    currency={balance.currency as Currency}
                    amount={decodeServerNumber(balance.total)}
                    fiatCurrency={balance.fiatCurrency}
                    fiatAmount={decodeServerNumber(balance.totalInFiat)}
                  />
                );
              })
            ) : (
              <>
                <BalanceByAssetCellSkeleton className="mt-3" />
                <BalanceByAssetCellSkeleton className="mt-3" />
              </>
            )}
          </div>
        </div>
      </div>
      <Withdrawals
        storeId={store?.id}
        payoutFeePercent={store?.payoutFeePercent}
      />
      {store && store.aggregatedBalance && (
        <WithdrawModal
          onSuccess={resetAndRefetchWithdrawals}
          open={withdrawModalOpened}
          onClose={() => setWithdrawModalOpened(false)}
        />
      )}
    </StoreContent>
  );
};
