export default {
  common: {
    download_csv: 'Скачать .csv',
    csv_date_ranger_error: 'Слишком большой диапазон дат',
    search: 'Поиск',
    date_range: 'Диапазон дат',
    cancel: 'Отмена',
    done: 'Готово',
    next: 'Далее',
    back: 'Назад',
    something_went_wrong: 'Что-то пошло не так',
    fiat_unavailable: 'Эквивалент в фиате недоступен',
    contact_support: 'Свяжитесь с поддержкой',
  },
  questionnaire: {
    title: 'Заполните форму',
    legal_form_step: 'Форма представления',
    general_step: 'Общая информация',
    about_step: 'О бизнесе',
    kyc_step: 'KYC',
    legal_form_title: 'Выберите ваш статус',
    company: 'Юридическое лицо',
    individual: 'Физическое лицо',
    legal_disclaimer:
      'Нажимая на кнопку Далее, вы соглашаетесь с <0>Условиями использования</0>.',
    company_phone: 'Телефон представителя',
    company_email: 'Электронная почта представителя',
    company_address: 'Адрес проживания представителя',
    individual_phone: 'Телефон',
    individual_email: 'Электронная почта',
    individual_address: 'Адрес проживания',
    activity:
      'Деятельность (Опишите продукты или услуги, которые вы предлагаете)',
    activity_details: 'Какие продукты или услуги вы предлагаете',
    purchase_amount: 'Ожидаемая средняя сумма покупки (эквивалент в EUR)',
    expected_revenue: 'Ожидаемый месячный оборот (эквивалент в EUR)',
    license: 'Лицензия № и выдающий регулятор (при наличии)',
    telegram:
      'URL-адрес магазинов в Telegram (Укажите Telegram Bots, через которые вы предоставляете свои услуги или продаете товары)',
    marketing:
      'Маркетинговые каналы (Веб-сайт, Telegram Channel, Twitter Account, где вы продвигаете свои товары или услуги)',
    activities: {
      goods:
        'Физические товары и магазины (например, одежда, электроника, билеты)',
      services:
        'Услуги (любые профессиональные или бизнес-услуги, например, консалтинг, косметические услуги, бухгалтерия)',
      digital_goods:
        'Цифровые товары (контент, программное обеспечение и игры, внутриигровая валюта, за исключением подписок)',
      subscriptions: 'Подписки',
      betting: 'Лицензионные ставки или азартные игры',
      other: 'Другое',
    },
    subscriptions_warning:
      'Рекурентные платежи пока не доступны. Однако, вы можете использовать текущую интеграцию для продажи подписок на будущие периоды. Мы сообщим вам, когда рекурентные платежи станут доступны.',
    max_length_error: 'Значение поля не должно быть длиннее {{max}} символов',
  },
  logout_reasons: {
    EXPIRED: 'Ваша сессия истекла',
  },
  client_error: {
    title: 'Упс.. Что-то пошло не так',
    text: 'Не волнуйтесь, мы уже об этом знаем',
  },
  not_found: {
    title: '404',
    text: 'Страница не найдена',
  },
  approved: {
    title: 'Ваша заявка одобрена',
    first_application_text:
      'Перейдите в личный кабинет. Комиссия за транзакцию: {{paymentFeePercent}}%, комиссия за вывод средств: {{payoutFeePercent}}%.',
    text: 'Ваш профиль успешно обновлен. Комиссия за транзакцию: {{paymentFeePercent}}%, комиссия за вывод средств: {{payoutFeePercent}}%.',
    go_to_wallet_pay: 'Перейти в Wallet Pay',
  },
  declined: {
    title: 'Ваша заявка отклонена',
    text: 'Пожалуйста, свяжитесь с поддержкой для получения подробной информации.',
  },
  declined_due_to_telegram_stars: {
    title: 'Ваша заявка отклонена',
    text: 'С 12 июня платежи за цифровые товары и услуги в Telegram необходимо проводить с помощью Звёзд Telegram, поэтому подключить проект к Wallet Pay не получится. Изучите документацию Звёзд Telegram для подключения нового способа оплаты.',
    telegram_stars_documentation: 'Документация Звёзды Telegram',
  },
  on_approval: {
    title: 'Ваша заявка на рассмотрении',
    text: 'Мы приостановили работу с новыми заявками Wallet Pay. Если заявка соответствует Условиям использования Telegram и Wallet Pay, то мы рассмотрим её, но это может занять время. Пока делимся полезными ссылками.',
    back_to_wallet_pay: 'Вернуться в Wallet Pay',
    read_documentation: 'Документация Wallet Pay',
    telegram_stars_documentation: 'Документация Звёзды Telegram',
    try_demo: 'Демо',
    revoke: 'Отменить заявку',
  },
  revoked: {
    title: 'Ваша заявка отменена',
    text: 'Вы можете подать новую заявку.',
    create: 'Подать заявку',
  },
  header: {
    api_documents: 'Документация',
    support: 'Поддержка',
    faq: 'FAQ',
    logout: 'Выйти',
    verification_not_completed: 'Верификация не завершена',
    verification_completed: 'Верификация завершена',
    complete: 'Завершить',
    change_info: 'Изменить информацию',
  },
  footer: {
    about: 'О нас',
    terms: 'Условия использования',
    privacy: 'Конфиденциальность',
  },
  welcome: {
    title: 'Начните принимать платежи в\u00A0криптовалюте в\u00A0Telegram',
    text: 'Отслеживайте заказы и легко выводите средства на кошелек',
    go_to_account: 'Перейти в аккаунт',
    create_store: 'Создать магазин',
    start_wallet: 'Запустить кошелек',
    access_denied: 'Доступ запрещен',
  },
  not_wallet_user: {
    title: 'Откройте Wallet Bot',
    text: 'Нажмите "Старт" в боте, чтобы использовать Wallet Pay',
    open: 'Открыть',
  },
  denied: {
    title: 'Доступ в Wallet Pay запрещен',
    text: 'Пожалуйста, свяжитесь с поддержкой для получения подробной информации.',
  },
  create_store: {
    title: 'Создать магазин',
    name_hint: 'Любое название от {{min}} до {{max}} символов',
    name_placeholder: 'Введите название магазина',
    name_validation_min: 'Название должно содержать не менее {{min}} символов',
    name_validation_max: 'Название не должно превышать {{max}} символов',
    bot_hint: '@name_of_the_bot или https://t.me/name_of_the_bot',
    bot_placeholder:
      'Укажите URL магазина (укажите ссылку на Telegram-бота, который вы используете для предоставления услуг или продажи товаров)',
    bot_validation:
      'Пожалуйста, введите ссылку в одном из следующих форматов: @name_of_the_bot или https://t.me/name_of_the_bot',
    create: 'Создать',
    created: '"{{ storeName }}" был создан',
  },
  store: {
    add: 'Новый магазин',
    create_new: 'Создать новый магазин',
    setup_title: 'Настройте API-ключ для начала использования Wallet Pay',
    setup_text: 'Вы всегда можете сделать это в настройках',
    setup_button: 'Настроить',
    balance: 'Баланс',
    orders: 'История заказов',
    settings: 'Настройки',
    not_found: 'Магазин не найден',
    access_denied: 'Доступ к этому магазину запрещен',
  },
  settings: {
    store: 'Настройки магазина',
    integration: 'Интеграция',
    store_bot: 'URL Магазина',
    store_bot_hint:
      'Пожалуйста, обратитесь в <0>службу поддержки</0>, если вы хотите изменить URL вашего магазина',
    store_name: 'Название',
    save: 'Сохранить',
    api: 'API',
    generate_api_key: 'Сгенерировать ключ',
    show_api_key: 'Показать',
    hide_api_key: 'Скрыть',
    api_key_generated: 'Ключ сгенерирован',
    regenerate_api_key: 'Сгенерировать новый ключ',
    api_key_regenerated: 'Ключ был сгенерирован заново',
    api_key_copied: 'Ключ был скопирован',
    saved: 'Изменения сохранены',
    documents: 'Документация API',
    webhooks: 'Webhooks',
    enable_webhooks: 'Включить Webhooks',
    no_api_key_title: 'У вас еще нет ключа',
    no_api_key_text: 'Вы можете иметь только один ключ',
    api_key: 'Ключ',
    api_copy: 'Копировать',
    api_created: 'Создан',
    callback_url: 'URL обратного вызова',
  },
  orders: {
    search_placeholder: 'ID',
    external_id: 'Внешний ID',
    order_id: 'ID заказа',
    description: 'Описание',
    created_at: 'Создано',
    order_amount: 'Сумма заказа',
    payment_gross: 'Брутто платежа',
    payment_net: 'Нетто платежа',
    status: 'Статус',
    payment_currency: 'Валюта платежа',
    order_currency: 'Валюта заказа',
    active: 'Активный',
    paid: 'Оплачен',
    expired: 'Истек срок',
    cancelled: 'Отменен',
    no_orders_title: 'Пока нет заказов',
    no_orders_text: 'Как только вы создадите заказ, он появится здесь',
  },
  balance: {
    assets: 'Мои активы',
    assets_description: 'Активы для вывода',
    total: 'Общий баланс',
    available: 'Доступно для вывода',
    available_hint:
      'Недавно полученные активы\nблокируются на {{fundsHoldingDuration}} из соображений безопасности',
    withdraw: 'Вывести',
    withdrawal_history: 'История выводов',
    no_withdrawals_title: 'Пока нет выводов',
    no_withdrawals_text: 'Как только вы сделаете вывод, он появится здесь',
  },
  withdrawal: {
    title: 'Вывод средств',
    text: 'Вы выводите общую сумму ≈ <0>{{amount}}</0>',
    fee_text: 'Wallet Pay взимает {{percent}}% комиссии за каждый актив:',
    zero_fee_text: 'Комиссия за вывод составляет 0%',
    after_fee: 'После комиссии: ≈ <0>{{amount}}</0>',
    total: 'Итого: ≈ <0>{{amount}}</0>',
    progress_title: 'Пожалуйста, подождите',
    progress_text: 'Обработка вывода...',
    success_title: 'Все готово',
    success_text: 'Средства были выведены на ваш кошелек',
  },
  withdrawals: {
    status: 'Статус',
    currency: 'Валюта',
    amount: 'Сумма',
    amount_after_fee: 'После комиссии',
    created_at: 'Создано',
    created: 'В ожидании',
    transferred: 'Оплачено',
    failed: 'Не оплачено',
  },
};
