import { Placeholder } from '../components/Placeholder';
import { useTranslation } from 'react-i18next';

export const ClientError = () => {
  const { t } = useTranslation();

  return (
    <div className="grow w-full h-full py-10">
      <Placeholder
        title={t('client_error.title')}
        text={t('client_error.text')}
        icon="😬"
      />
    </div>
  );
};
