import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import API from 'api';
import {
  RestDataResponseGetStoreStatusStoreDto,
  StoreDto,
} from 'api/generated';

export const getStore: (request: {
  id: string;
}) => Promise<RestDataResponseGetStoreStatusStoreDto> = async ({ id }) => {
  const store = await API.Store.getStore(id);
  return store.data;
};

type UseStoreResult = 'NOT_FOUND' | 'ACCESS_DENIED' | StoreDto | undefined;

export const useStore: (id?: string) => UseQueryResult<UseStoreResult> = (
  id,
) => {
  return useQuery({
    queryKey: ['store', { id }],
    queryFn: async () => {
      if (!id) {
        return 'NOT_FOUND';
      } else {
        try {
          const storeResponse = await getStore({ id });
          if (storeResponse.status === 'SUCCESS') {
            return storeResponse.data as StoreDto;
          } else {
            return 'ACCESS_DENIED';
          }
        } catch (e) {
          return 'NOT_FOUND';
        }
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
  });
};

export const useStoreIfExists = (store: UseStoreResult): StoreDto | null => {
  if (store === 'NOT_FOUND' || store === 'ACCESS_DENIED' || !store) {
    return null;
  }
  return store;
};
