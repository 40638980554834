import { MerchantApplicationDto } from '../api/generated';
import { DOCUMENTATION_LINK, FAQ_LINKS, SUPPORT_LINK } from '../config';
import { localeNames, locales } from '../i18n';
import { useMerchant } from '../queries/merchant/useMerchant';
import { logout } from '../reducers/userSlice';
import { routePaths } from '../router';
import { useAppDispatch, useAppSelector } from '../store';
import { getOngoingApplication, merchantHomePage } from '../utils/merchant';
import { UserAvatar } from './UserAvatar';
import {
  ExpandLess,
  ExpandMore,
  Language,
  Logout,
  Menu as MenuIcon,
  OpenInNew,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { useLocale } from 'hooks/useLocale';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useNavigate } from 'react-router-dom';

export const Nav: FC<{ adaptive?: boolean }> = ({ adaptive = false }) => {
  const { t, i18n } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();
  const { tgUser } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { data: merchant } = useMerchant();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorEl = useRef<HTMLDivElement>(null);

  const getApplicationPath = (applications: MerchantApplicationDto[]) => {
    const ongoingApplication = getOngoingApplication(applications);

    if (ongoingApplication) {
      return generatePath(routePaths.APPLICATION, {
        id: ongoingApplication.id,
      });
    } else {
      return routePaths.NEW_APPLICATION;
    }
  };

  return (
    <nav
      className={classNames(
        'flex',
        'items-center',
        'gap-10',
        'relative',
        !tgUser && '-right-2.5',
      )}
    >
      <div className={classNames(adaptive && 'hidden lg:block')}>
        <Button
          size="large"
          color="info"
          component="a"
          href={DOCUMENTATION_LINK}
          target="_blank"
          endIcon={<OpenInNew />}
        >
          {t('header.api_documents')}
        </Button>
      </div>
      <div className={classNames(adaptive && 'hidden lg:block')}>
        <Button
          size="large"
          color="info"
          component="a"
          href={SUPPORT_LINK}
          target="_blank"
          endIcon={<OpenInNew />}
        >
          {t('header.support')}
        </Button>
      </div>
      <div className={classNames(adaptive && 'hidden lg:block')}>
        <Button
          size="large"
          color="info"
          component="a"
          href={FAQ_LINKS[locale]}
          target="_blank"
          endIcon={<OpenInNew />}
        >
          {t('header.faq')}
        </Button>
      </div>
      <div className={classNames(adaptive && 'hidden lg:block')}>
        <Button
          size="large"
          color="info"
          endIcon={<Language />}
          onClick={() => {
            i18n.changeLanguage(
              locale === locales['ru'] ? locales['en'] : locales['ru'],
            );
          }}
        >
          {localeNames[locale]}
        </Button>
      </div>

      {tgUser ? (
        <div
          className={classNames(
            'flex items-center cursor-pointer',
            adaptive ? 'lg:ml-[63px]' : 'ml-[63px]',
          )}
          ref={menuAnchorEl}
          onClick={() => setMenuOpen(true)}
        >
          <IconButton>
            <UserAvatar
              username={tgUser.username}
              firstName={tgUser.first_name}
              lastName={tgUser.last_name}
              userId={tgUser.id}
            />
          </IconButton>
          {menuOpen ? (
            <ExpandLess className="opacity-[0.38] -ml-1" />
          ) : (
            <ExpandMore className="opacity-[0.38] -ml-1" />
          )}
        </div>
      ) : adaptive ? (
        <div
          className="lg:hidden"
          ref={menuAnchorEl}
          onClick={() => setMenuOpen(true)}
        >
          <IconButton>
            <MenuIcon />
          </IconButton>
        </div>
      ) : null}

      <Menu
        anchorEl={menuAnchorEl.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          component="a"
          href={DOCUMENTATION_LINK}
          target="_blank"
          onClick={() => setMenuOpen(false)}
          sx={{ display: adaptive ? { lg: 'none' } : 'none' }}
        >
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText>{t('header.api_documents')}</ListItemText>
        </MenuItem>
        <MenuItem
          component="a"
          href={SUPPORT_LINK}
          target="_blank"
          onClick={() => setMenuOpen(false)}
          sx={{ display: adaptive ? { lg: 'none' } : 'none' }}
        >
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText>{t('header.support')}</ListItemText>
        </MenuItem>
        <MenuItem
          component="a"
          href={FAQ_LINKS[locale]}
          target="_blank"
          onClick={() => setMenuOpen(false)}
          sx={{ display: adaptive ? { lg: 'none' } : 'none' }}
        >
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText>{t('header.faq')}</ListItemText>
        </MenuItem>
        <Divider sx={{ display: adaptive ? { lg: 'none' } : 'none' }} />
        {tgUser && (tgUser.last_name || tgUser.last_name) && merchant && (
          <>
            <MenuItem
              onClick={() => {
                navigate(merchantHomePage(merchant), { replace: true });
                setMenuOpen(false);
              }}
            >
              <div>
                <Typography variant="body1">
                  {[tgUser.first_name, tgUser.last_name].join(' ')}
                </Typography>
                {tgUser.username && (
                  <Typography variant="body2" color="text.secondary">
                    @{tgUser.username}
                  </Typography>
                )}
              </div>
            </MenuItem>
            <Divider />
          </>
        )}

        {merchant?.status === 'APPROVED' && (
          <>
            <MenuItem
              onClick={() => {
                setMenuOpen(false);
              }}
              component={Link}
              to={getApplicationPath(merchant.applications)}
            >
              <div>
                <Typography variant="body1">
                  {t('header.verification_completed')}
                </Typography>
                <Typography variant="body2" color="primary">
                  {t('header.change_info')}
                </Typography>
              </div>
            </MenuItem>
            <Divider />
          </>
        )}
        {merchant?.status === 'NOT_APPROVED' && (
          <>
            <MenuItem
              onClick={() => {
                setMenuOpen(false);
              }}
              component={Link}
              to={getApplicationPath(merchant.applications)}
            >
              <div>
                <Typography variant="body1">
                  {t('header.verification_not_completed')}
                </Typography>
                <Typography variant="body2" color="primary">
                  {t('header.complete')}
                </Typography>
              </div>
            </MenuItem>
            <Divider />
          </>
        )}

        <MenuItem
          onClick={() => {
            setMenuOpen(false);
            i18n.changeLanguage(
              locale === locales['ru'] ? locales['en'] : locales['ru'],
            );
          }}
          sx={{ display: adaptive ? { lg: 'none' } : 'none' }}
        >
          <ListItemIcon>
            <Language />
          </ListItemIcon>
          <ListItemText>{localeNames[locale]}</ListItemText>
        </MenuItem>
        {tgUser && (
          <MenuItem
            onClick={() => {
              dispatch(logout());
              setMenuOpen(false);
              navigate(routePaths.ROOT, { replace: true });
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>{t('header.logout')}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </nav>
  );
};
