import { Skeleton } from '@mui/material';
import { FC } from 'react';
import { repeat } from 'utils/common';

type MUIDataGridSkeletonProps = {
  rowHeight: number;
  headerRowHeight?: number;
  rowsCount: number;
};

export const MUIDataGridSkeleton: FC<MUIDataGridSkeletonProps> = ({
  rowHeight,
  rowsCount,
  headerRowHeight,
}) => {
  return (
    <>
      <Skeleton
        height={
          typeof headerRowHeight === 'number' ? headerRowHeight : rowHeight
        }
      />
      {repeat(
        (index) => (
          <Skeleton height={rowHeight} key={index} />
        ),
        rowsCount,
      )}
    </>
  );
};
