import { Button } from '@mui/material';
import { Placeholder } from 'components/Placeholder';
import { SUPPORT_LINK } from 'config';
import { useTranslation } from 'react-i18next';

export const Declined = () => {
  const { t } = useTranslation();

  return (
    <Placeholder
      className="py-8 lg:h-[550px]"
      title={t('declined.title')}
      text={t('declined.text')}
      icon="⛔️"
      action={
        <Button
          size="medium"
          variant="contained"
          LinkComponent="a"
          href={SUPPORT_LINK}
          target="_blank"
        >
          {t('common.contact_support')}
        </Button>
      }
    />
  );
};
