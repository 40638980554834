import classNames from 'classnames';
import { FC } from 'react';

export type ContainerProps = {
  narrow?: boolean;
  className?: string;
  adaptive?: boolean;
};

export const Container: FC<ContainerProps> = ({
  narrow = false,
  className,
  children,
  adaptive = false,
}) => {
  const adaptiveClassNames = narrow
    ? 'w-full lg:w-[790px]'
    : 'w-full lg:w-[1152px]';
  const staticClassNames = narrow ? 'w-[790px]' : 'w-[1152px]';

  return (
    <div
      className={classNames(
        adaptive ? adaptiveClassNames : staticClassNames,
        'mx-auto',
        className,
      )}
    >
      {children}
    </div>
  );
};
