import API from '../../api';
import { useQuery } from '@tanstack/react-query';

export const useSettings = () => {
  return useQuery({
    queryKey: ['settings'],
    keepPreviousData: true,
    queryFn: async () => {
      const response = await API.Settings.getAppSettings();
      return response.data;
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
  });
};
