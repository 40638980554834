import { MerchantApplicationDto, MerchantDto } from 'api/generated';
import { generatePath } from 'react-router-dom';
import { routePaths } from 'router';

export const merchantHomePage = (merchant: MerchantDto) => {
  if (merchant.stores.length > 0) {
    return generatePath(routePaths.STORE, {
      id: merchant.stores[0].id,
    });
  } else {
    return routePaths.CREATE_STORE;
  }
};

export const getOngoingApplication = (
  applications: MerchantApplicationDto[],
) => {
  return applications.find(
    (application) =>
      application.status !== 'APPROVED' &&
      application.status !== 'DECLINED' &&
      application.status !== 'VERIFICATION_FAILED' &&
      application.status !== 'REVOKED',
  );
};
