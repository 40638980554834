import { SUPPORT_LINK } from '../../../config';
import { LoadingButton } from '@mui/lab';
import { Skeleton, TextField, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AppContext } from 'AppContext';
import API from 'api';
import { MUIButtonSkeleton } from 'components/MUIButtonSkeleton';
import { useStore, useStoreIfExists } from 'queries/store/useStore';
import { FC, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const StoreCommonSettings: FC = () => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [storeName, setStoreName] = useState('');
  const { showSnackbar } = useContext(AppContext);
  const { id } = useParams();
  const { data, refetch } = useStore(id);
  const store = useStoreIfExists(data);
  const queryClient = useQueryClient();

  const save = async () => {
    if (id && storeName) {
      setSaving(true);
      try {
        await API.Store.renameStore({
          storeId: id,
          newName: storeName,
        });
        await refetch();
        await queryClient.invalidateQueries({ queryKey: ['merchant'] });
        showSnackbar({
          message: t('settings.saved'),
        });
      } catch (e) {
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      } finally {
        setSaving(false);
      }
    }
  };

  if (store) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (storeName.length && id) {
            save();
          }
        }}
      >
        <div className="mb-6 flex flex-col gap-6 w-[570px]">
          <TextField
            inputProps={{ maxLength: 32 }}
            label={t('settings.store_name')}
            variant="outlined"
            fullWidth
            defaultValue={store.name}
            onChange={(e) => {
              setStoreName(e.target.value);
            }}
          />
          {store.telegramBotUsername && (
            <Tooltip
              placement="top"
              arrow
              title={
                <div className="whitespace-pre-line font-normal text-[13px] leading-[18px]">
                  <Trans
                    i18nKey="settings.store_bot_hint"
                    t={t}
                    components={[
                      <a
                        href={SUPPORT_LINK}
                        rel="noreferrer"
                        className="underline"
                        target="_blank"
                        key="terms"
                        color="primary"
                      />,
                    ]}
                  />
                </div>
              }
            >
              <TextField
                disabled
                InputProps={{ readOnly: true }}
                label={t('settings.store_bot')}
                variant="outlined"
                fullWidth
                value={store.telegramBotUsername}
              />
            </Tooltip>
          )}
        </div>
        <LoadingButton
          type="submit"
          loading={saving}
          disabled={!storeName.length || storeName === store.name}
          variant="contained"
          size="large"
        >
          {t('settings.save')}
        </LoadingButton>
      </form>
    );
  } else {
    return (
      <>
        <div className="mb-6 w-[570px] flex flex-col gap-6">
          <Skeleton variant="rounded" height={56} />
          <Skeleton variant="rounded" height={56} />
        </div>
        <MUIButtonSkeleton variant="contained" size="large">
          {t('settings.save')}
        </MUIButtonSkeleton>
      </>
    );
  }
};
