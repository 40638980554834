import { Container } from '../../components/Container';
import { TERMS_OF_USE_PATH } from '../../config';
import * as styles from './Footer.module.css';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Footer: FC<{ adaptive?: boolean }> = ({ adaptive }) => {
  const { t } = useTranslation();

  return (
    <footer>
      <Container adaptive={adaptive}>
        <div className={styles.footer}>
          <a
            className={styles.footerLink}
            href={TERMS_OF_USE_PATH}
            target="_blank"
            rel="noreferrer"
          >
            {t('footer.terms')}
          </a>
        </div>
      </Container>
    </footer>
  );
};
