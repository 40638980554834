/**
 * "10.00" => 10
 */
export function decodeServerNumber<T extends string | undefined>(
  stringNumber: T,
): T extends string ? number : undefined {
  if (typeof stringNumber === 'string') {
    return Number(stringNumber) as T extends string ? number : undefined;
  }
  return undefined as T extends string ? number : undefined;
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}
