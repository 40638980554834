import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import API from 'api';
import {
  GetStorePayoutsRestRequestCurrenciesEnum,
  PayoutDtoStatusEnum,
  PayoutListRestResponse,
  RestDataResponseGetPayoutListStatusPayoutListRestResponse,
} from 'api/generated';
import { Dayjs } from 'dayjs';

export const getWithdrawalListCsv: (params: {
  storeId: string;
  from: Dayjs;
  to: Dayjs;
  statuses?: string[];
  currencies?: string[];
}) => Promise<Blob> = async ({ storeId, currencies, statuses, from, to }) => {
  const response = await API.Payout.getPayoutListAsFile(
    {
      createdDateTimeFrom: from.toISOString(),
      createdDateTimeTo: to.toISOString(),
      statuses: statuses?.length
        ? (statuses as PayoutDtoStatusEnum[])
        : undefined,
      currencies: currencies?.length
        ? (currencies as GetStorePayoutsRestRequestCurrenciesEnum[])
        : undefined,
      storeId,
    },
    {
      responseType: 'blob',
    },
  );

  return response.data as unknown as Blob;
};

const getWithdrawalList: (params: {
  storeId: string;
  limit?: number;
  offset?: number;
  from?: string;
  to?: string;
  statuses?: PayoutDtoStatusEnum[];
  currencies?: GetStorePayoutsRestRequestCurrenciesEnum[];
}) => Promise<RestDataResponseGetPayoutListStatusPayoutListRestResponse> = async ({
  storeId,
  limit = 10,
  offset = 0,
  currencies,
  statuses,
  from,
  to,
}) => {
  const withdrawalList = await API.Payout.getPayoutList({
    // strange api method name will disappear after WT-3741
    storeId,
    limit,
    offset,
    currencies,
    statuses,
    createdDateTimeFrom: from,
    createdDateTimeTo: to,
  });
  return withdrawalList.data;
};

export type UseWithdrawalListResult =
  | 'NOT_FOUND'
  | 'ACCESS_DENIED'
  | PayoutListRestResponse
  | undefined;

export const useWithdrawalList: (params: {
  id?: string;
  limit?: number;
  offset?: number;
  from?: Dayjs;
  to?: Dayjs;
  statuses?: PayoutDtoStatusEnum[];
  currencies?: GetStorePayoutsRestRequestCurrenciesEnum[];
}) => UseQueryResult<UseWithdrawalListResult> = ({
  id,
  limit,
  offset,
  currencies,
  statuses,
  from,
  to,
}) => {
  return useQuery({
    queryKey: [
      'withdrawalList',
      { id, limit, offset, currencies, statuses, from, to },
    ],
    keepPreviousData: true,
    queryFn: async () => {
      if (!id) {
        return 'NOT_FOUND';
      }
      try {
        const withdrawalListResponse = await getWithdrawalList({
          storeId: id,
          limit,
          offset,
          currencies: currencies?.length ? currencies : undefined,
          statuses: statuses?.length ? statuses : undefined,
          from: from?.toISOString(),
          to: to?.toISOString(),
        });
        if (withdrawalListResponse.status === 'SUCCESS') {
          return withdrawalListResponse.data as PayoutListRestResponse;
        } else {
          return withdrawalListResponse.status;
        }
      } catch (e) {
        return 'NOT_FOUND';
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
  });
};
