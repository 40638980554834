import { useLocale } from '../hooks/useLocale';
import { Locales } from '../i18n';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS, ruRU } from '@mui/x-date-pickers/locales';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

const localizationAdapterTexts: Record<
  Locales,
  typeof ruRU.components.MuiLocalizationProvider.defaultProps.localeText
> = {
  ru: ruRU.components.MuiLocalizationProvider.defaultProps.localeText,
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
};

export const TableCommonFilters: FC<{
  hasSearch?: boolean;
  className?: string;
  from?: Dayjs;
  to?: Dayjs;
  setFrom: (data: { formattedDate: string | null; date: Dayjs | null }) => void;
  setTo: (data: { formattedDate: string | null; date: Dayjs | null }) => void;
  fromInputRef?: RefObject<HTMLInputElement>;
  toInputRef?: RefObject<HTMLInputElement>;
}> = ({ className, from, to, setFrom, setTo, fromInputRef, toInputRef }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const formatDate = (date: Dayjs | null) => {
    const formattedDate =
      date && date.isValid() ? date.format('YYYY-MM-DD') : null;

    return { formattedDate, date };
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={locale}
      localeText={localizationAdapterTexts[locale]}
    >
      <div
        className={classNames(
          'flex',
          'items-center',
          'gap-4',
          'py-4',
          className,
        )}
      >
        <div className="grow-[1]">
          <DatePicker
            inputRef={fromInputRef}
            sx={{ width: '100%' }}
            label={t('common.date_range')}
            value={from || null}
            onChange={(date) => {
              setFrom(formatDate(date));
            }}
          />
        </div>
        <div className="grow-[1]">
          <DatePicker
            inputRef={toInputRef}
            sx={{ width: '100%' }}
            value={to || null}
            onChange={(date) => {
              setTo(formatDate(date));
            }}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};
