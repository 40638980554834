import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FormikHandlers } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const BusinessActivitySelect: FC<{
  onChange: FormikHandlers['handleChange'];
  value?: string;
}> = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel>{t('questionnaire.activity')}</InputLabel>
      <Select
        name="businessActivity"
        label={t('questionnaire.activity')}
        onChange={onChange}
        value={value}
        required
      >
        <MenuItem
          sx={{ whiteSpace: 'normal' }}
          value="Physical goods and stores (e.g. clothes, electronics, tickets)"
        >
          {t('questionnaire.activities.goods')}
        </MenuItem>
        <MenuItem
          sx={{ whiteSpace: 'normal' }}
          value="Services (any professional or business services, e.g. consulting, beauty services, bookkeeping)"
        >
          {t('questionnaire.activities.services')}
        </MenuItem>
        <MenuItem
          sx={{ whiteSpace: 'normal' }}
          value="Digital goods (Content, software and games, in-game currency, except for subscriptions)"
        >
          {t('questionnaire.activities.digital_goods')}
        </MenuItem>
        <MenuItem sx={{ whiteSpace: 'normal' }} value="Subscriptions">
          {t('questionnaire.activities.subscriptions')}
        </MenuItem>
        <MenuItem
          sx={{ whiteSpace: 'normal' }}
          value="Licensed betting or gambling"
        >
          {t('questionnaire.activities.betting')}
        </MenuItem>
        <MenuItem sx={{ whiteSpace: 'normal' }} value="Other">
          {t('questionnaire.activities.other')}
        </MenuItem>
      </Select>
      {value === 'Subscriptions' && (
        <div className="mt-2">
          <Alert severity="warning">
            {t('questionnaire.subscriptions_warning')}
          </Alert>
        </div>
      )}
    </FormControl>
  );
};
