import { Button } from '@mui/material';
import { Placeholder } from 'components/Placeholder';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routePaths } from 'router';

export const Revoked = () => {
  const { t } = useTranslation();

  return (
    <Placeholder
      className="py-8 lg:h-[550px]"
      title={t('revoked.title')}
      text={t('revoked.text')}
      icon="⛔️"
      action={
        <Button
          size="medium"
          variant="contained"
          component={Link}
          to={routePaths.NEW_APPLICATION}
        >
          {t('revoked.create')}
        </Button>
      }
    />
  );
};
