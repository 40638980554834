import { Button } from '@mui/material';
import { Placeholder } from 'components/Placeholder';
import { useMerchant } from 'queries/merchant/useMerchant';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { merchantHomePage } from 'utils/merchant';

export const Approved = () => {
  const { t } = useTranslation();
  const { data: merchant } = useMerchant();

  if (!merchant) {
    return null;
  }

  return (
    <Placeholder
      className="py-8 lg:h-[550px]"
      title={t('approved.title')}
      text={
        merchant.applications.length > 1
          ? t('approved.text', {
              payoutFeePercent: merchant.conditions.payoutFeePercent,
              paymentFeePercent: merchant.conditions.paymentFeePercent,
            })
          : t('approved.first_application_text', {
              payoutFeePercent: merchant.conditions.payoutFeePercent,
              paymentFeePercent: merchant.conditions.paymentFeePercent,
            })
      }
      icon="🎉"
      action={
        <Button
          size="medium"
          variant="contained"
          component={Link}
          to={merchantHomePage(merchant)}
        >
          {t('approved.go_to_wallet_pay')}
        </Button>
      }
    />
  );
};
