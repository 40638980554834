import { MerchantDto } from '../api/generated';
import { merchantHomePage } from '../utils/merchant';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AppContext } from 'AppContext';
import API from 'api';
import { PageContainer } from 'components/PageContainer';
import { BOT_USERNAME } from 'config';
import { useTimeout } from 'hooks/useTimeout';
import { ReactComponent as CrystalSVG } from 'images/crystal.svg';
import { FC, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { AuthStatuses, auth } from 'utils/auth';

export const NotWalletUser: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [authPolling, setAuthPolling] = useState(false);
  const authWindowRef = useRef<Window | null>(null);
  const { tgUser } = useAppSelector((state) => state.user);
  const { showSnackbar } = useContext(AppContext);
  const [pollAuthTimeout] = useTimeout();
  const queryClient = useQueryClient();

  const pollAuth = async () => {
    if (tgUser) {
      const data = await auth(tgUser);
      if (data.status === AuthStatuses.NOT_WALLET_USER) {
        pollAuthTimeout(() => {
          pollAuth();
        }, 2000);
      } else if (data.status === AuthStatuses.ACCESS_DENIED) {
        showSnackbar({
          message: t('welcome.access_denied'),
        });
      } else if (data.status === AuthStatuses.GENERIC_ERROR) {
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      } else {
        setAuthPolling(false);
        authWindowRef.current?.close();
        const response = await API.Merchant.createMerchantForUser();
        await queryClient.invalidateQueries({ queryKey: ['merchant'] });
        navigate(merchantHomePage(response.data.data as MerchantDto), {
          replace: true,
        });
      }
    }
  };

  return (
    <PageContainer narrow>
      <div className="flex flex-col items-center justify-center pt-[153px] pb-[167px] text-center">
        <CrystalSVG width="87" height="87" className="mb-3.5" />
        <Typography variant="body1">{t('not_wallet_user.title')}</Typography>
        <Typography variant="body2" sx={{ mt: 0.5, mb: 3 }}>
          <span className="opacity-60 whitespace-pre-line">
            {t('not_wallet_user.text')}
          </span>
        </Typography>
        <LoadingButton
          loading={authPolling}
          variant="contained"
          component="a"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            authWindowRef.current = window.open(
              `https://t.me/${BOT_USERNAME}`,
              'startBotWindow',
              'width=600,height=300',
            );
            setAuthPolling(true);
            pollAuth();
          }}
        >
          {t('not_wallet_user.open')}
        </LoadingButton>
      </div>
    </PageContainer>
  );
};
