import { App } from './App';
import { ProtectedRoute } from './containers/ProtectedRoute';
import { Application } from './pages/Application/Application';
import { NewApplication } from './pages/Application/NewApplication';
import { ClientError } from './pages/ClientError';
import { CreateStore } from './pages/CreateStore';
import { NotFound } from './pages/NotFound';
import { NotWalletUser } from './pages/NotWalletUser';
import { Store } from './pages/Store/Store';
import { StoreAssetBalance } from './pages/Store/StoreAssetBalance';
import { StoreBalance } from './pages/Store/StoreBalance';
import { StoreOrders } from './pages/Store/StoreOrders';
import { StoreSettings } from './pages/Store/StoreSettings';
import { Welcome } from './pages/Welcome/Welcome';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

export const routePaths = {
  ROOT: '/',
  NOT_WALLET_USER: '/not_wallet_user',
  APPLICATION: '/application/:id',
  NEW_APPLICATION: '/application/new',
  CREATE_STORE: '/create_store',
  STORE: '/store/:id',
  STORE_ORDERS: '/store/:id/orders',
  STORE_SETTINGS: '/store/:id/settings',
  STORE_ASSET_BALANCE: '/store/:id/:currency',
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const routes = [
  {
    path: routePaths.ROOT,
    element: <App />,
    errorElement: <ClientError />,
    children: [
      {
        index: true,
        element: <Welcome />,
      },
      {
        path: routePaths.NOT_WALLET_USER,
        element: <NotWalletUser />,
      },
      {
        path: routePaths.APPLICATION,
        element: <Application />,
      },
      {
        path: routePaths.NEW_APPLICATION,
        element: <NewApplication />,
      },
      {
        path: routePaths.CREATE_STORE,
        element: (
          <ProtectedRoute>
            <CreateStore />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.STORE,
        element: (
          <ProtectedRoute>
            <Store />
          </ProtectedRoute>
        ),
        errorElement: <ClientError />,
        children: [
          {
            path: routePaths.STORE,
            index: true,
            element: <StoreBalance />,
          },
          { path: routePaths.STORE_ORDERS, element: <StoreOrders /> },
          { path: routePaths.STORE_SETTINGS, element: <StoreSettings /> },
          {
            path: routePaths.STORE_ASSET_BALANCE,
            element: <StoreAssetBalance />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export const router = sentryCreateBrowserRouter(routes);
