import { Skeleton, Typography } from '@mui/material';
import { useLocale } from 'hooks/useLocale';
import { FC, ReactNode } from 'react';
import { printFiatAmount } from 'utils/amount';

const FiatBalanceBase: FC<{
  className?: string;
  value: ReactNode;
  bottom: ReactNode;
  bottomIcon?: ReactNode;
}> = ({ className, value, bottom, bottomIcon }) => {
  return (
    <div className={className}>
      <Typography variant="h4">{value}</Typography>
      <Typography variant="body2">
        <div className="flex items-center gap-1">
          <span className="opacity-60">{bottom}</span>
          {bottomIcon}
        </div>
      </Typography>
    </div>
  );
};

export const FiatBalance: FC<{
  amount: number;
  currency: string;
  bottom: ReactNode;
  bottomIcon?: ReactNode;
  className?: string;
}> = ({ amount, currency, bottom, className, bottomIcon }) => {
  const locale = useLocale();

  return (
    <FiatBalanceBase
      className={className}
      value={printFiatAmount({ amount, currency, languageCode: locale })}
      bottom={bottom}
      bottomIcon={bottomIcon}
    />
  );
};

export const FiatBalanceSkeleton: FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <FiatBalanceBase
      className={className}
      value={<Skeleton width={100} />}
      bottom={<Skeleton width={150} />}
    />
  );
};
