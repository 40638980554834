import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import API from 'api';
import {
  GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum,
  GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum,
  GetStoreOrdersAsFileRestRequestStatusesEnum,
  GetStoreOrdersResponse,
  RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse,
} from 'api/generated';
import { Dayjs } from 'dayjs';

export const getOrderListCsv: (params: {
  storeId: string;
  from: Dayjs;
  to: Dayjs;
  orderCurrencies?: string[];
  payerCurrencies?: string[];
  statuses?: string[];
  searchString?: string;
}) => Promise<Blob> = async ({
  storeId,
  orderCurrencies,
  statuses,
  from,
  to,
  searchString,
  payerCurrencies,
}) => {
  const response = await API.Order.getStoreOrdersAsFile(
    {
      searchString,
      createdDateTimeFrom: from.toISOString(),
      createdDateTimeTo: to.toISOString(),
      statuses: statuses?.length
        ? (statuses as GetStoreOrdersAsFileRestRequestStatusesEnum[])
        : undefined,
      orderCurrencies: orderCurrencies?.length
        ? (orderCurrencies as GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum[])
        : undefined,
      payerCurrencies: payerCurrencies?.length
        ? (payerCurrencies as GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum[])
        : undefined,
      storeId,
    },
    {
      responseType: 'blob',
    },
  );

  return response.data as unknown as Blob;
};

const getOrderList: (params: {
  storeId: string;
  limit?: number;
  offset?: number;
  from?: string;
  to?: string;
  statuses?: GetStoreOrdersAsFileRestRequestStatusesEnum[];
  orderCurrencies?: GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum[];
  payerCurrencies?: GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum[];
  searchString?: string;
}) => Promise<RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse> = async ({
  storeId,
  limit = 10,
  offset = 0,
  orderCurrencies,
  statuses,
  from,
  to,
  searchString,
  payerCurrencies,
}) => {
  const orderList = await API.Order.getStoreOrders({
    storeId,
    limit,
    offset,
    orderCurrencies,
    statuses,
    createdDateTimeFrom: from,
    createdDateTimeTo: to,
    searchString,
    payerCurrencies,
  });
  return orderList.data;
};

export type UseOrderListResult =
  | 'NOT_FOUND'
  | 'ACCESS_DENIED'
  | GetStoreOrdersResponse
  | undefined;

export const useOrderList: (params: {
  storeId?: string;
  limit?: number;
  offset?: number;
  from?: Dayjs;
  to?: Dayjs;
  statuses?: GetStoreOrdersAsFileRestRequestStatusesEnum[];
  orderCurrencies?: GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum[];
  payerCurrencies?: GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum[];
  searchString?: string;
}) => UseQueryResult<UseOrderListResult> = ({
  storeId,
  limit,
  offset,
  orderCurrencies,
  payerCurrencies,
  statuses,
  from,
  to,
  searchString,
}) => {
  return useQuery({
    queryKey: [
      'orderList',
      {
        storeId,
        limit,
        offset,
        orderCurrencies,
        payerCurrencies,
        statuses,
        from,
        to,
        searchString,
      },
    ],
    keepPreviousData: true,
    queryFn: async () => {
      if (!storeId) {
        return 'NOT_FOUND';
      }
      try {
        const orderListResponse = await getOrderList({
          storeId,
          limit,
          offset,
          orderCurrencies: orderCurrencies?.length
            ? orderCurrencies
            : undefined,
          payerCurrencies: payerCurrencies?.length
            ? payerCurrencies
            : undefined,
          statuses: statuses?.length ? statuses : undefined,
          from: from?.toISOString(),
          to: to?.toISOString(),
          searchString,
        });
        if (orderListResponse.status === 'SUCCESS') {
          return orderListResponse.data as GetStoreOrdersResponse;
        } else {
          return orderListResponse.status;
        }
      } catch (e) {
        return 'NOT_FOUND';
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
  });
};
