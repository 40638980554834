import { BalanceByAssetCell } from './BalanceByAssetCell';
import { CheckCircleOutline, Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { AppContext } from 'AppContext';
import API from 'api';
import { StoreBalanceDto } from 'api/generated';
import { Modal } from 'components/Modal';
import { CurrencyLogo } from 'containers/CurrencyLogo';
import { VerificationModal } from 'containers/VerificationModal';
import { useLocale } from 'hooks/useLocale';
import { useStore, useStoreIfExists } from 'queries/store/useStore';
import { FC, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Currency } from 'types';
import { printCryptoAmount, printFiatAmount } from 'utils/amount';
import { decodeServerNumber } from 'utils/number';

export const WithdrawModal: FC<{
  open: boolean;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
  balance?: StoreBalanceDto;
}> = ({ open, onClose, onSuccess, balance }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const [withdrawalState, setWithdrawalState] = useState<
    'progress' | 'success' | null
  >(null);
  const { id } = useParams();
  const { data } = useStore(id);
  const store = useStoreIfExists(data);
  const { showSnackbar } = useContext(AppContext);
  const balances = balance
    ? [balance]
    : store?.balances
    ? store.balances.filter(
        (balance) => decodeServerNumber(balance.availableForPayoutGross) > 0,
      )
    : [];
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);

  async function withdraw() {
    if (store?.id) {
      setWithdrawalState('progress');
      try {
        let withdrawResponse;

        if (balance) {
          withdrawResponse = await API.Payout.proceedPayout({
            storeId: store.id,
            amountGross: {
              currencyCode: balance.currency,
              amount: balance.availableForPayoutGross,
            },
          });
        } else {
          withdrawResponse = await API.Payout.proceedPayoutForAllAccounts({
            storeId: store.id,
          });
        }

        if (withdrawResponse.data.status === 'SUCCESS') {
          setWithdrawalState('success');
        } else if (withdrawResponse.data.status === 'VERIFICATION_REQUIRED') {
          setWithdrawalState(null);
          setVerificationModalOpen(true);
        } else {
          throw Error;
        }
      } catch (e) {
        setWithdrawalState(null);
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      }
    }
  }

  const onCloseWrapper = () => {
    if (withdrawalState !== 'progress') {
      onClose();
    }
  };

  if (store && store.aggregatedBalance) {
    return (
      <Modal
        open={open}
        onClose={onCloseWrapper}
        className="w-[400px] px-6 pb-5 pt-4"
      >
        <VerificationModal
          open={verificationModalOpen}
          onClose={() => setVerificationModalOpen(false)}
          onComplete={() => {
            setVerificationModalOpen(false);
            withdraw();
          }}
        />
        {withdrawalState === null && (
          <>
            <div className="mb-5 flex justify-between items-center h-10">
              <Typography variant="h6">{t('withdrawal.title')}</Typography>
              <IconButton
                onClick={onCloseWrapper}
                sx={{ top: -4, right: -12, position: 'relative' }}
              >
                <Close />
              </IconButton>
            </div>
            <Typography variant="body1" mb={1.5}>
              <span className="opacity-60">
                <Trans
                  values={{
                    amount: balance
                      ? printFiatAmount({
                          languageCode: locale,
                          amount: decodeServerNumber(
                            balance.availableForPayoutGrossInFiat as string,
                          ),
                          currency: balance.fiatCurrency as string,
                        })
                      : printFiatAmount({
                          languageCode: locale,
                          amount: decodeServerNumber(
                            store.aggregatedBalance.availableForPayoutGross,
                          ),
                          currency: store.aggregatedBalance.currency,
                        }),
                  }}
                  i18nKey="withdrawal.text"
                  t={t}
                  components={[<span key="amount" className="font-semibold" />]}
                />
              </span>
            </Typography>
            <div className="mb-5 -mx-5">
              {balances.map((balance) => {
                return (
                  <BalanceByAssetCell
                    key={balance.currency}
                    currency={balance.currency as Currency}
                    amount={decodeServerNumber(balance.availableForPayoutGross)}
                    fiatCurrency={balance.fiatCurrency}
                    fiatAmount={decodeServerNumber(
                      balance.availableForPayoutGrossInFiat,
                    )}
                  />
                );
              })}
            </div>

            <Typography variant="caption" mb={3} component="div">
              <div className="opacity-60">
                {store.payoutFeePercent > 0 ? (
                  <>
                    {t('withdrawal.fee_text', {
                      percent: store.payoutFeePercent,
                    })}
                    <br />
                    <span className="font-medium">
                      {balances
                        .map((balance) => {
                          return printCryptoAmount({
                            amount: decodeServerNumber(
                              balance.availableForPayoutFee,
                            ),
                            currency: balance.currency as Currency,
                          });
                        })
                        .join(', ')}
                    </span>
                  </>
                ) : (
                  t('withdrawal.zero_fee_text')
                )}
              </div>
            </Typography>
            <div className="h-px bg-black opacity-[0.12] mb-6" />
          </>
        )}

        {withdrawalState === 'progress' && (
          <div className="py-5 flex flex-col items-center justify-center">
            <CircularProgress size={24} thickness={6} />
            <Typography variant="body1" mt={1.5}>
              {t('withdrawal.progress_title')}
            </Typography>
            <Typography variant="body2" mt={0.25}>
              <span className="opacity-60">
                {t('withdrawal.progress_text')}
              </span>
            </Typography>
          </div>
        )}

        {withdrawalState === 'success' && (
          <div className="py-5 flex flex-col items-center justify-center">
            <CheckCircleOutline fontSize="large" color="primary" />
            <Typography variant="body1" mt={0.5}>
              {t('withdrawal.success_title')}
            </Typography>
            <Typography variant="body2" mt={0.25}>
              <span className="opacity-60">{t('withdrawal.success_text')}</span>
            </Typography>
            <div className="flex flex-col gap-3 mt-5">
              {balances.map((balance) => {
                return (
                  <div
                    key={balance.currency}
                    className="flex items-center gap-2"
                  >
                    <CurrencyLogo
                      currency={balance.currency as Currency}
                      size={24}
                    />
                    <Typography variant="body1">
                      {printCryptoAmount({
                        amount: decodeServerNumber(
                          balance.availableForPayoutNet,
                        ),
                        currency: balance.currency as Currency,
                      })}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="flex justify-between items-center gap-2">
          <Typography variant="body1">
            <Trans
              values={{
                amount: balance
                  ? printFiatAmount({
                      amount: decodeServerNumber(
                        balance.availableForPayoutNetInFiat as string,
                      ),
                      currency: balance.fiatCurrency as string,
                      languageCode: locale,
                    })
                  : printFiatAmount({
                      amount: decodeServerNumber(
                        store.aggregatedBalance.availableForPayoutNet,
                      ),
                      currency: store.aggregatedBalance.currency,
                      languageCode: locale,
                    }),
              }}
              i18nKey={
                store.payoutFeePercent > 0
                  ? 'withdrawal.after_fee'
                  : 'withdrawal.total'
              }
              t={t}
              components={[<span key="amount" className="font-semibold" />]}
            />
          </Typography>
          <Button
            disabled={withdrawalState === 'progress'}
            size="large"
            variant="contained"
            onClick={async () => {
              if (withdrawalState === null) {
                await withdraw();
              } else if (withdrawalState === 'success') {
                onClose();
                onSuccess();
                setWithdrawalState(null);
              }
            }}
          >
            {withdrawalState === 'success'
              ? t('common.done')
              : t('balance.withdraw')}
          </Button>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};
