import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const IndividualGeneral: FC<{
  formik: FormikProps<{
    phoneNumber?: string;
    email?: string;
    residentialAddress?: string;
  }>;
  onBack: VoidFunction;
}> = ({ formik, onBack }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-6">
        <TextField
          name="phoneNumber"
          value={formik.values.phoneNumber}
          error={!!formik.errors.phoneNumber}
          helperText={formik.errors.phoneNumber}
          onChange={formik.handleChange}
          required
          placeholder={t('questionnaire.individual_phone') as string}
        />
        <TextField
          name="email"
          type="email"
          value={formik.values.email}
          error={!!formik.errors.email}
          helperText={formik.errors.email}
          onChange={formik.handleChange}
          required
          placeholder={t('questionnaire.individual_email') as string}
        />
        <TextField
          name="residentialAddress"
          value={formik.values.residentialAddress}
          error={!!formik.errors.residentialAddress}
          helperText={formik.errors.residentialAddress}
          onChange={formik.handleChange}
          required
          placeholder={t('questionnaire.individual_address') as string}
        />
      </div>
      <div className="mt-7 flex gap-3">
        <Button size="large" type="button" variant="outlined" onClick={onBack}>
          {t('common.back')}
        </Button>
        <LoadingButton
          loading={formik.isSubmitting}
          type="submit"
          size="large"
          variant="contained"
          disabled={Object.keys(formik.errors).length > 0}
        >
          {t('common.next')}
        </LoadingButton>
      </div>
    </form>
  );
};
