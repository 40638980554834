import { locales } from '../i18n';
import { useTranslation } from 'react-i18next';

export const useLocale = () => {
  const { i18n } = useTranslation();

  return i18n.language.split('-')[0] === locales['ru']
    ? locales['ru']
    : locales['en'];
};
