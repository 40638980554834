import { StoreCommonSettings } from './components/StoreCommonSettings';
import { StoreContent } from './components/StoreContent';
import { StoreIntegrationSettings } from './components/StoreIntegrationSettings';
import { Box, Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const ACTIVE_TABS = {
  COMMON: 'common',
  INTEGRATION: 'integration',
} as const;

export const StoreSettings: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || ACTIVE_TABS.INTEGRATION;

  return (
    <StoreContent>
      <Box mb={4} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => {
            setSearchParams({ tab: newValue }, { replace: true });
          }}
        >
          <Tab
            label={t('settings.integration')}
            value={ACTIVE_TABS.INTEGRATION}
          />
          <Tab label={t('settings.store')} value={ACTIVE_TABS.COMMON} />
        </Tabs>
      </Box>
      {activeTab === ACTIVE_TABS.INTEGRATION && <StoreIntegrationSettings />}
      {activeTab === ACTIVE_TABS.COMMON && <StoreCommonSettings />}
    </StoreContent>
  );
};
