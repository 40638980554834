// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RVt3 {\n  padding-block: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: baseline;\n}\n\n.pH68 {\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  color: #2196f3;\n}\n\n@media screen and (min-width: 1240px) {\n  .RVt3 {\n    padding-block: 26px;\n  }\n\n  .pH68 {\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.44px;\n    color: #000;\n    opacity: 0.6;\n  }\n\n  .pH68:hover {\n    opacity: 1;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/containers/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".footer {\n  padding-block: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: baseline;\n}\n\n.footerLink {\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.15px;\n  color: #2196f3;\n}\n\n@media screen and (min-width: 1240px) {\n  .footer {\n    padding-block: 26px;\n  }\n\n  .footerLink {\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.44px;\n    color: #000;\n    opacity: 0.6;\n  }\n\n  .footerLink:hover {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var footer = "RVt3";
export var footerLink = "pH68";
export default ___CSS_LOADER_EXPORT___;
