import { Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';
import { CurrencyLogo } from 'containers/CurrencyLogo';
import { useLocale } from 'hooks/useLocale';
import { FC, ReactNode } from 'react';
import { Currency } from 'types';
import { printCryptoAmount } from 'utils/amount';

const AssetBalanceBase: FC<{
  className?: string;
  currency: Currency;
  top: ReactNode;
  bottom: ReactNode;
  bottomIcon?: ReactNode;
}> = ({ className, currency, top, bottom, bottomIcon }) => {
  return (
    <div className={classNames('flex items-center gap-4 py-2 pr-4', className)}>
      <CurrencyLogo currency={currency} className="shrink-0" />
      <div>
        <Typography variant="h5">{top}</Typography>
        <Typography variant="body2">
          <div className="flex items-center gap-1">
            <span className="opacity-60">{bottom}</span>
            {bottomIcon}
          </div>
        </Typography>
      </div>
    </div>
  );
};

export const AssetBalanceSkeleton: FC<{
  className?: string;
  currency: Currency;
}> = ({ className, currency }) => {
  return (
    <AssetBalanceBase
      className={className}
      currency={currency}
      top={<Skeleton width={90} />}
      bottom={<Skeleton width={140} />}
    />
  );
};

export const AssetBalance: FC<{
  amount: number;
  currency: Currency;
  bottom: ReactNode;
  className?: string;
  bottomIcon?: ReactNode;
}> = ({ amount, currency, bottom, className, bottomIcon }) => {
  const locale = useLocale();

  return (
    <AssetBalanceBase
      className={className}
      currency={currency}
      bottomIcon={bottomIcon}
      top={printCryptoAmount({ amount, currency, languageCode: locale })}
      bottom={bottom}
    />
  );
};
