import { ThemeOptions } from '@mui/material';

export const muiTheme: ThemeOptions = {
  palette: {
    info: {
      main: '#000',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1240,
      xl: 1536,
    },
  },
};
