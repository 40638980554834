import { Modal as MUIModal, Paper } from '@mui/material';
import { FC } from 'react';

export const Modal: FC<{
  open: boolean;
  onClose: VoidFunction;
  className?: string;
}> = ({ open, onClose, className, children }) => {
  return (
    <MUIModal open={open} onClose={onClose}>
      <div className="h-full w-full flex overflow-auto" onClick={onClose}>
        <div onClick={(e) => e.stopPropagation()} className="m-auto p-5">
          <Paper elevation={24} className={className}>
            {children}
          </Paper>
        </div>
      </div>
    </MUIModal>
  );
};
