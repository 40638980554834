import { CircularProgress } from '@mui/material';
import SumsubWebSdk from '@sumsub/websdk-react';
import API from 'api';
import { useLocale } from 'hooks/useLocale';
import { FC, useEffect, useState } from 'react';

export const Sumsub: FC<{
  applicationId: string;
}> = ({ applicationId }) => {
  const [accessToken, setAccessToken] = useState<string>();
  const locale = useLocale();

  async function getSumsubToken(applicationId: string) {
    const response = await API.Merchant.getVerificationTokenForApplication({
      applicationId,
    });

    if (response.data.data) {
      return response.data.data.verificationToken;
    } else {
      return Promise.reject();
    }
  }

  useEffect(() => {
    getSumsubToken(applicationId).then((accessToken) => {
      setAccessToken(accessToken);
    });
  }, [applicationId]);

  return accessToken ? (
    <SumsubWebSdk
      config={{
        lang: locale,
      }}
      accessToken={accessToken}
      expirationHandler={() => getSumsubToken(applicationId)}
    />
  ) : (
    <div className="h-[200px] flex items-center justify-center">
      <CircularProgress />
    </div>
  );
};
