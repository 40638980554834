import { createContext } from 'react';

export type SnackbarProps = {
  message: string;
  autoHideDuration?: number;
};

export const AppContext = createContext<{
  showSnackbar: (props: SnackbarProps) => void;
}>({
  showSnackbar: () => {},
});
