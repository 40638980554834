import { FilterList } from '@mui/icons-material';
import { Badge, IconButton, Popover } from '@mui/material';
import classNames from 'classnames';
import { FC, useRef, useState } from 'react';

export const FiltersDropdown: FC<{ className?: string; active?: boolean }> = ({
  children,
  className,
  active = false,
}) => {
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  return (
    <div className={classNames('shrink-0', className)}>
      <IconButton ref={filtersButtonRef} onClick={() => setFiltersOpen(true)}>
        {active ? (
          <Badge color="primary" variant="dot">
            <FilterList />
          </Badge>
        ) : (
          <FilterList />
        )}
      </IconButton>
      <Popover
        open={filtersOpen}
        anchorEl={filtersButtonRef.current}
        onClose={() => setFiltersOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="w-[220px]">{children}</div>
      </Popover>
    </div>
  );
};
