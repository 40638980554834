import { PageContainer } from 'components/PageContainer';
import { Placeholder } from 'components/Placeholder';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <PageContainer narrow stretch={false} className="py-10">
      <Placeholder
        title={t('not_found.title')}
        text={t('not_found.text')}
        icon="🤔"
      />
    </PageContainer>
  );
};
