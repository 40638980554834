import { Locales } from './i18n';

export const APP_PREFIX = 'wpayMerchant';
export const PRODUCTION_BOT_USERNAME = 'wallet';
export const SUPPORT_LINK = 'https://t.me/WalletPay_supportbot';
export const TERMS_OF_USE_PATH = '/static/documents/terms_of_use.pdf?v=3';
export const DOCUMENTATION_LINK = 'https://docs.wallet.tg/pay/';
export const TELEGRAM_STARS_DOCUMENTATION_LINK =
  'https://core.telegram.org/bots/api';
export const DEMO_BOT_LINK = 'https://t.me/PineAppleDemoWPStoreBot';
export const FAQ_LINKS: Record<Locales, string> = {
  en: 'https://wallet.helpscoutdocs.com/category/187-wallet-pay',
  ru: 'https://walletru.helpscoutdocs.com/category/193-wallet-pay',
};

// process.env.BOT_USERNAME is an argument that should be passed during local development
// wallet is a string that will be replaced after build on demo stands and production.
export const BOT_USERNAME =
  process.env.IS_DEV && process.env.BOT_USERNAME
    ? process.env.BOT_USERNAME
    : 'wallet' || 'wallet';

if (process.env.IS_DEV && !process.env.BOT_USERNAME) {
  console.error(
    'In dev mode you have to pass BOT_USERNAME argument in `yarn start` command. Example: `BOT_USERNAME=demo3_2bot yarn start`',
  );
}

export function withAppPrefix<T extends string>(
  value: T,
): `${typeof APP_PREFIX}-${T}` {
  return `${APP_PREFIX}-${value}`;
}
