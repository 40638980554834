import { StoreContent } from './StoreContent';
import { Button } from '@mui/material';
import { Placeholder } from 'components/Placeholder';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import { routePaths } from 'router';

export const StoreOnboarding: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <StoreContent className="flex flex-col items-center justify-center">
      <Placeholder
        title={t('store.setup_title')}
        text={t('store.setup_text')}
        icon="🛠"
      />
      <Button
        sx={{
          mt: 3,
        }}
        component={Link}
        to={generatePath(routePaths.STORE_SETTINGS, { id })}
        variant="contained"
      >
        {t('store.setup_button')}
      </Button>
    </StoreContent>
  );
};
