import { useAppSelector } from '../../store';
import { useQuery } from '@tanstack/react-query';
import API from 'api';
import { MerchantDto } from 'api/generated';

const getMerchant = async () => {
  const merchant = await API.Merchant.findMerchantByUser();
  if (merchant.data.status === 'SUCCESS') {
    return merchant.data.data as MerchantDto;
  } else {
    return null;
  }
};

export const useMerchant = () => {
  const { jwtToken } = useAppSelector((state) => state.user);
  return useQuery({
    queryKey: ['merchant'],
    queryFn: async () => {
      return await getMerchant();
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    enabled: !!jwtToken,
  });
};
