import { useQuery, useQueryClient } from '@tanstack/react-query';
import API from 'api';
import { MerchantDto } from 'api/generated';

const getApplication = async (id: string) => {
  const merchant = await API.Merchant.findMerchantByUser();

  if (merchant.data.status === 'SUCCESS') {
    const data = merchant.data.data as MerchantDto;
    return data.applications.find((application) => application.id === id);
  } else {
    return null;
  }
};

export const useApplication = (id: string) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['application', id],
    queryFn: async () => {
      return await getApplication(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['merchant'] });
    },
    refetchInterval: 3000,
    refetchOnMount: false,
    refetchOnReconnect: true,
  });
};
