import { StoreContent } from './components/StoreContent';
import { StoreOrdersTable } from './components/StoreOrdersTable';
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { AppContext } from 'AppContext';
import {
  GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum,
  GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum,
  GetStoreOrdersAsFileRestRequestStatusesEnum,
} from 'api/generated';
import axios from 'axios';
import { MUIDataGridSkeleton } from 'components/MUIDataGridSkeleton';
import { Placeholder } from 'components/Placeholder';
import { FiltersDropdown } from 'containers/FiltersDropdown';
import { TableCommonFilters } from 'containers/TableCommonFilters';
import * as dayjs from 'dayjs';
import {
  useArrayParam,
  useDateParam,
  useNumberParam,
  useStringParam,
} from 'hooks/useSearchParam';
import { useTimeout } from 'hooks/useTimeout';
import { getOrderListCsv, useOrderList } from 'queries/order/useOrderList';
import { FC, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CurrencyEnum } from 'types';
import { downloadBlob } from 'utils/downloadBlob';

export const StoreOrders: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const { showSnackbar } = useContext(AppContext);
  const [searchStringChangeTimeout] = useTimeout();
  const fromInputRef = useRef<HTMLInputElement>(null);
  const toInputRef = useRef<HTMLInputElement>(null);

  const [from, updateFrom] = useDateParam('from');
  const [to, updateTo] = useDateParam('to');
  const [pageSize, updatePageSize] = useNumberParam('pageSize', 10);
  const [page, updatePage] = useNumberParam('page', 0);
  const [search, updateSearch] = useStringParam('search');
  const [orderCurrencies, addOrderCurrency, removeOrderCurrency] =
    useArrayParam('orderCurrencies', []);
  const [payerCurrencies, addPaymentCurrency, removePaymentCurrency] =
    useArrayParam('payerCurrencies', []);
  const [statuses, addStatus, removeStatus] = useArrayParam('statuses', []);

  const {
    data: ordersList,
    isLoading,
    isRefetching,
  } = useOrderList({
    storeId: id,
    limit: pageSize,
    offset: page * pageSize,
    from,
    to,
    orderCurrencies:
      orderCurrencies as GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum[],
    payerCurrencies:
      payerCurrencies as GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum[],
    statuses: statuses as GetStoreOrdersAsFileRestRequestStatusesEnum[],
    searchString: search,
  });

  const hasList =
    ordersList &&
    ordersList !== 'ACCESS_DENIED' &&
    ordersList !== 'NOT_FOUND' &&
    ordersList.total > 0;
  const areFiltersEmpty =
    !search &&
    !from &&
    !to &&
    statuses.length === 0 &&
    orderCurrencies.length === 0 &&
    payerCurrencies.length === 0;

  const canDownload = !!id && hasList;

  return (
    <StoreContent className="flex flex-col min-w-0">
      <div className="flex justify-between pt-2 pb-4">
        <Typography variant="h5">{t('store.orders')}</Typography>
        <LoadingButton
          size="small"
          variant="outlined"
          startIcon={<Download />}
          loading={downloadingCSV}
          disabled={!canDownload}
          onClick={async () => {
            if (!from) {
              fromInputRef.current?.focus();
            } else if (!to) {
              toInputRef.current?.focus();
            } else if (canDownload) {
              try {
                setDownloadingCSV(true);
                const data = await getOrderListCsv({
                  from,
                  to,
                  statuses,
                  orderCurrencies,
                  payerCurrencies,
                  storeId: id,
                });

                downloadBlob(
                  data,
                  `orders-${dayjs(Date.now()).format('YYYY-MM-DD-hh-mm')}.csv`,
                );
              } catch (e) {
                if (axios.isAxiosError(e) && e.response?.status === 400) {
                  showSnackbar({
                    message: t('common.csv_date_ranger_error'),
                  });
                } else {
                  showSnackbar({
                    message: t('common.something_went_wrong'),
                  });
                }
              } finally {
                setDownloadingCSV(false);
              }
            }
          }}
        >
          {t('common.download_csv')}
        </LoadingButton>
      </div>
      {!isLoading && (hasList || (!hasList && !areFiltersEmpty)) && (
        <div className="flex items-center gap-4 mb-4">
          <div className="grow-[2]">
            <TextField
              label={t('common.search')}
              fullWidth
              placeholder={t('orders.search_placeholder') as string}
              defaultValue={search}
              onChange={(e) => {
                searchStringChangeTimeout(() => {
                  updateSearch(e.target.value);
                }, 200);
              }}
            />
          </div>
          <TableCommonFilters
            fromInputRef={fromInputRef}
            from={from}
            toInputRef={toInputRef}
            to={to}
            setFrom={({ formattedDate }) => {
              updateFrom(formattedDate);
            }}
            setTo={({ formattedDate }) => {
              updateTo(formattedDate);
            }}
            className="grow"
          />
          <FiltersDropdown
            active={
              statuses.length > 0 ||
              orderCurrencies.length > 0 ||
              payerCurrencies.length > 0
            }
          >
            <FormControl sx={{ p: 2 }} component="fieldset" variant="standard">
              <Typography variant="body1">
                <span className="opacity-60">{t('orders.order_currency')}</span>
              </Typography>
              <FormGroup>
                {[...Object.values(CurrencyEnum), 'USD'].map((currency) => (
                  <FormControlLabel
                    key={currency}
                    sx={{ ml: 0 }}
                    control={
                      <Checkbox
                        name="orderCurrencies"
                        value={currency}
                        checked={orderCurrencies.includes(currency)}
                        onChange={(e) => {
                          e.target.checked
                            ? addOrderCurrency(e.target.value)
                            : removeOrderCurrency(e.target.value);
                        }}
                      />
                    }
                    label={currency}
                  />
                ))}
              </FormGroup>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <span className="opacity-60">
                  {t('orders.payment_currency')}
                </span>
              </Typography>
              <FormGroup>
                {Object.values(CurrencyEnum).map((currency) => (
                  <FormControlLabel
                    key={`payment-${currency}`}
                    sx={{ ml: 0 }}
                    control={
                      <Checkbox
                        name="payerCurrencies"
                        value={currency}
                        checked={payerCurrencies.includes(currency)}
                        onChange={(e) => {
                          e.target.checked
                            ? addPaymentCurrency(e.target.value)
                            : removePaymentCurrency(e.target.value);
                        }}
                      />
                    }
                    label={currency}
                  />
                ))}
              </FormGroup>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <span className="opacity-60">{t('orders.status')}</span>
              </Typography>
              <FormGroup>
                {Object.values(GetStoreOrdersAsFileRestRequestStatusesEnum).map(
                  (status) => (
                    <FormControlLabel
                      key={status}
                      sx={{ ml: 0 }}
                      control={
                        <Checkbox
                          name="statuses"
                          value={status}
                          checked={statuses.includes(status)}
                          onChange={(e) => {
                            e.target.checked
                              ? addStatus(e.target.value)
                              : removeStatus(e.target.value);
                          }}
                        />
                      }
                      label={t(`orders.${status.toLowerCase()}`)}
                    />
                  ),
                )}
              </FormGroup>
            </FormControl>
          </FiltersDropdown>
        </div>
      )}
      {isLoading ? (
        <MUIDataGridSkeleton
          rowHeight={52}
          headerRowHeight={56}
          rowsCount={5}
        />
      ) : hasList ? (
        <StoreOrdersTable
          page={page}
          pageSize={pageSize}
          updatePage={updatePage}
          updatePageSize={updatePageSize}
          isRefetching={isRefetching}
          ordersList={ordersList}
        />
      ) : (
        <Placeholder
          title={t('orders.no_orders_title')}
          text={t('orders.no_orders_text')}
          icon="🕔"
          className="grow"
        />
      )}
    </StoreContent>
  );
};
