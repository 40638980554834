import { MerchantDto } from '../api/generated';
import { PageContainer } from '../components/PageContainer';
import { Placeholder } from '../components/Placeholder';
import { SUPPORT_LINK } from '../config';
import { useMerchant } from '../queries/merchant/useMerchant';
import { getOngoingApplication } from '../utils/merchant';
import { Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, generatePath } from 'react-router-dom';
import { routePaths } from 'router';
import { useAppSelector } from 'store';

export const ProtectedRoute: FC = ({ children }) => {
  const { jwtToken } = useAppSelector((state) => state.user);
  const { data: merchant } = useMerchant();
  const { t } = useTranslation();

  function navigateNotApprovedMerchant(merchant: MerchantDto) {
    const ongoingApplication = getOngoingApplication(merchant.applications);

    if (ongoingApplication) {
      return (
        <Navigate
          to={generatePath(routePaths.APPLICATION, {
            id: ongoingApplication.id,
          })}
          replace
        />
      );
    } else {
      return <Navigate to={routePaths.NEW_APPLICATION} replace />;
    }
  }

  if (!jwtToken) {
    return <Navigate to={routePaths.ROOT} replace />;
  } else if (merchant) {
    switch (merchant.status) {
      case 'DECLINED':
      case 'VERIFICATION_FAILED':
        return (
          <PageContainer narrow stretch={false} adaptive>
            <Placeholder
              className="py-8 lg:h-[550px]"
              title={t('denied.title')}
              text={t('denied.text')}
              icon="⛔️"
              action={
                <Button
                  size="medium"
                  variant="contained"
                  LinkComponent="a"
                  href={SUPPORT_LINK}
                  target="_blank"
                >
                  {t('common.contact_support')}
                </Button>
              }
            />
          </PageContainer>
        );
      case 'NOT_APPROVED':
        return navigateNotApprovedMerchant(merchant);
      case 'APPROVED':
        return <>{children}</>;
    }
  } else {
    return <PageContainer />;
  }
};
