import { useSettings } from '../queries/settings/useSettings';
import { TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { ChangeEvent, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type StoreFormFields = {
  name: string;
  telegramBotUsername: string;
};

const telegramBotUsernameRegExp = /^(?:@(\w+)|https:\/\/t\.me\/(\w+))$/;

export const CreateStoreForm: FC<{
  onSubmit: (params: StoreFormFields) => void;
  renderActions: (params: {
    submit: FormikHelpers<StoreFormFields>['submitForm'];
    valid: boolean;
  }) => ReactNode;
}> = ({ onSubmit, renderActions }) => {
  const { data: settings } = useSettings();
  const { t } = useTranslation();
  const formik = useFormik<StoreFormFields>({
    initialValues: {
      name: '',
      telegramBotUsername: '',
    },
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.telegramBotUsername.match(telegramBotUsernameRegExp)) {
        errors.telegramBotUsername = t('create_store.bot_validation');
      }

      if (
        settings &&
        values.name.length > settings.storeNameLength.maxInclusive
      ) {
        errors.name = t('create_store.name_validation_max', {
          max: settings.storeNameLength.maxInclusive,
        });
      }

      if (
        settings &&
        values.name.length < settings.storeNameLength.minInclusive
      ) {
        errors.name = t('create_store.name_validation_min', {
          min: settings.storeNameLength.minInclusive,
        });
      }

      return errors;
    },
    onSubmit: (values) => {
      const matchedBotUsername = values.telegramBotUsername.match(
        telegramBotUsernameRegExp,
      );

      if (matchedBotUsername) {
        onSubmit({
          name: values.name,
          telegramBotUsername: matchedBotUsername[1] || matchedBotUsername[2],
        });
      }
    },
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldTouched(e.target.name, true);
    formik.handleChange(e);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-4">
        <TextField
          name="name"
          error={formik.touched.name && !!formik.errors.name}
          autoFocus
          label={t('create_store.name_placeholder')}
          helperText={
            (formik.touched.name && formik.errors.name) ||
            t('create_store.name_hint', {
              min: settings?.storeNameLength.minInclusive,
              max: settings?.storeNameLength.maxInclusive,
            })
          }
          variant="outlined"
          fullWidth
          onChange={onChange}
        />
        <TextField
          name="telegramBotUsername"
          error={
            formik.touched.telegramBotUsername &&
            !!formik.errors.telegramBotUsername
          }
          label={t('create_store.bot_placeholder')}
          helperText={
            (formik.touched.telegramBotUsername &&
              formik.errors.telegramBotUsername) ||
            t('create_store.bot_hint')
          }
          variant="outlined"
          fullWidth
          onChange={onChange}
        />
      </div>
      {renderActions({ submit: formik.submitForm, valid: formik.isValid })}
    </form>
  );
};
