/* tslint:disable */
/* eslint-disable */
/**
 * Wallet-Pay
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: DEVELOPMENT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * null if exchange rate unavailable
 * @export
 * @interface AggregatedStoreBalanceDto
 */
export interface AggregatedStoreBalanceDto {
    /**
     * 
     * @type {string}
     * @memberof AggregatedStoreBalanceDto
     */
    'total': string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedStoreBalanceDto
     */
    'availableForPayoutGross': string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedStoreBalanceDto
     */
    'availableForPayoutNet': string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedStoreBalanceDto
     */
    'availableForPayoutFee': string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedStoreBalanceDto
     */
    'currency': string;
}
/**
 * 
 * @export
 * @interface AppSettingsDto
 */
export interface AppSettingsDto {
    /**
     * 
     * @type {LimitsInteger}
     * @memberof AppSettingsDto
     */
    'storeNameLength': LimitsInteger;
    /**
     * 
     * @type {QuestionnaireLimitsDto}
     * @memberof AppSettingsDto
     */
    'applicationQuestionnaireLimits': QuestionnaireLimitsDto;
    /**
     * 
     * @type {string}
     * @memberof AppSettingsDto
     */
    'orderHistoryUploadMaxPeriod'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSettingsDto
     */
    'payoutHistoryUploadMaxPeriod'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsDto
     */
    'supportedOrderCurrencies'?: Array<AppSettingsDtoSupportedOrderCurrenciesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsDto
     */
    'supportedPaymentCurrencies'?: Array<AppSettingsDtoSupportedPaymentCurrenciesEnum>;
}

export const AppSettingsDtoSupportedOrderCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type AppSettingsDtoSupportedOrderCurrenciesEnum = typeof AppSettingsDtoSupportedOrderCurrenciesEnum[keyof typeof AppSettingsDtoSupportedOrderCurrenciesEnum];
export const AppSettingsDtoSupportedPaymentCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type AppSettingsDtoSupportedPaymentCurrenciesEnum = typeof AppSettingsDtoSupportedPaymentCurrenciesEnum[keyof typeof AppSettingsDtoSupportedPaymentCurrenciesEnum];

/**
 * 
 * @export
 * @interface ApplicationIdRequest
 */
export interface ApplicationIdRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof ApplicationIdRequest
     */
    'applicationId': string;
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface AuthorizeByTelegramRestResponse
 */
export interface AuthorizeByTelegramRestResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeByTelegramRestResponse
     */
    'jwtToken': string;
}
/**
 * 
 * @export
 * @interface AutoPayoutSubscribeRequest
 */
export interface AutoPayoutSubscribeRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof AutoPayoutSubscribeRequest
     */
    'storeId': string;
    /**
     * 
     * @type {boolean}
     * @memberof AutoPayoutSubscribeRequest
     */
    'subscribeToAutoPayout': boolean;
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface AutoPayoutSubscribeRestResponse
 */
export interface AutoPayoutSubscribeRestResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AutoPayoutSubscribeRestResponse
     */
    'subscribedOnAutoPayout': boolean;
}
/**
 * 
 * @export
 * @interface CalculatePayoutRestRequest
 */
export interface CalculatePayoutRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof CalculatePayoutRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof CalculatePayoutRestRequest
     */
    'amount': MoneyAmountDto;
}
/**
 * 
 * @export
 * @interface CreateApiKeyRestRequest
 */
export interface CreateApiKeyRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof CreateApiKeyRestRequest
     */
    'storeId': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyApplication
 */
export interface CreateCompanyApplication {
    /**
     * 
     * @type {DraftQuestionnaireForCompany}
     * @memberof CreateCompanyApplication
     */
    'questionnaire': DraftQuestionnaireForCompany;
}
/**
 * 
 * @export
 * @interface CreateIndividualApplication
 */
export interface CreateIndividualApplication {
    /**
     * 
     * @type {DraftQuestionnaireForIndividual}
     * @memberof CreateIndividualApplication
     */
    'questionnaire': DraftQuestionnaireForIndividual;
}
/**
 * 
 * @export
 * @interface CreateStoreRestRequest
 */
export interface CreateStoreRestRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRestRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRestRequest
     */
    'telegramBotUsername'?: string;
}
/**
 * 
 * @export
 * @interface DecryptApiKeyRestRequest
 */
export interface DecryptApiKeyRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof DecryptApiKeyRestRequest
     */
    'storeId': string;
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof DecryptApiKeyRestRequest
     */
    'apiKeyId': string;
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface DecryptedStoreApiKeyDto
 */
export interface DecryptedStoreApiKeyDto {
    /**
     * 
     * @type {string}
     * @memberof DecryptedStoreApiKeyDto
     */
    'decryptedValue': string;
}
/**
 * 
 * @export
 * @interface DraftQuestionnaireForCompany
 */
export interface DraftQuestionnaireForCompany {
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'marketingChannels'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'telegramBots'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'businessActivity'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'businessActivityDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'expectedAveragePaymentAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'expectedRevenue'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'residentialAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForCompany
     */
    'licenseNumber'?: string;
}
/**
 * 
 * @export
 * @interface DraftQuestionnaireForIndividual
 */
export interface DraftQuestionnaireForIndividual {
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'marketingChannels'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'telegramBots'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'businessActivity'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'businessActivityDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'expectedAveragePaymentAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'expectedRevenue'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftQuestionnaireForIndividual
     */
    'residentialAddress'?: string;
}
/**
 * 
 * @export
 * @interface GetStoreOrdersAsFileRestRequest
 */
export interface GetStoreOrdersAsFileRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'searchString'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'createdDateTimeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'createdDateTimeTo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'statuses'?: Array<GetStoreOrdersAsFileRestRequestStatusesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'orderCurrencies'?: Array<GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'payerCurrencies'?: Array<GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum>;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersAsFileRestRequest
     */
    'orderIdSortOrder'?: GetStoreOrdersAsFileRestRequestOrderIdSortOrderEnum;
}

export const GetStoreOrdersAsFileRestRequestStatusesEnum = {
    Active: 'ACTIVE',
    Expired: 'EXPIRED',
    Paid: 'PAID',
    Cancelled: 'CANCELLED'
} as const;

export type GetStoreOrdersAsFileRestRequestStatusesEnum = typeof GetStoreOrdersAsFileRestRequestStatusesEnum[keyof typeof GetStoreOrdersAsFileRestRequestStatusesEnum];
export const GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum = typeof GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum[keyof typeof GetStoreOrdersAsFileRestRequestOrderCurrenciesEnum];
export const GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum = typeof GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum[keyof typeof GetStoreOrdersAsFileRestRequestPayerCurrenciesEnum];
export const GetStoreOrdersAsFileRestRequestOrderIdSortOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetStoreOrdersAsFileRestRequestOrderIdSortOrderEnum = typeof GetStoreOrdersAsFileRestRequestOrderIdSortOrderEnum[keyof typeof GetStoreOrdersAsFileRestRequestOrderIdSortOrderEnum];

/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface GetStoreOrdersResponse
 */
export interface GetStoreOrdersResponse {
    /**
     * 
     * @type {Array<WebsiteOrderItem>}
     * @memberof GetStoreOrdersResponse
     */
    'items': Array<WebsiteOrderItem>;
    /**
     * 
     * @type {number}
     * @memberof GetStoreOrdersResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface GetStoreOrdersRestRequest
 */
export interface GetStoreOrdersRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof GetStoreOrdersRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersRestRequest
     */
    'searchString'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersRestRequest
     */
    'createdDateTimeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersRestRequest
     */
    'createdDateTimeTo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStoreOrdersRestRequest
     */
    'statuses'?: Array<GetStoreOrdersRestRequestStatusesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStoreOrdersRestRequest
     */
    'orderCurrencies'?: Array<GetStoreOrdersRestRequestOrderCurrenciesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStoreOrdersRestRequest
     */
    'payerCurrencies'?: Array<GetStoreOrdersRestRequestPayerCurrenciesEnum>;
    /**
     * 
     * @type {string}
     * @memberof GetStoreOrdersRestRequest
     */
    'orderIdSortOrder'?: GetStoreOrdersRestRequestOrderIdSortOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof GetStoreOrdersRestRequest
     */
    'offset': number;
    /**
     * 
     * @type {number}
     * @memberof GetStoreOrdersRestRequest
     */
    'limit': number;
}

export const GetStoreOrdersRestRequestStatusesEnum = {
    Active: 'ACTIVE',
    Expired: 'EXPIRED',
    Paid: 'PAID',
    Cancelled: 'CANCELLED'
} as const;

export type GetStoreOrdersRestRequestStatusesEnum = typeof GetStoreOrdersRestRequestStatusesEnum[keyof typeof GetStoreOrdersRestRequestStatusesEnum];
export const GetStoreOrdersRestRequestOrderCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type GetStoreOrdersRestRequestOrderCurrenciesEnum = typeof GetStoreOrdersRestRequestOrderCurrenciesEnum[keyof typeof GetStoreOrdersRestRequestOrderCurrenciesEnum];
export const GetStoreOrdersRestRequestPayerCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type GetStoreOrdersRestRequestPayerCurrenciesEnum = typeof GetStoreOrdersRestRequestPayerCurrenciesEnum[keyof typeof GetStoreOrdersRestRequestPayerCurrenciesEnum];
export const GetStoreOrdersRestRequestOrderIdSortOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetStoreOrdersRestRequestOrderIdSortOrderEnum = typeof GetStoreOrdersRestRequestOrderIdSortOrderEnum[keyof typeof GetStoreOrdersRestRequestOrderIdSortOrderEnum];

/**
 * 
 * @export
 * @interface GetStorePayoutsAsFileRestRequest
 */
export interface GetStorePayoutsAsFileRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof GetStorePayoutsAsFileRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof GetStorePayoutsAsFileRestRequest
     */
    'createdDateTimeFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetStorePayoutsAsFileRestRequest
     */
    'createdDateTimeTo'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStorePayoutsAsFileRestRequest
     */
    'statuses'?: Array<GetStorePayoutsAsFileRestRequestStatusesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStorePayoutsAsFileRestRequest
     */
    'currencies'?: Array<GetStorePayoutsAsFileRestRequestCurrenciesEnum>;
}

export const GetStorePayoutsAsFileRestRequestStatusesEnum = {
    Created: 'CREATED',
    Failed: 'FAILED',
    Transferred: 'TRANSFERRED'
} as const;

export type GetStorePayoutsAsFileRestRequestStatusesEnum = typeof GetStorePayoutsAsFileRestRequestStatusesEnum[keyof typeof GetStorePayoutsAsFileRestRequestStatusesEnum];
export const GetStorePayoutsAsFileRestRequestCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type GetStorePayoutsAsFileRestRequestCurrenciesEnum = typeof GetStorePayoutsAsFileRestRequestCurrenciesEnum[keyof typeof GetStorePayoutsAsFileRestRequestCurrenciesEnum];

/**
 * 
 * @export
 * @interface GetStorePayoutsRestRequest
 */
export interface GetStorePayoutsRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof GetStorePayoutsRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof GetStorePayoutsRestRequest
     */
    'createdDateTimeFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetStorePayoutsRestRequest
     */
    'createdDateTimeTo'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStorePayoutsRestRequest
     */
    'statuses'?: Array<GetStorePayoutsRestRequestStatusesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetStorePayoutsRestRequest
     */
    'currencies'?: Array<GetStorePayoutsRestRequestCurrenciesEnum>;
    /**
     * 
     * @type {number}
     * @memberof GetStorePayoutsRestRequest
     */
    'offset'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetStorePayoutsRestRequest
     */
    'limit': number;
}

export const GetStorePayoutsRestRequestStatusesEnum = {
    Created: 'CREATED',
    Failed: 'FAILED',
    Transferred: 'TRANSFERRED'
} as const;

export type GetStorePayoutsRestRequestStatusesEnum = typeof GetStorePayoutsRestRequestStatusesEnum[keyof typeof GetStorePayoutsRestRequestStatusesEnum];
export const GetStorePayoutsRestRequestCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type GetStorePayoutsRestRequestCurrenciesEnum = typeof GetStorePayoutsRestRequestCurrenciesEnum[keyof typeof GetStorePayoutsRestRequestCurrenciesEnum];

/**
 * 
 * @export
 * @interface LimitsInteger
 */
export interface LimitsInteger {
    /**
     * 
     * @type {number}
     * @memberof LimitsInteger
     */
    'minInclusive': number;
    /**
     * 
     * @type {number}
     * @memberof LimitsInteger
     */
    'maxInclusive': number;
}
/**
 * 
 * @export
 * @interface MerchantApplicationDto
 */
export interface MerchantApplicationDto {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof MerchantApplicationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MerchantApplicationDto
     */
    'status': MerchantApplicationDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MerchantApplicationDto
     */
    'declineReason'?: MerchantApplicationDtoDeclineReasonEnum;
    /**
     * 
     * @type {SavedQuestionnaireDto}
     * @memberof MerchantApplicationDto
     */
    'questionnaire': SavedQuestionnaireDto;
}

export const MerchantApplicationDtoStatusEnum = {
    Draft: 'DRAFT',
    VerificationRequired: 'VERIFICATION_REQUIRED',
    OnVerification: 'ON_VERIFICATION',
    VerificationFailed: 'VERIFICATION_FAILED',
    Verified: 'VERIFIED',
    OnApproval: 'ON_APPROVAL',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    Revoked: 'REVOKED'
} as const;

export type MerchantApplicationDtoStatusEnum = typeof MerchantApplicationDtoStatusEnum[keyof typeof MerchantApplicationDtoStatusEnum];
export const MerchantApplicationDtoDeclineReasonEnum = {
    Other: 'OTHER',
    UseTelegramStars: 'USE_TELEGRAM_STARS'
} as const;

export type MerchantApplicationDtoDeclineReasonEnum = typeof MerchantApplicationDtoDeclineReasonEnum[keyof typeof MerchantApplicationDtoDeclineReasonEnum];

/**
 * 
 * @export
 * @interface MerchantConditionsDto
 */
export interface MerchantConditionsDto {
    /**
     * 
     * @type {number}
     * @memberof MerchantConditionsDto
     */
    'payoutFeePercent': number;
    /**
     * 
     * @type {number}
     * @memberof MerchantConditionsDto
     */
    'paymentFeePercent': number;
    /**
     * 
     * @type {number}
     * @memberof MerchantConditionsDto
     */
    'autoConversionFeePercent': number;
    /**
     * 
     * @type {string}
     * @memberof MerchantConditionsDto
     */
    'fundsHoldingDuration': string;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantConditionsDto
     */
    'isTestMode'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MerchantConditionsDto
     */
    'maxOrderAmounts'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof MerchantConditionsDto
     */
    'maxStoresNumber'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantConditionsDto
     */
    'extraPaymentCurrencies'?: Array<MerchantConditionsDtoExtraPaymentCurrenciesEnum>;
}

export const MerchantConditionsDtoExtraPaymentCurrenciesEnum = {
    Btc: 'BTC',
    Ton: 'TON',
    Not: 'NOT',
    Usdt: 'USDT',
    Usd: 'USD',
    Eur: 'EUR',
    Rub: 'RUB'
} as const;

export type MerchantConditionsDtoExtraPaymentCurrenciesEnum = typeof MerchantConditionsDtoExtraPaymentCurrenciesEnum[keyof typeof MerchantConditionsDtoExtraPaymentCurrenciesEnum];

/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface MerchantDto
 */
export interface MerchantDto {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof MerchantDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MerchantDto
     */
    'status': MerchantDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MerchantDto
     */
    'createdDateTime': string;
    /**
     * 
     * @type {MerchantConditionsDto}
     * @memberof MerchantDto
     */
    'conditions': MerchantConditionsDto;
    /**
     * 
     * @type {Array<StorePreviewDto>}
     * @memberof MerchantDto
     */
    'stores': Array<StorePreviewDto>;
    /**
     * 
     * @type {Array<MerchantApplicationDto>}
     * @memberof MerchantDto
     */
    'applications': Array<MerchantApplicationDto>;
}

export const MerchantDtoStatusEnum = {
    NotApproved: 'NOT_APPROVED',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    VerificationFailed: 'VERIFICATION_FAILED'
} as const;

export type MerchantDtoStatusEnum = typeof MerchantDtoStatusEnum[keyof typeof MerchantDtoStatusEnum];

/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface MerchantPreviewDto
 */
export interface MerchantPreviewDto {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof MerchantPreviewDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof MerchantPreviewDto
     */
    'status': MerchantPreviewDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MerchantPreviewDto
     */
    'createdDateTime': string;
    /**
     * 
     * @type {Array<StorePreviewDto>}
     * @memberof MerchantPreviewDto
     */
    'stores'?: Array<StorePreviewDto>;
}

export const MerchantPreviewDtoStatusEnum = {
    NotApproved: 'NOT_APPROVED',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    VerificationFailed: 'VERIFICATION_FAILED'
} as const;

export type MerchantPreviewDtoStatusEnum = typeof MerchantPreviewDtoStatusEnum[keyof typeof MerchantPreviewDtoStatusEnum];

/**
 * 
 * @export
 * @interface MoneyAmountDto
 */
export interface MoneyAmountDto {
    /**
     * 
     * @type {string}
     * @memberof MoneyAmountDto
     */
    'currencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof MoneyAmountDto
     */
    'amount': string;
}
/**
 * 
 * @export
 * @interface PaymentOptionDto
 */
export interface PaymentOptionDto {
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PaymentOptionDto
     */
    'amount': MoneyAmountDto;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PaymentOptionDto
     */
    'amountFee': MoneyAmountDto;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PaymentOptionDto
     */
    'amountNet': MoneyAmountDto;
    /**
     * 
     * @type {string}
     * @memberof PaymentOptionDto
     */
    'exchangeRate': string;
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface PayoutAmountDto
 */
export interface PayoutAmountDto {
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PayoutAmountDto
     */
    'amountNet': MoneyAmountDto;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PayoutAmountDto
     */
    'amountFee': MoneyAmountDto;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PayoutAmountDto
     */
    'amountGross': MoneyAmountDto;
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface PayoutDto
 */
export interface PayoutDto {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof PayoutDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDto
     */
    'status': PayoutDtoStatusEnum;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PayoutDto
     */
    'amountNet': MoneyAmountDto;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PayoutDto
     */
    'transferFee': MoneyAmountDto;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof PayoutDto
     */
    'amountGross': MoneyAmountDto;
    /**
     * 
     * @type {string}
     * @memberof PayoutDto
     */
    'createdDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDto
     */
    'completedDateTime'?: string;
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof PayoutDto
     */
    'storeId': string;
}

export const PayoutDtoStatusEnum = {
    Created: 'CREATED',
    Failed: 'FAILED',
    Transferred: 'TRANSFERRED'
} as const;

export type PayoutDtoStatusEnum = typeof PayoutDtoStatusEnum[keyof typeof PayoutDtoStatusEnum];

/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface PayoutListRestResponse
 */
export interface PayoutListRestResponse {
    /**
     * 
     * @type {Array<PayoutDto>}
     * @memberof PayoutListRestResponse
     */
    'items': Array<PayoutDto>;
    /**
     * 
     * @type {number}
     * @memberof PayoutListRestResponse
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ProceedPayoutForAllAccountsRestRequest
 */
export interface ProceedPayoutForAllAccountsRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof ProceedPayoutForAllAccountsRestRequest
     */
    'storeId': string;
}
/**
 * 
 * @export
 * @interface ProceedPayoutRestRequest
 */
export interface ProceedPayoutRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof ProceedPayoutRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof ProceedPayoutRestRequest
     */
    'amountGross': MoneyAmountDto;
}
/**
 * 
 * @export
 * @interface QuestionnaireLimitsDto
 */
export interface QuestionnaireLimitsDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireLimitsDto
     */
    'allStringFieldsMaxLength': number;
}
/**
 * 
 * @export
 * @interface RenameStoreRestRequest
 */
export interface RenameStoreRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof RenameStoreRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {string}
     * @memberof RenameStoreRestRequest
     */
    'newName': string;
}
/**
 * 
 * @export
 * @interface RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponse
 */
export interface RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponse {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponse
     */
    'status': RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponseStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponse
     */
    'message'?: string;
    /**
     * 
     * @type {AuthorizeByTelegramRestResponse}
     * @memberof RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponse
     */
    'data'?: AuthorizeByTelegramRestResponse;
}

export const RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponseStatusEnum = {
    Success: 'SUCCESS',
    FailedVerification: 'FAILED_VERIFICATION',
    NotWalletUser: 'NOT_WALLET_USER'
} as const;

export type RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponseStatusEnum = typeof RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponseStatusEnum[keyof typeof RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponseStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseCalculatePayoutStatusPayoutAmountDto
 */
export interface RestDataResponseCalculatePayoutStatusPayoutAmountDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseCalculatePayoutStatusPayoutAmountDto
     */
    'status': RestDataResponseCalculatePayoutStatusPayoutAmountDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseCalculatePayoutStatusPayoutAmountDto
     */
    'message'?: string;
    /**
     * 
     * @type {PayoutAmountDto}
     * @memberof RestDataResponseCalculatePayoutStatusPayoutAmountDto
     */
    'data'?: PayoutAmountDto;
}

export const RestDataResponseCalculatePayoutStatusPayoutAmountDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseCalculatePayoutStatusPayoutAmountDtoStatusEnum = typeof RestDataResponseCalculatePayoutStatusPayoutAmountDtoStatusEnum[keyof typeof RestDataResponseCalculatePayoutStatusPayoutAmountDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDto
 */
export interface RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDto
     */
    'status': RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDto
     */
    'message'?: string;
    /**
     * 
     * @type {DecryptedStoreApiKeyDto}
     * @memberof RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDto
     */
    'data'?: DecryptedStoreApiKeyDto;
}

export const RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum = typeof RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum[keyof typeof RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseCreateApplicationStatusMerchantApplicationDto
 */
export interface RestDataResponseCreateApplicationStatusMerchantApplicationDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseCreateApplicationStatusMerchantApplicationDto
     */
    'status': RestDataResponseCreateApplicationStatusMerchantApplicationDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseCreateApplicationStatusMerchantApplicationDto
     */
    'message'?: string;
    /**
     * 
     * @type {MerchantApplicationDto}
     * @memberof RestDataResponseCreateApplicationStatusMerchantApplicationDto
     */
    'data'?: MerchantApplicationDto;
}

export const RestDataResponseCreateApplicationStatusMerchantApplicationDtoStatusEnum = {
    Success: 'SUCCESS',
    PreviousApplicationNotFinished: 'PREVIOUS_APPLICATION_NOT_FINISHED',
    AccessDenied: 'ACCESS_DENIED',
    InvalidQuestionnaire: 'INVALID_QUESTIONNAIRE'
} as const;

export type RestDataResponseCreateApplicationStatusMerchantApplicationDtoStatusEnum = typeof RestDataResponseCreateApplicationStatusMerchantApplicationDtoStatusEnum[keyof typeof RestDataResponseCreateApplicationStatusMerchantApplicationDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseCreateMerchantStatusMerchantPreviewDto
 */
export interface RestDataResponseCreateMerchantStatusMerchantPreviewDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseCreateMerchantStatusMerchantPreviewDto
     */
    'status': RestDataResponseCreateMerchantStatusMerchantPreviewDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseCreateMerchantStatusMerchantPreviewDto
     */
    'message'?: string;
    /**
     * 
     * @type {MerchantPreviewDto}
     * @memberof RestDataResponseCreateMerchantStatusMerchantPreviewDto
     */
    'data'?: MerchantPreviewDto;
}

export const RestDataResponseCreateMerchantStatusMerchantPreviewDtoStatusEnum = {
    Success: 'SUCCESS',
    Already: 'ALREADY',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseCreateMerchantStatusMerchantPreviewDtoStatusEnum = typeof RestDataResponseCreateMerchantStatusMerchantPreviewDtoStatusEnum[keyof typeof RestDataResponseCreateMerchantStatusMerchantPreviewDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseCreateStoreStatusStorePreviewDto
 */
export interface RestDataResponseCreateStoreStatusStorePreviewDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseCreateStoreStatusStorePreviewDto
     */
    'status': RestDataResponseCreateStoreStatusStorePreviewDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseCreateStoreStatusStorePreviewDto
     */
    'message'?: string;
    /**
     * 
     * @type {StorePreviewDto}
     * @memberof RestDataResponseCreateStoreStatusStorePreviewDto
     */
    'data'?: StorePreviewDto;
}

export const RestDataResponseCreateStoreStatusStorePreviewDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    Failure: 'FAILURE',
    InvalidTgBotUsername: 'INVALID_TG_BOT_USERNAME'
} as const;

export type RestDataResponseCreateStoreStatusStorePreviewDtoStatusEnum = typeof RestDataResponseCreateStoreStatusStorePreviewDtoStatusEnum[keyof typeof RestDataResponseCreateStoreStatusStorePreviewDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDto
 */
export interface RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDto
     */
    'status': RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDto
     */
    'message'?: string;
    /**
     * 
     * @type {DecryptedStoreApiKeyDto}
     * @memberof RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDto
     */
    'data'?: DecryptedStoreApiKeyDto;
}

export const RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum = typeof RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum[keyof typeof RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseGetMerchantStatusMerchantDto
 */
export interface RestDataResponseGetMerchantStatusMerchantDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseGetMerchantStatusMerchantDto
     */
    'status': RestDataResponseGetMerchantStatusMerchantDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseGetMerchantStatusMerchantDto
     */
    'message'?: string;
    /**
     * 
     * @type {MerchantDto}
     * @memberof RestDataResponseGetMerchantStatusMerchantDto
     */
    'data'?: MerchantDto;
}

export const RestDataResponseGetMerchantStatusMerchantDtoStatusEnum = {
    Success: 'SUCCESS',
    NotFound: 'NOT_FOUND',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseGetMerchantStatusMerchantDtoStatusEnum = typeof RestDataResponseGetMerchantStatusMerchantDtoStatusEnum[keyof typeof RestDataResponseGetMerchantStatusMerchantDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseGetPayoutListStatusPayoutListRestResponse
 */
export interface RestDataResponseGetPayoutListStatusPayoutListRestResponse {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseGetPayoutListStatusPayoutListRestResponse
     */
    'status': RestDataResponseGetPayoutListStatusPayoutListRestResponseStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseGetPayoutListStatusPayoutListRestResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PayoutListRestResponse}
     * @memberof RestDataResponseGetPayoutListStatusPayoutListRestResponse
     */
    'data'?: PayoutListRestResponse;
}

export const RestDataResponseGetPayoutListStatusPayoutListRestResponseStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    TooBigRequest: 'TOO_BIG_REQUEST'
} as const;

export type RestDataResponseGetPayoutListStatusPayoutListRestResponseStatusEnum = typeof RestDataResponseGetPayoutListStatusPayoutListRestResponseStatusEnum[keyof typeof RestDataResponseGetPayoutListStatusPayoutListRestResponseStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseGetPayoutStatusPayoutDto
 */
export interface RestDataResponseGetPayoutStatusPayoutDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseGetPayoutStatusPayoutDto
     */
    'status': RestDataResponseGetPayoutStatusPayoutDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseGetPayoutStatusPayoutDto
     */
    'message'?: string;
    /**
     * 
     * @type {PayoutDto}
     * @memberof RestDataResponseGetPayoutStatusPayoutDto
     */
    'data'?: PayoutDto;
}

export const RestDataResponseGetPayoutStatusPayoutDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseGetPayoutStatusPayoutDtoStatusEnum = typeof RestDataResponseGetPayoutStatusPayoutDtoStatusEnum[keyof typeof RestDataResponseGetPayoutStatusPayoutDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse
 */
export interface RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse
     */
    'status': RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponseStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetStoreOrdersResponse}
     * @memberof RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse
     */
    'data'?: GetStoreOrdersResponse;
}

export const RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponseStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    TooBigRequest: 'TOO_BIG_REQUEST'
} as const;

export type RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponseStatusEnum = typeof RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponseStatusEnum[keyof typeof RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponseStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseGetStoreStatusStoreDto
 */
export interface RestDataResponseGetStoreStatusStoreDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseGetStoreStatusStoreDto
     */
    'status': RestDataResponseGetStoreStatusStoreDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseGetStoreStatusStoreDto
     */
    'message'?: string;
    /**
     * 
     * @type {StoreDto}
     * @memberof RestDataResponseGetStoreStatusStoreDto
     */
    'data'?: StoreDto;
}

export const RestDataResponseGetStoreStatusStoreDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseGetStoreStatusStoreDtoStatusEnum = typeof RestDataResponseGetStoreStatusStoreDtoStatusEnum[keyof typeof RestDataResponseGetStoreStatusStoreDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseProceedPayoutStatusListPayoutDto
 */
export interface RestDataResponseProceedPayoutStatusListPayoutDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseProceedPayoutStatusListPayoutDto
     */
    'status': RestDataResponseProceedPayoutStatusListPayoutDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseProceedPayoutStatusListPayoutDto
     */
    'message'?: string;
    /**
     * Response payload, present if status is SUCCESS
     * @type {Array<PayoutDto>}
     * @memberof RestDataResponseProceedPayoutStatusListPayoutDto
     */
    'data'?: Array<PayoutDto>;
}

export const RestDataResponseProceedPayoutStatusListPayoutDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    Failed: 'FAILED',
    VerificationRequired: 'VERIFICATION_REQUIRED',
    VerificationFailed: 'VERIFICATION_FAILED'
} as const;

export type RestDataResponseProceedPayoutStatusListPayoutDtoStatusEnum = typeof RestDataResponseProceedPayoutStatusListPayoutDtoStatusEnum[keyof typeof RestDataResponseProceedPayoutStatusListPayoutDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseProceedPayoutStatusPayoutDto
 */
export interface RestDataResponseProceedPayoutStatusPayoutDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseProceedPayoutStatusPayoutDto
     */
    'status': RestDataResponseProceedPayoutStatusPayoutDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseProceedPayoutStatusPayoutDto
     */
    'message'?: string;
    /**
     * 
     * @type {PayoutDto}
     * @memberof RestDataResponseProceedPayoutStatusPayoutDto
     */
    'data'?: PayoutDto;
}

export const RestDataResponseProceedPayoutStatusPayoutDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    Failed: 'FAILED',
    VerificationRequired: 'VERIFICATION_REQUIRED',
    VerificationFailed: 'VERIFICATION_FAILED'
} as const;

export type RestDataResponseProceedPayoutStatusPayoutDtoStatusEnum = typeof RestDataResponseProceedPayoutStatusPayoutDtoStatusEnum[keyof typeof RestDataResponseProceedPayoutStatusPayoutDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseRevokeApplicationStatusMerchantApplicationDto
 */
export interface RestDataResponseRevokeApplicationStatusMerchantApplicationDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseRevokeApplicationStatusMerchantApplicationDto
     */
    'status': RestDataResponseRevokeApplicationStatusMerchantApplicationDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseRevokeApplicationStatusMerchantApplicationDto
     */
    'message'?: string;
    /**
     * 
     * @type {MerchantApplicationDto}
     * @memberof RestDataResponseRevokeApplicationStatusMerchantApplicationDto
     */
    'data'?: MerchantApplicationDto;
}

export const RestDataResponseRevokeApplicationStatusMerchantApplicationDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseRevokeApplicationStatusMerchantApplicationDtoStatusEnum = typeof RestDataResponseRevokeApplicationStatusMerchantApplicationDtoStatusEnum[keyof typeof RestDataResponseRevokeApplicationStatusMerchantApplicationDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseSubmitApplicationStatusSubmitApplicationResponse
 */
export interface RestDataResponseSubmitApplicationStatusSubmitApplicationResponse {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseSubmitApplicationStatusSubmitApplicationResponse
     */
    'status': RestDataResponseSubmitApplicationStatusSubmitApplicationResponseStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseSubmitApplicationStatusSubmitApplicationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {SubmitApplicationResponse}
     * @memberof RestDataResponseSubmitApplicationStatusSubmitApplicationResponse
     */
    'data'?: SubmitApplicationResponse;
}

export const RestDataResponseSubmitApplicationStatusSubmitApplicationResponseStatusEnum = {
    Success: 'SUCCESS',
    VerificationRequired: 'VERIFICATION_REQUIRED',
    VerificationFailed: 'VERIFICATION_FAILED',
    AccessDenied: 'ACCESS_DENIED',
    QuestionnaireNotFullFilled: 'QUESTIONNAIRE_NOT_FULL_FILLED'
} as const;

export type RestDataResponseSubmitApplicationStatusSubmitApplicationResponseStatusEnum = typeof RestDataResponseSubmitApplicationStatusSubmitApplicationResponseStatusEnum[keyof typeof RestDataResponseSubmitApplicationStatusSubmitApplicationResponseStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponse
 */
export interface RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponse {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponse
     */
    'status': RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponseStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponse
     */
    'message'?: string;
    /**
     * 
     * @type {AutoPayoutSubscribeRestResponse}
     * @memberof RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponse
     */
    'data'?: AutoPayoutSubscribeRestResponse;
}

export const RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponseStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    Failed: 'FAILED'
} as const;

export type RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponseStatusEnum = typeof RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponseStatusEnum[keyof typeof RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponseStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseUpdateApplicationStatusMerchantApplicationDto
 */
export interface RestDataResponseUpdateApplicationStatusMerchantApplicationDto {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseUpdateApplicationStatusMerchantApplicationDto
     */
    'status': RestDataResponseUpdateApplicationStatusMerchantApplicationDtoStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseUpdateApplicationStatusMerchantApplicationDto
     */
    'message'?: string;
    /**
     * 
     * @type {MerchantApplicationDto}
     * @memberof RestDataResponseUpdateApplicationStatusMerchantApplicationDto
     */
    'data'?: MerchantApplicationDto;
}

export const RestDataResponseUpdateApplicationStatusMerchantApplicationDtoStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    InvalidQuestionnaire: 'INVALID_QUESTIONNAIRE'
} as const;

export type RestDataResponseUpdateApplicationStatusMerchantApplicationDtoStatusEnum = typeof RestDataResponseUpdateApplicationStatusMerchantApplicationDtoStatusEnum[keyof typeof RestDataResponseUpdateApplicationStatusMerchantApplicationDtoStatusEnum];

/**
 * 
 * @export
 * @interface RestDataResponseVerificationTokenStatusVerificationTokenResponse
 */
export interface RestDataResponseVerificationTokenStatusVerificationTokenResponse {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestDataResponseVerificationTokenStatusVerificationTokenResponse
     */
    'status': RestDataResponseVerificationTokenStatusVerificationTokenResponseStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestDataResponseVerificationTokenStatusVerificationTokenResponse
     */
    'message'?: string;
    /**
     * 
     * @type {VerificationTokenResponse}
     * @memberof RestDataResponseVerificationTokenStatusVerificationTokenResponse
     */
    'data'?: VerificationTokenResponse;
}

export const RestDataResponseVerificationTokenStatusVerificationTokenResponseStatusEnum = {
    Success: 'SUCCESS',
    NotNeeded: 'NOT_NEEDED',
    VerificationFailed: 'VERIFICATION_FAILED',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestDataResponseVerificationTokenStatusVerificationTokenResponseStatusEnum = typeof RestDataResponseVerificationTokenStatusVerificationTokenResponseStatusEnum[keyof typeof RestDataResponseVerificationTokenStatusVerificationTokenResponseStatusEnum];

/**
 * 
 * @export
 * @interface RestResponseRenameStoreStatus
 */
export interface RestResponseRenameStoreStatus {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestResponseRenameStoreStatus
     */
    'status': RestResponseRenameStoreStatusStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestResponseRenameStoreStatus
     */
    'message'?: string;
}

export const RestResponseRenameStoreStatusStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED',
    Failure: 'FAILURE'
} as const;

export type RestResponseRenameStoreStatusStatusEnum = typeof RestResponseRenameStoreStatusStatusEnum[keyof typeof RestResponseRenameStoreStatusStatusEnum];

/**
 * 
 * @export
 * @interface RestResponseUpdateWebhookConfigStatus
 */
export interface RestResponseUpdateWebhookConfigStatus {
    /**
     * Operation result status, always present
     * @type {string}
     * @memberof RestResponseUpdateWebhookConfigStatus
     */
    'status': RestResponseUpdateWebhookConfigStatusStatusEnum;
    /**
     * Verbose reason of non-success result
     * @type {string}
     * @memberof RestResponseUpdateWebhookConfigStatus
     */
    'message'?: string;
}

export const RestResponseUpdateWebhookConfigStatusStatusEnum = {
    Success: 'SUCCESS',
    AccessDenied: 'ACCESS_DENIED'
} as const;

export type RestResponseUpdateWebhookConfigStatusStatusEnum = typeof RestResponseUpdateWebhookConfigStatusStatusEnum[keyof typeof RestResponseUpdateWebhookConfigStatusStatusEnum];

/**
 * 
 * @export
 * @interface SavedQuestionnaireDto
 */
export interface SavedQuestionnaireDto {
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'legalForm': SavedQuestionnaireDtoLegalFormEnum;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'marketingChannels'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'telegramBots'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'businessActivity'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'businessActivityDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'expectedAveragePaymentAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'expectedRevenue'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'residentialAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedQuestionnaireDto
     */
    'licenseNumber'?: string;
}

export const SavedQuestionnaireDtoLegalFormEnum = {
    Individual: 'INDIVIDUAL',
    Company: 'COMPANY'
} as const;

export type SavedQuestionnaireDtoLegalFormEnum = typeof SavedQuestionnaireDtoLegalFormEnum[keyof typeof SavedQuestionnaireDtoLegalFormEnum];

/**
 * 
 * @export
 * @interface StoreApiKeyDto
 */
export interface StoreApiKeyDto {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof StoreApiKeyDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreApiKeyDto
     */
    'createdDatetime': string;
    /**
     * 
     * @type {string}
     * @memberof StoreApiKeyDto
     */
    'revokedDatetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreApiKeyDto
     */
    'decryptedPrefix': string;
}
/**
 * 
 * @export
 * @interface StoreBalanceDto
 */
export interface StoreBalanceDto {
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'total': string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'totalInFiat'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'availableForPayoutGross': string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'availableForPayoutGrossInFiat'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'availableForPayoutNet': string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'availableForPayoutNetInFiat'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'availableForPayoutFee': string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'availableForPayoutFeeInFiat'?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof StoreBalanceDto
     */
    'fiatCurrency'?: string;
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface StoreDto
 */
export interface StoreDto {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof StoreDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'status': StoreDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'telegramBotUsername'?: string;
    /**
     * 
     * @type {Array<StoreApiKeyDto>}
     * @memberof StoreDto
     */
    'apiKeys': Array<StoreApiKeyDto>;
    /**
     * 
     * @type {WebhookConfigDto}
     * @memberof StoreDto
     */
    'webhookConfig': WebhookConfigDto;
    /**
     * 
     * @type {boolean}
     * @memberof StoreDto
     */
    'subscribedOnAutoPayout': boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreDto
     */
    'payoutFeePercent': number;
    /**
     * 
     * @type {number}
     * @memberof StoreDto
     */
    'paymentFeePercent': number;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'fundsHoldingDuration': string;
    /**
     * 
     * @type {Array<StoreBalanceDto>}
     * @memberof StoreDto
     */
    'balances': Array<StoreBalanceDto>;
    /**
     * 
     * @type {AggregatedStoreBalanceDto}
     * @memberof StoreDto
     */
    'aggregatedBalance'?: AggregatedStoreBalanceDto | null;
    /**
     * 
     * @type {StoreStatisticDto}
     * @memberof StoreDto
     */
    'statistic': StoreStatisticDto;
}

export const StoreDtoStatusEnum = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type StoreDtoStatusEnum = typeof StoreDtoStatusEnum[keyof typeof StoreDtoStatusEnum];

/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface StorePreviewDto
 */
export interface StorePreviewDto {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof StorePreviewDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StorePreviewDto
     */
    'status': StorePreviewDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StorePreviewDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StorePreviewDto
     */
    'telegramBotUsername'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StorePreviewDto
     */
    'subscribedOnAutoPayout': boolean;
    /**
     * 
     * @type {number}
     * @memberof StorePreviewDto
     */
    'feeForPayout'?: number;
}

export const StorePreviewDtoStatusEnum = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type StorePreviewDtoStatusEnum = typeof StorePreviewDtoStatusEnum[keyof typeof StorePreviewDtoStatusEnum];

/**
 * 
 * @export
 * @interface StoreStatisticDto
 */
export interface StoreStatisticDto {
    /**
     * 
     * @type {number}
     * @memberof StoreStatisticDto
     */
    'paidOrdersCount': number;
    /**
     * 
     * @type {string}
     * @memberof StoreStatisticDto
     */
    'paidOrdersAmountEur': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StoreStatisticDto
     */
    'paidOrdersAmounts': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StoreStatisticDto
     */
    'paidPaymentsAmounts': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StoreStatisticDto
     */
    'paidPaymentFeesAmounts': { [key: string]: string; };
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface SubmitApplicationResponse
 */
export interface SubmitApplicationResponse {
    /**
     * 
     * @type {MerchantApplicationDto}
     * @memberof SubmitApplicationResponse
     */
    'application'?: MerchantApplicationDto;
    /**
     * 
     * @type {string}
     * @memberof SubmitApplicationResponse
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyApplication
 */
export interface UpdateCompanyApplication {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof UpdateCompanyApplication
     */
    'applicationId': string;
    /**
     * 
     * @type {DraftQuestionnaireForCompany}
     * @memberof UpdateCompanyApplication
     */
    'questionnaire': DraftQuestionnaireForCompany;
}
/**
 * 
 * @export
 * @interface UpdateIndividualApplication
 */
export interface UpdateIndividualApplication {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof UpdateIndividualApplication
     */
    'applicationId': string;
    /**
     * 
     * @type {DraftQuestionnaireForIndividual}
     * @memberof UpdateIndividualApplication
     */
    'questionnaire': DraftQuestionnaireForIndividual;
}
/**
 * 
 * @export
 * @interface UpdateWebhookConfigRestRequest
 */
export interface UpdateWebhookConfigRestRequest {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof UpdateWebhookConfigRestRequest
     */
    'storeId': string;
    /**
     * 
     * @type {WebhookConfigDto}
     * @memberof UpdateWebhookConfigRestRequest
     */
    'newWebhookConfig': WebhookConfigDto;
}
/**
 * Response payload, present if status is SUCCESS
 * @export
 * @interface VerificationTokenResponse
 */
export interface VerificationTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof VerificationTokenResponse
     */
    'verificationToken': string;
}
/**
 * 
 * @export
 * @interface WebhookConfigDto
 */
export interface WebhookConfigDto {
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigDto
     */
    'url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookConfigDto
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface WebsiteOrderItem
 */
export interface WebsiteOrderItem {
    /**
     * The string representation of Java long value.
     * @type {string}
     * @memberof WebsiteOrderItem
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteOrderItem
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteOrderItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteOrderItem
     */
    'createdDateTime': string;
    /**
     * 
     * @type {MoneyAmountDto}
     * @memberof WebsiteOrderItem
     */
    'orderAmount': MoneyAmountDto;
    /**
     * 
     * @type {string}
     * @memberof WebsiteOrderItem
     */
    'orderStatus': WebsiteOrderItemOrderStatusEnum;
    /**
     * 
     * @type {PaymentOptionDto}
     * @memberof WebsiteOrderItem
     */
    'selectedPaymentOption'?: PaymentOptionDto;
}

export const WebsiteOrderItemOrderStatusEnum = {
    Active: 'ACTIVE',
    Expired: 'EXPIRED',
    Paid: 'PAID',
    Cancelled: 'CANCELLED'
} as const;

export type WebsiteOrderItemOrderStatusEnum = typeof WebsiteOrderItemOrderStatusEnum[keyof typeof WebsiteOrderItemOrderStatusEnum];


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authorize Wallet user by telegram authorization data 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeByTelegram: async (requestBody: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('authorizeByTelegram', 'requestBody', requestBody)
            const localVarPath = `/wpay/website-api/v1/auth/authorize-by-telegram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIpCountry: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wpay/website-api/v1/auth/get-ip-country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Authorize Wallet user by telegram authorization data 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeByTelegram(requestBody: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeByTelegram(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIpCountry(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIpCountry(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Authorize Wallet user by telegram authorization data 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeByTelegram(requestBody: { [key: string]: string; }, options?: any): AxiosPromise<RestDataResponseAuthorizeByTelegramStatusAuthorizeByTelegramRestResponse> {
            return localVarFp.authorizeByTelegram(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIpCountry(options?: any): AxiosPromise<string> {
            return localVarFp.getIpCountry(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Authorize Wallet user by telegram authorization data 
     * @param {{ [key: string]: string; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeByTelegram(requestBody: { [key: string]: string; }, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authorizeByTelegram(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getIpCountry(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getIpCountry(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MerchantApi - axios parameter creator
 * @export
 */
export const MerchantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCompanyApplication} createCompanyApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyApplication: async (createCompanyApplication: CreateCompanyApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyApplication' is not null or undefined
            assertParamExists('createCompanyApplication', 'createCompanyApplication', createCompanyApplication)
            const localVarPath = `/wpay/website-api/v1/merchant/application/company/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateIndividualApplication} createIndividualApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividualApplication: async (createIndividualApplication: CreateIndividualApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createIndividualApplication' is not null or undefined
            assertParamExists('createIndividualApplication', 'createIndividualApplication', createIndividualApplication)
            const localVarPath = `/wpay/website-api/v1/merchant/application/individual/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIndividualApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerchantForUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wpay/website-api/v1/merchant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMerchantByUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wpay/website-api/v1/merchant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationTokenForApplication: async (applicationIdRequest: ApplicationIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationIdRequest' is not null or undefined
            assertParamExists('getVerificationTokenForApplication', 'applicationIdRequest', applicationIdRequest)
            const localVarPath = `/wpay/website-api/v1/merchant/application/get-verification-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationTokenForMerchant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wpay/website-api/v1/merchant/get-verification-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeApplication: async (applicationIdRequest: ApplicationIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationIdRequest' is not null or undefined
            assertParamExists('revokeApplication', 'applicationIdRequest', applicationIdRequest)
            const localVarPath = `/wpay/website-api/v1/merchant/application/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplication: async (applicationIdRequest: ApplicationIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationIdRequest' is not null or undefined
            assertParamExists('submitApplication', 'applicationIdRequest', applicationIdRequest)
            const localVarPath = `/wpay/website-api/v1/merchant/application/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyApplication} updateCompanyApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyApplication: async (updateCompanyApplication: UpdateCompanyApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyApplication' is not null or undefined
            assertParamExists('updateCompanyApplication', 'updateCompanyApplication', updateCompanyApplication)
            const localVarPath = `/wpay/website-api/v1/merchant/application/company/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateIndividualApplication} updateIndividualApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualApplication: async (updateIndividualApplication: UpdateIndividualApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateIndividualApplication' is not null or undefined
            assertParamExists('updateIndividualApplication', 'updateIndividualApplication', updateIndividualApplication)
            const localVarPath = `/wpay/website-api/v1/merchant/application/individual/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIndividualApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantApi - functional programming interface
 * @export
 */
export const MerchantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MerchantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCompanyApplication} createCompanyApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyApplication(createCompanyApplication: CreateCompanyApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseCreateApplicationStatusMerchantApplicationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyApplication(createCompanyApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateIndividualApplication} createIndividualApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIndividualApplication(createIndividualApplication: CreateIndividualApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseCreateApplicationStatusMerchantApplicationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIndividualApplication(createIndividualApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMerchantForUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseCreateMerchantStatusMerchantPreviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMerchantForUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMerchantByUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseGetMerchantStatusMerchantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMerchantByUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerificationTokenForApplication(applicationIdRequest: ApplicationIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseVerificationTokenStatusVerificationTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerificationTokenForApplication(applicationIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerificationTokenForMerchant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseVerificationTokenStatusVerificationTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerificationTokenForMerchant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeApplication(applicationIdRequest: ApplicationIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseRevokeApplicationStatusMerchantApplicationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeApplication(applicationIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitApplication(applicationIdRequest: ApplicationIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseSubmitApplicationStatusSubmitApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitApplication(applicationIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCompanyApplication} updateCompanyApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyApplication(updateCompanyApplication: UpdateCompanyApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseUpdateApplicationStatusMerchantApplicationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyApplication(updateCompanyApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateIndividualApplication} updateIndividualApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIndividualApplication(updateIndividualApplication: UpdateIndividualApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseUpdateApplicationStatusMerchantApplicationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIndividualApplication(updateIndividualApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MerchantApi - factory interface
 * @export
 */
export const MerchantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MerchantApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCompanyApplication} createCompanyApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyApplication(createCompanyApplication: CreateCompanyApplication, options?: any): AxiosPromise<RestDataResponseCreateApplicationStatusMerchantApplicationDto> {
            return localVarFp.createCompanyApplication(createCompanyApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateIndividualApplication} createIndividualApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndividualApplication(createIndividualApplication: CreateIndividualApplication, options?: any): AxiosPromise<RestDataResponseCreateApplicationStatusMerchantApplicationDto> {
            return localVarFp.createIndividualApplication(createIndividualApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerchantForUser(options?: any): AxiosPromise<RestDataResponseCreateMerchantStatusMerchantPreviewDto> {
            return localVarFp.createMerchantForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMerchantByUser(options?: any): AxiosPromise<RestDataResponseGetMerchantStatusMerchantDto> {
            return localVarFp.findMerchantByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationTokenForApplication(applicationIdRequest: ApplicationIdRequest, options?: any): AxiosPromise<RestDataResponseVerificationTokenStatusVerificationTokenResponse> {
            return localVarFp.getVerificationTokenForApplication(applicationIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationTokenForMerchant(options?: any): AxiosPromise<RestDataResponseVerificationTokenStatusVerificationTokenResponse> {
            return localVarFp.getVerificationTokenForMerchant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeApplication(applicationIdRequest: ApplicationIdRequest, options?: any): AxiosPromise<RestDataResponseRevokeApplicationStatusMerchantApplicationDto> {
            return localVarFp.revokeApplication(applicationIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationIdRequest} applicationIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplication(applicationIdRequest: ApplicationIdRequest, options?: any): AxiosPromise<RestDataResponseSubmitApplicationStatusSubmitApplicationResponse> {
            return localVarFp.submitApplication(applicationIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyApplication} updateCompanyApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyApplication(updateCompanyApplication: UpdateCompanyApplication, options?: any): AxiosPromise<RestDataResponseUpdateApplicationStatusMerchantApplicationDto> {
            return localVarFp.updateCompanyApplication(updateCompanyApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateIndividualApplication} updateIndividualApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIndividualApplication(updateIndividualApplication: UpdateIndividualApplication, options?: any): AxiosPromise<RestDataResponseUpdateApplicationStatusMerchantApplicationDto> {
            return localVarFp.updateIndividualApplication(updateIndividualApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantApi - object-oriented interface
 * @export
 * @class MerchantApi
 * @extends {BaseAPI}
 */
export class MerchantApi extends BaseAPI {
    /**
     * 
     * @param {CreateCompanyApplication} createCompanyApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public createCompanyApplication(createCompanyApplication: CreateCompanyApplication, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).createCompanyApplication(createCompanyApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateIndividualApplication} createIndividualApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public createIndividualApplication(createIndividualApplication: CreateIndividualApplication, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).createIndividualApplication(createIndividualApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public createMerchantForUser(options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).createMerchantForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public findMerchantByUser(options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).findMerchantByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationIdRequest} applicationIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public getVerificationTokenForApplication(applicationIdRequest: ApplicationIdRequest, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).getVerificationTokenForApplication(applicationIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public getVerificationTokenForMerchant(options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).getVerificationTokenForMerchant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationIdRequest} applicationIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public revokeApplication(applicationIdRequest: ApplicationIdRequest, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).revokeApplication(applicationIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationIdRequest} applicationIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public submitApplication(applicationIdRequest: ApplicationIdRequest, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).submitApplication(applicationIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyApplication} updateCompanyApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public updateCompanyApplication(updateCompanyApplication: UpdateCompanyApplication, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).updateCompanyApplication(updateCompanyApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateIndividualApplication} updateIndividualApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantApi
     */
    public updateIndividualApplication(updateIndividualApplication: UpdateIndividualApplication, options?: AxiosRequestConfig) {
        return MerchantApiFp(this.configuration).updateIndividualApplication(updateIndividualApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetStoreOrdersRestRequest} getStoreOrdersRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreOrders: async (getStoreOrdersRestRequest: GetStoreOrdersRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getStoreOrdersRestRequest' is not null or undefined
            assertParamExists('getStoreOrders', 'getStoreOrdersRestRequest', getStoreOrdersRestRequest)
            const localVarPath = `/wpay/website-api/v1/order/get-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStoreOrdersRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetStoreOrdersAsFileRestRequest} getStoreOrdersAsFileRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreOrdersAsFile: async (getStoreOrdersAsFileRestRequest: GetStoreOrdersAsFileRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getStoreOrdersAsFileRestRequest' is not null or undefined
            assertParamExists('getStoreOrdersAsFile', 'getStoreOrdersAsFileRestRequest', getStoreOrdersAsFileRestRequest)
            const localVarPath = `/wpay/website-api/v1/order/get-list-as-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStoreOrdersAsFileRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetStoreOrdersRestRequest} getStoreOrdersRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreOrders(getStoreOrdersRestRequest: GetStoreOrdersRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreOrders(getStoreOrdersRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetStoreOrdersAsFileRestRequest} getStoreOrdersAsFileRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreOrdersAsFile(getStoreOrdersAsFileRestRequest: GetStoreOrdersAsFileRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreOrdersAsFile(getStoreOrdersAsFileRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @param {GetStoreOrdersRestRequest} getStoreOrdersRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreOrders(getStoreOrdersRestRequest: GetStoreOrdersRestRequest, options?: any): AxiosPromise<RestDataResponseGetStoreOrdersStatusGetStoreOrdersResponse> {
            return localVarFp.getStoreOrders(getStoreOrdersRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetStoreOrdersAsFileRestRequest} getStoreOrdersAsFileRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreOrdersAsFile(getStoreOrdersAsFileRestRequest: GetStoreOrdersAsFileRestRequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getStoreOrdersAsFile(getStoreOrdersAsFileRestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {GetStoreOrdersRestRequest} getStoreOrdersRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getStoreOrders(getStoreOrdersRestRequest: GetStoreOrdersRestRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getStoreOrders(getStoreOrdersRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetStoreOrdersAsFileRestRequest} getStoreOrdersAsFileRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getStoreOrdersAsFile(getStoreOrdersAsFileRestRequest: GetStoreOrdersAsFileRestRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).getStoreOrdersAsFile(getStoreOrdersAsFileRestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PayoutApi - axios parameter creator
 * @export
 */
export const PayoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalculatePayoutRestRequest} calculatePayoutRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePayoutAmount: async (calculatePayoutRestRequest: CalculatePayoutRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculatePayoutRestRequest' is not null or undefined
            assertParamExists('calculatePayoutAmount', 'calculatePayoutRestRequest', calculatePayoutRestRequest)
            const localVarPath = `/wpay/website-api/v1/payout/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculatePayoutRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AutoPayoutSubscribeRequest} autoPayoutSubscribeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureAutoPayout: async (autoPayoutSubscribeRequest: AutoPayoutSubscribeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'autoPayoutSubscribeRequest' is not null or undefined
            assertParamExists('configureAutoPayout', 'autoPayoutSubscribeRequest', autoPayoutSubscribeRequest)
            const localVarPath = `/wpay/website-api/v1/payout/configure-autopayout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(autoPayoutSubscribeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetStorePayoutsRestRequest} getStorePayoutsRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutList: async (getStorePayoutsRestRequest: GetStorePayoutsRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getStorePayoutsRestRequest' is not null or undefined
            assertParamExists('getPayoutList', 'getStorePayoutsRestRequest', getStorePayoutsRestRequest)
            const localVarPath = `/wpay/website-api/v1/payout/get-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStorePayoutsRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetStorePayoutsAsFileRestRequest} getStorePayoutsAsFileRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutListAsFile: async (getStorePayoutsAsFileRestRequest: GetStorePayoutsAsFileRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getStorePayoutsAsFileRestRequest' is not null or undefined
            assertParamExists('getPayoutListAsFile', 'getStorePayoutsAsFileRestRequest', getStorePayoutsAsFileRestRequest)
            const localVarPath = `/wpay/website-api/v1/payout/get-list-as-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStorePayoutsAsFileRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} payoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutStatus: async (payoutId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payoutId' is not null or undefined
            assertParamExists('getPayoutStatus', 'payoutId', payoutId)
            const localVarPath = `/wpay/website-api/v1/payout/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (payoutId !== undefined) {
                localVarQueryParameter['payout-id'] = payoutId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProceedPayoutRestRequest} proceedPayoutRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proceedPayout: async (proceedPayoutRestRequest: ProceedPayoutRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proceedPayoutRestRequest' is not null or undefined
            assertParamExists('proceedPayout', 'proceedPayoutRestRequest', proceedPayoutRestRequest)
            const localVarPath = `/wpay/website-api/v1/payout/proceed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proceedPayoutRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProceedPayoutForAllAccountsRestRequest} proceedPayoutForAllAccountsRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proceedPayoutForAllAccounts: async (proceedPayoutForAllAccountsRestRequest: ProceedPayoutForAllAccountsRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proceedPayoutForAllAccountsRestRequest' is not null or undefined
            assertParamExists('proceedPayoutForAllAccounts', 'proceedPayoutForAllAccountsRestRequest', proceedPayoutForAllAccountsRestRequest)
            const localVarPath = `/wpay/website-api/v1/payout/proceed-all-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proceedPayoutForAllAccountsRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayoutApi - functional programming interface
 * @export
 */
export const PayoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalculatePayoutRestRequest} calculatePayoutRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePayoutAmount(calculatePayoutRestRequest: CalculatePayoutRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseCalculatePayoutStatusPayoutAmountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculatePayoutAmount(calculatePayoutRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AutoPayoutSubscribeRequest} autoPayoutSubscribeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configureAutoPayout(autoPayoutSubscribeRequest: AutoPayoutSubscribeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configureAutoPayout(autoPayoutSubscribeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetStorePayoutsRestRequest} getStorePayoutsRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayoutList(getStorePayoutsRestRequest: GetStorePayoutsRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseGetPayoutListStatusPayoutListRestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayoutList(getStorePayoutsRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetStorePayoutsAsFileRestRequest} getStorePayoutsAsFileRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayoutListAsFile(getStorePayoutsAsFileRestRequest: GetStorePayoutsAsFileRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayoutListAsFile(getStorePayoutsAsFileRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} payoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayoutStatus(payoutId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseGetPayoutStatusPayoutDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayoutStatus(payoutId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProceedPayoutRestRequest} proceedPayoutRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proceedPayout(proceedPayoutRestRequest: ProceedPayoutRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseProceedPayoutStatusPayoutDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proceedPayout(proceedPayoutRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProceedPayoutForAllAccountsRestRequest} proceedPayoutForAllAccountsRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proceedPayoutForAllAccounts(proceedPayoutForAllAccountsRestRequest: ProceedPayoutForAllAccountsRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseProceedPayoutStatusListPayoutDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proceedPayoutForAllAccounts(proceedPayoutForAllAccountsRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayoutApi - factory interface
 * @export
 */
export const PayoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayoutApiFp(configuration)
    return {
        /**
         * 
         * @param {CalculatePayoutRestRequest} calculatePayoutRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePayoutAmount(calculatePayoutRestRequest: CalculatePayoutRestRequest, options?: any): AxiosPromise<RestDataResponseCalculatePayoutStatusPayoutAmountDto> {
            return localVarFp.calculatePayoutAmount(calculatePayoutRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AutoPayoutSubscribeRequest} autoPayoutSubscribeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureAutoPayout(autoPayoutSubscribeRequest: AutoPayoutSubscribeRequest, options?: any): AxiosPromise<RestDataResponseSubscribeAutoPayoutStatusAutoPayoutSubscribeRestResponse> {
            return localVarFp.configureAutoPayout(autoPayoutSubscribeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetStorePayoutsRestRequest} getStorePayoutsRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutList(getStorePayoutsRestRequest: GetStorePayoutsRestRequest, options?: any): AxiosPromise<RestDataResponseGetPayoutListStatusPayoutListRestResponse> {
            return localVarFp.getPayoutList(getStorePayoutsRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetStorePayoutsAsFileRestRequest} getStorePayoutsAsFileRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutListAsFile(getStorePayoutsAsFileRestRequest: GetStorePayoutsAsFileRestRequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPayoutListAsFile(getStorePayoutsAsFileRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} payoutId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayoutStatus(payoutId: string, options?: any): AxiosPromise<RestDataResponseGetPayoutStatusPayoutDto> {
            return localVarFp.getPayoutStatus(payoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProceedPayoutRestRequest} proceedPayoutRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proceedPayout(proceedPayoutRestRequest: ProceedPayoutRestRequest, options?: any): AxiosPromise<RestDataResponseProceedPayoutStatusPayoutDto> {
            return localVarFp.proceedPayout(proceedPayoutRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProceedPayoutForAllAccountsRestRequest} proceedPayoutForAllAccountsRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proceedPayoutForAllAccounts(proceedPayoutForAllAccountsRestRequest: ProceedPayoutForAllAccountsRestRequest, options?: any): AxiosPromise<RestDataResponseProceedPayoutStatusListPayoutDto> {
            return localVarFp.proceedPayoutForAllAccounts(proceedPayoutForAllAccountsRestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayoutApi - object-oriented interface
 * @export
 * @class PayoutApi
 * @extends {BaseAPI}
 */
export class PayoutApi extends BaseAPI {
    /**
     * 
     * @param {CalculatePayoutRestRequest} calculatePayoutRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public calculatePayoutAmount(calculatePayoutRestRequest: CalculatePayoutRestRequest, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).calculatePayoutAmount(calculatePayoutRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AutoPayoutSubscribeRequest} autoPayoutSubscribeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public configureAutoPayout(autoPayoutSubscribeRequest: AutoPayoutSubscribeRequest, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).configureAutoPayout(autoPayoutSubscribeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetStorePayoutsRestRequest} getStorePayoutsRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public getPayoutList(getStorePayoutsRestRequest: GetStorePayoutsRestRequest, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).getPayoutList(getStorePayoutsRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetStorePayoutsAsFileRestRequest} getStorePayoutsAsFileRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public getPayoutListAsFile(getStorePayoutsAsFileRestRequest: GetStorePayoutsAsFileRestRequest, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).getPayoutListAsFile(getStorePayoutsAsFileRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} payoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public getPayoutStatus(payoutId: string, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).getPayoutStatus(payoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProceedPayoutRestRequest} proceedPayoutRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public proceedPayout(proceedPayoutRestRequest: ProceedPayoutRestRequest, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).proceedPayout(proceedPayoutRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProceedPayoutForAllAccountsRestRequest} proceedPayoutForAllAccountsRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApi
     */
    public proceedPayoutForAllAccounts(proceedPayoutForAllAccountsRestRequest: ProceedPayoutForAllAccountsRestRequest, options?: AxiosRequestConfig) {
        return PayoutApiFp(this.configuration).proceedPayoutForAllAccounts(proceedPayoutForAllAccountsRestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Merchant website settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wpay/website-api/v1/settings/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Merchant website settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * Merchant website settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppSettings(options?: any): AxiosPromise<AppSettingsDto> {
            return localVarFp.getAppSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * Merchant website settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getAppSettings(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getAppSettings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateStoreRestRequest} createStoreRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore: async (createStoreRestRequest: CreateStoreRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStoreRestRequest' is not null or undefined
            assertParamExists('createStore', 'createStoreRestRequest', createStoreRestRequest)
            const localVarPath = `/wpay/website-api/v1/store/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStoreRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DecryptApiKeyRestRequest} decryptApiKeyRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decryptApiKey: async (decryptApiKeyRestRequest: DecryptApiKeyRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'decryptApiKeyRestRequest' is not null or undefined
            assertParamExists('decryptApiKey', 'decryptApiKeyRestRequest', decryptApiKeyRestRequest)
            const localVarPath = `/wpay/website-api/v1/store/api-key/decrypt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(decryptApiKeyRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStore: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStore', 'storeId', storeId)
            const localVarPath = `/wpay/website-api/v1/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (storeId !== undefined) {
                localVarQueryParameter['store-id'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateApiKeyRestRequest} createApiKeyRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateApiKey: async (createApiKeyRestRequest: CreateApiKeyRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createApiKeyRestRequest' is not null or undefined
            assertParamExists('recreateApiKey', 'createApiKeyRestRequest', createApiKeyRestRequest)
            const localVarPath = `/wpay/website-api/v1/store/api-key/recreate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createApiKeyRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RenameStoreRestRequest} renameStoreRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameStore: async (renameStoreRestRequest: RenameStoreRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'renameStoreRestRequest' is not null or undefined
            assertParamExists('renameStore', 'renameStoreRestRequest', renameStoreRestRequest)
            const localVarPath = `/wpay/website-api/v1/store/rename`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(renameStoreRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWebhookConfigRestRequest} updateWebhookConfigRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhookConfig: async (updateWebhookConfigRestRequest: UpdateWebhookConfigRestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWebhookConfigRestRequest' is not null or undefined
            assertParamExists('updateWebhookConfig', 'updateWebhookConfigRestRequest', updateWebhookConfigRestRequest)
            const localVarPath = `/wpay/website-api/v1/store/webhook-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtUserToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWebhookConfigRestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateStoreRestRequest} createStoreRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStore(createStoreRestRequest: CreateStoreRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseCreateStoreStatusStorePreviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStore(createStoreRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DecryptApiKeyRestRequest} decryptApiKeyRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async decryptApiKey(decryptApiKeyRestRequest: DecryptApiKeyRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.decryptApiKey(decryptApiKeyRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStore(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseGetStoreStatusStoreDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateApiKeyRestRequest} createApiKeyRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateApiKey(createApiKeyRestRequest: CreateApiKeyRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recreateApiKey(createApiKeyRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RenameStoreRestRequest} renameStoreRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameStore(renameStoreRestRequest: RenameStoreRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseRenameStoreStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameStore(renameStoreRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWebhookConfigRestRequest} updateWebhookConfigRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebhookConfig(updateWebhookConfigRestRequest: UpdateWebhookConfigRestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestResponseUpdateWebhookConfigStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhookConfig(updateWebhookConfigRestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateStoreRestRequest} createStoreRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStore(createStoreRestRequest: CreateStoreRestRequest, options?: any): AxiosPromise<RestDataResponseCreateStoreStatusStorePreviewDto> {
            return localVarFp.createStore(createStoreRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DecryptApiKeyRestRequest} decryptApiKeyRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decryptApiKey(decryptApiKeyRestRequest: DecryptApiKeyRestRequest, options?: any): AxiosPromise<RestDataResponseDecryptApiKeyStatusDecryptedStoreApiKeyDto> {
            return localVarFp.decryptApiKey(decryptApiKeyRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStore(storeId: string, options?: any): AxiosPromise<RestDataResponseGetStoreStatusStoreDto> {
            return localVarFp.getStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateApiKeyRestRequest} createApiKeyRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateApiKey(createApiKeyRestRequest: CreateApiKeyRestRequest, options?: any): AxiosPromise<RestDataResponseCreateApiKeyStatusDecryptedStoreApiKeyDto> {
            return localVarFp.recreateApiKey(createApiKeyRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RenameStoreRestRequest} renameStoreRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameStore(renameStoreRestRequest: RenameStoreRestRequest, options?: any): AxiosPromise<RestResponseRenameStoreStatus> {
            return localVarFp.renameStore(renameStoreRestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWebhookConfigRestRequest} updateWebhookConfigRestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhookConfig(updateWebhookConfigRestRequest: UpdateWebhookConfigRestRequest, options?: any): AxiosPromise<RestResponseUpdateWebhookConfigStatus> {
            return localVarFp.updateWebhookConfig(updateWebhookConfigRestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * 
     * @param {CreateStoreRestRequest} createStoreRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public createStore(createStoreRestRequest: CreateStoreRestRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).createStore(createStoreRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DecryptApiKeyRestRequest} decryptApiKeyRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public decryptApiKey(decryptApiKeyRestRequest: DecryptApiKeyRestRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).decryptApiKey(decryptApiKeyRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStore(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateApiKeyRestRequest} createApiKeyRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public recreateApiKey(createApiKeyRestRequest: CreateApiKeyRestRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).recreateApiKey(createApiKeyRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RenameStoreRestRequest} renameStoreRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public renameStore(renameStoreRestRequest: RenameStoreRestRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).renameStore(renameStoreRestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateWebhookConfigRestRequest} updateWebhookConfigRestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateWebhookConfig(updateWebhookConfigRestRequest: UpdateWebhookConfigRestRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).updateWebhookConfig(updateWebhookConfigRestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


