import { AppContext, SnackbarProps } from './AppContext';
import { useDidUpdate } from './hooks/useDidUpdate';
import { useLocale } from './hooks/useLocale';
import { muiLocales } from './i18n';
import { muiTheme } from './muiTheme';
import { routePaths } from './router';
import { useAppSelector } from './store';
import { Snackbar, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

const SNACKBAR_TRANSITION_DURATION = 250;
const DEFAULT_SNACKBAR_DURATION = 3000;

export const App = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarProps = useRef<SnackbarProps | null>();
  const { logoutReason, jwtToken } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const themeWithLocale = useMemo(
    () => createTheme(muiTheme, muiLocales[locale]),
    [locale],
  );

  useEffect(() => {
    document.documentElement.setAttribute('lang', locale);
  }, [locale]);

  const showSnackbar = useCallback(
    ({
      message,
      autoHideDuration = DEFAULT_SNACKBAR_DURATION,
    }: SnackbarProps) => {
      function showInner() {
        snackbarProps.current = {
          message,
          autoHideDuration,
        };
        setSnackbarOpen(true);
      }
      if (snackbarOpen) {
        setSnackbarOpen(false);
        setTimeout(() => {
          showInner();
        }, SNACKBAR_TRANSITION_DURATION);
      } else {
        showInner();
      }
    },
    [snackbarOpen],
  );

  useDidUpdate(() => {
    if (logoutReason) {
      showSnackbar({ message: t(`logout_reasons.${logoutReason}`) });
      navigate(routePaths.ROOT, { replace: true });
    }
  }, [logoutReason]);

  useDidUpdate(() => {
    queryClient.clear();
  }, [jwtToken]);

  return (
    <ThemeProvider theme={themeWithLocale}>
      <AppContext.Provider value={{ showSnackbar }}>
        <div className="antialiased">
          <Outlet />
          <Snackbar
            transitionDuration={SNACKBAR_TRANSITION_DURATION}
            open={snackbarOpen}
            autoHideDuration={snackbarProps.current?.autoHideDuration}
            onClose={(event, reason) => {
              if (reason === 'timeout') {
                setSnackbarOpen(false);
                snackbarProps.current = null;
              }
            }}
            message={snackbarProps.current?.message}
          />
        </div>
      </AppContext.Provider>
    </ThemeProvider>
  );
};
