import en from './i18n/en';
import ru from './i18n/ru';
import { Localization, enUS, ruRU } from '@mui/material/locale';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export type Locales = 'ru' | 'en';

export const locales: Record<Locales, Locales> = {
  en: 'en',
  ru: 'ru',
};

/**
 * en-US => en; en-EN => en; ru-RU => ru; en => en; ru => ru;
 */
export const getLocale = (languageCode: string) => {
  return languageCode.split('-')[0] as Locales;
};

export const FALLBACK_LANGUAGE = locales['en'];

export const muiLocales: Record<Locales, Localization> = {
  en: enUS,
  ru: ruRU,
};

export const localeNames: Record<Locales, string> = {
  en: 'EN',
  ru: 'RU',
};

const resources: Record<Locales, { translation: Record<string, unknown> }> = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
    },
    resources,
    nonExplicitSupportedLngs: true,
    fallbackLng: FALLBACK_LANGUAGE,
    supportedLngs: Object.values(locales),
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
