import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { LogoutReasons, logout } from 'reducers/userSlice';
import { store } from 'store';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const jwtToken = store.getState().user.jwtToken;

  if (jwtToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${jwtToken}`,
      },
    };
  }
  return config;
};

const onResponseError = async (error: AxiosError) => {
  if (error.response?.status === 401) {
    store.dispatch(logout(LogoutReasons.expired));
  }

  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest);
  axiosInstance.interceptors.response.use(undefined, onResponseError);
  return axiosInstance;
}
