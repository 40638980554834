import { Button, ButtonProps, Skeleton } from '@mui/material';
import { FC } from 'react';

export const MUIButtonSkeleton: FC<ButtonProps> = ({ sx, ...props }) => {
  return (
    <button disabled className="relative">
      <div className="absolute left-0 top-0 w-full h-full">
        <Skeleton variant="rounded" width="100%" height="100%" />
      </div>
      <Button
        {...props}
        component="span"
        disabled
        sx={{ visibility: 'hidden', ...sx }}
      />
    </button>
  );
};
