import { Avatar } from '@mui/material';
import { FC } from 'react';
import { isUnicode } from 'utils/common';

const bgColors = [
  ['#ff885e', '#ff516a'], // red
  ['#ffcd6a', '#ffa85c'], // orange
  ['#82b1ff', '#665fff'], // purple
  ['#a0de7e', '#54cb68'], // green
  ['#53edd6', '#28c9b7'], // cyan
  ['#72d5fd', '#2a9ef1'], // blue
  ['#e0a2f3', '#d669ed'], // pink
];

export const InitialsAvatar: FC<{
  name: string;
  id: number;
  size?: number;
}> = ({ name, id, size = 40 }) => {
  const bgIndex = Number(id) % 7;
  const [topColor, bottomColor] = bgColors[bgIndex];

  const [firstWord = '', secondWord = ''] = name.split(' ');

  return (
    <Avatar
      sx={{
        background: `linear-gradient(180deg, ${topColor} 0%, ${bottomColor} 100%)`,
        fontWeight: 700,
        fontSize: 18,
        width: size,
        height: size,
      }}
    >
      {isUnicode(firstWord.charAt(0)) &&
        firstWord.charAt(0).toLocaleUpperCase()}
      {isUnicode(secondWord.charAt(0)) &&
        secondWord.charAt(0).toLocaleUpperCase()}
    </Avatar>
  );
};
