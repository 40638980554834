import { setStoreId } from '../../reducers/userSlice';
import { useAppDispatch } from '../../store';
import { StoreError } from './StoreError';
import {
  Add,
  ContentPaste,
  CreditCard,
  ExpandLess,
  ExpandMore,
  Settings,
} from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { PageContainer } from 'components/PageContainer';
import { CreateStoreModal } from 'containers/CreateStoreModal';
import { StoreAvatar } from 'containers/StoreAvatar';
import { useMerchant } from 'queries/merchant/useMerchant';
import { useStore } from 'queries/store/useStore';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Outlet,
  generatePath,
  useMatch,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { routePaths } from 'router';

const NavItem: FC<{ to: string; icon: ReactNode }> = ({
  to,
  icon,
  children,
}) => {
  const [searchParams] = useSearchParams();
  const exactMatch = useMatch(to);
  const { currency, id } = useParams();

  return (
    <ListItemButton
      sx={{ borderRadius: 2 }}
      component={Link}
      to={to}
      selected={
        !searchParams.get('onboarding') &&
        (!!exactMatch ||
          (to === generatePath(routePaths.STORE, { id }) && !!currency))
      }
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItemButton>
  );
};

export const Store: FC = () => {
  const { t } = useTranslation();
  const [storeListExpanded, setStoreListExpanded] = useState(false);
  const [createStoreModalOpened, setCreateStoreModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data: store, isLoading } = useStore(id);
  const { data: merchant } = useMerchant();

  if (store === 'ACCESS_DENIED' || store === 'NOT_FOUND') {
    return <StoreError reason={store} />;
  }

  const storeIsLoading = !store || isLoading;

  return (
    <PageContainer stretch className="overflow-hidden flex">
      <Paper elevation={1} square className="w-64 shrink-0 self-stretch px-2">
        <List component="div">
          <div className="mb-3">
            <ListItemButton
              sx={{ borderRadius: 2, px: 1.5, py: 1.5 }}
              onClick={() => {
                setStoreListExpanded((value) => !value);
              }}
            >
              <ListItemAvatar>
                {storeIsLoading ? (
                  <Skeleton variant="circular">
                    <Avatar />
                  </Skeleton>
                ) : (
                  <StoreAvatar storeId={store.id} storeName={store.name} />
                )}
              </ListItemAvatar>
              <ListItemText>
                {storeIsLoading ? (
                  <Skeleton variant="rounded" />
                ) : (
                  <div
                    title={store.name}
                    className="overflow-ellipsis overflow-hidden whitespace-nowrap"
                  >
                    {store.name}
                  </div>
                )}
              </ListItemText>
              {storeListExpanded ? (
                <ExpandLess className="opacity-[0.38]" />
              ) : (
                <ExpandMore className="opacity-[0.38]" />
              )}
            </ListItemButton>

            <Collapse in={storeListExpanded} timeout="auto" unmountOnExit>
              <List disablePadding>
                {merchant &&
                  merchant.stores
                    .filter((store) => store.id !== id)
                    .map((store) => {
                      return (
                        <ListItemButton
                          component={Link}
                          to={generatePath(routePaths.STORE, {
                            id: store.id,
                          })}
                          key={store.id}
                          sx={{ borderRadius: 2, px: 1.5, py: 1.5 }}
                          onClick={() => {
                            setStoreListExpanded(false);
                            dispatch(setStoreId(store.id));
                          }}
                        >
                          <ListItemAvatar>
                            <StoreAvatar
                              storeId={store.id}
                              storeName={store.name}
                            />
                          </ListItemAvatar>
                          <ListItemText>
                            <div
                              title={store.name}
                              className="overflow-ellipsis overflow-hidden whitespace-nowrap"
                            >
                              {store.name}
                            </div>
                          </ListItemText>
                        </ListItemButton>
                      );
                    })}
                <ListItemButton
                  sx={{ borderRadius: 2, px: 1.5, py: 1.5 }}
                  onClick={() => setCreateStoreModalOpened(true)}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ background: 'rgba(0, 0, 0, 0.04)' }}>
                      <Add color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="button" color="primary">
                      {t('store.add')}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          </div>
          <NavItem
            to={generatePath(routePaths.STORE, { id })}
            icon={<CreditCard />}
          >
            {t('store.balance')}
          </NavItem>
          <NavItem
            to={generatePath(routePaths.STORE_ORDERS, { id })}
            icon={<ContentPaste />}
          >
            {t('store.orders')}
          </NavItem>
          <NavItem
            to={generatePath(routePaths.STORE_SETTINGS, { id })}
            icon={<Settings />}
          >
            {t('store.settings')}
          </NavItem>
        </List>
      </Paper>
      <Outlet />
      <CreateStoreModal
        open={createStoreModalOpened}
        onClose={() => {
          setCreateStoreModalOpened(false);
          setStoreListExpanded(false);
        }}
      />
    </PageContainer>
  );
};
