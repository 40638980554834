import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import * as dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AvailableBalanceHint: FC<{ fundsHoldingDuration: string }> = ({
  fundsHoldingDuration,
}) => {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <Tooltip
      placement="top"
      arrow
      title={
        <div className="whitespace-pre-line font-normal text-[13px] leading-[18px]">
          {t('balance.available_hint', {
            fundsHoldingDuration: dayjs
              .duration(fundsHoldingDuration)
              .locale(locale)
              .humanize(),
          })}
        </div>
      }
    >
      <div
        className="opacity-[0.72] relative -top-px"
        style={{ color: 'rgba(0, 0, 0, 0.6)' }}
      >
        <InfoOutlined fontSize="small" />
      </div>
    </Tooltip>
  );
};
