import { ChevronRight } from '@mui/icons-material';
import { Avatar, ButtonBase, Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';
import { CurrencyLogo } from 'containers/CurrencyLogo';
import { useLocale } from 'hooks/useLocale';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Currency } from 'types';
import { printCryptoAmount, printFiatAmount } from 'utils/amount';
import { isNumber } from 'utils/number';

const BalanceByAssetCellBase: FC<{
  start: ReactNode;
  end?: ReactNode;
  value: ReactNode;
  bottom: ReactNode;
}> = ({ start, end, value, bottom }) => {
  return (
    <div className="flex justify-start items-center grow">
      <div className="shrink-0 pr-4">{start}</div>
      <div className="grow flex">
        <div className="grow py-1">
          <Typography variant="body1">{value}</Typography>
          <Typography variant="body2">
            <span className="opacity-60">{bottom}</span>
          </Typography>
        </div>
        {end && <div className="shrink-0 p-3">{end}</div>}
      </div>
    </div>
  );
};

export const BalanceByAssetCell: FC<{
  currency: Currency;
  amount: number;
  fiatAmount?: number;
  fiatCurrency?: string;
  className?: string;
  to?: string;
}> = ({ currency, amount, fiatAmount, fiatCurrency, className, to }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const base = (
    <BalanceByAssetCellBase
      start={<CurrencyLogo currency={currency} />}
      end={to && <ChevronRight className="opacity-[0.58]" />}
      value={printCryptoAmount({
        amount,
        currency,
        languageCode: locale,
      })}
      bottom={
        isNumber(fiatAmount) && fiatCurrency
          ? printFiatAmount({
              amount: fiatAmount,
              currency: fiatCurrency,
              languageCode: locale,
            })
          : t('common.fiat_unavailable')
      }
    />
  );

  if (to) {
    return (
      <div className={className}>
        <ButtonBase
          component={Link}
          to={to}
          sx={{
            width: '100%',
            px: 2.5,
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
          }}
        >
          {base}
        </ButtonBase>
      </div>
    );
  } else {
    return <div className={classNames('px-5', className)}>{base}</div>;
  }
};

export const BalanceByAssetCellSkeleton: FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div className={classNames('px-5', className)}>
      <BalanceByAssetCellBase
        start={
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        }
        end={<ChevronRight className="opacity-[0.2]" />}
        value={<Skeleton width={100} />}
        bottom={<Skeleton width={80} />}
      />
    </div>
  );
};
