import { CompanyAbout } from './CompanyAbout';
import { CompanyGeneral } from './CompanyGeneral';
import { IndividualAbout } from './IndividualAbout';
import { IndividualGeneral } from './IndividualGeneral';
import { LegalForm } from './LegalForm';
import { Stepper } from './Stepper';
import { Sumsub } from './Sumsub';
import { Button, Typography } from '@mui/material';
import { AppContext } from 'AppContext';
import API from 'api';
import {
  MerchantApplicationDtoStatusEnum,
  SavedQuestionnaireDto,
  SavedQuestionnaireDtoLegalFormEnum,
} from 'api/generated';
import { useFormik } from 'formik';
import { useSettings } from 'queries/settings/useSettings';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Prepares data for API request
 * @param questionnaire
 */
const questionnaireProvider: (
  questionnaire: Omit<SavedQuestionnaireDto, 'legalForm'>,
) => Omit<SavedQuestionnaireDto, 'legalForm'> = (questionnaire) => {
  const result = { ...questionnaire };

  if (result.businessActivity && result.businessActivity !== 'Other') {
    result.businessActivityDetails = undefined;
  }

  Object.entries(result).forEach(([key, value]) => {
    if (!value) {
      result[key as keyof Omit<SavedQuestionnaireDto, 'legalForm'>] = undefined;
    }
  });

  return result;
};

export const Questionnaire: FC<
  Partial<SavedQuestionnaireDto> & {
    applicationId: string;
    applicationStatus: MerchantApplicationDtoStatusEnum;
    onFinish: VoidFunction;
  }
> = ({
  email = '',
  residentialAddress = '',
  phoneNumber = '',
  legalForm,
  licenseNumber = '',
  expectedAveragePaymentAmount = '',
  businessActivity = '',
  businessActivityDetails = '',
  telegramBots = '',
  marketingChannels = '',
  expectedRevenue = '',
  applicationId,
  applicationStatus,
  onFinish,
}) => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(AppContext);
  const { data: settings } = useSettings();

  let initialStep = 0;
  if (legalForm) {
    initialStep = 1;
  }
  if (residentialAddress && email && phoneNumber) {
    initialStep = 2;
  }
  if (
    applicationStatus === 'VERIFICATION_REQUIRED' ||
    applicationStatus === 'ON_VERIFICATION'
  ) {
    initialStep = 3;
  }

  const [activeStep, setActiveStep] = useState(initialStep);

  const onBackClick = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  function validate(values: Record<string, string | undefined>) {
    const errors: Record<string, string> = {};
    if (settings) {
      Object.entries(values).forEach(([key, value]) => {
        if (
          value &&
          value.length >
            settings.applicationQuestionnaireLimits.allStringFieldsMaxLength
        ) {
          errors[key] = t('questionnaire.max_length_error', {
            max: settings.applicationQuestionnaireLimits
              .allStringFieldsMaxLength,
          });
        }
      });
    }
    return errors;
  }

  async function updateApplication(
    legalForm: SavedQuestionnaireDtoLegalFormEnum,
    questionnaire: Omit<SavedQuestionnaireDto, 'legalForm'> = {},
  ) {
    questionnaire = questionnaireProvider(questionnaire);

    try {
      switch (legalForm) {
        case 'INDIVIDUAL':
          await API.Merchant.updateIndividualApplication({
            applicationId,
            questionnaire,
          });
          break;
        case 'COMPANY':
          await API.Merchant.updateCompanyApplication({
            applicationId,
            questionnaire,
          });
          break;
      }
    } catch (e) {
      showSnackbar({
        message: t('common.something_went_wrong'),
      });
    }
  }

  async function submitApplication(applicationId: string) {
    try {
      const submitResponse = await API.Merchant.submitApplication({
        applicationId,
      });

      switch (submitResponse.data.status) {
        case 'VERIFICATION_FAILED':
        case 'ACCESS_DENIED':
        case 'SUCCESS':
          onFinish && onFinish();
          break;
        case 'VERIFICATION_REQUIRED':
          setActiveStep(3);
      }
    } catch (e) {
      showSnackbar({
        message: t('common.something_went_wrong'),
      });
    }
  }

  const legalFormik = useFormik<{
    legalForm: SavedQuestionnaireDtoLegalFormEnum;
  }>({
    initialValues: {
      legalForm: legalForm || 'COMPANY',
    },
    onSubmit: async (values) => {
      await updateApplication(values.legalForm);
      setActiveStep(1);
    },
  });

  const generalFormik = useFormik<{
    phoneNumber?: string;
    email?: string;
    residentialAddress?: string;
  }>({
    initialValues: {
      phoneNumber,
      email,
      residentialAddress,
    },
    validate,
    onSubmit: async (values) => {
      await updateApplication(legalFormik.values.legalForm, values);
      setActiveStep(2);
    },
  });

  const companyAboutFormik = useFormik<{
    marketingChannels?: string;
    telegramBots?: string;
    businessActivity?: string;
    businessActivityDetails?: string;
    expectedAveragePaymentAmount?: string;
    licenseNumber?: string;
    expectedRevenue?: string;
  }>({
    initialValues: {
      marketingChannels,
      telegramBots,
      businessActivity,
      businessActivityDetails,
      expectedAveragePaymentAmount,
      licenseNumber,
      expectedRevenue,
    },
    validate,
    onSubmit: async (values) => {
      await updateApplication('COMPANY', {
        ...generalFormik.values,
        ...values,
      });
      await submitApplication(applicationId);
    },
  });

  const individualAboutFormik = useFormik<{
    marketingChannels?: string;
    telegramBots?: string;
    businessActivity?: string;
    businessActivityDetails?: string;
    expectedAveragePaymentAmount?: string;
    expectedRevenue?: string;
  }>({
    initialValues: {
      marketingChannels,
      telegramBots,
      businessActivity,
      businessActivityDetails,
      expectedAveragePaymentAmount,
      expectedRevenue,
    },
    validate,
    onSubmit: async (values) => {
      await updateApplication('INDIVIDUAL', {
        ...generalFormik.values,
        ...values,
      });
      await submitApplication(applicationId);
    },
  });

  return (
    <div className="py-4 lg:p-8">
      <Typography variant="h5">{t('questionnaire.title')}</Typography>
      <Stepper activeStep={activeStep} />

      {activeStep === 0 && <LegalForm formik={legalFormik} />}

      {activeStep === 1 && (
        <>
          {legalFormik.values.legalForm === 'COMPANY' && (
            <CompanyGeneral formik={generalFormik} onBack={onBackClick} />
          )}
          {legalFormik.values.legalForm === 'INDIVIDUAL' && (
            <IndividualGeneral formik={generalFormik} onBack={onBackClick} />
          )}
        </>
      )}

      {activeStep === 2 && (
        <>
          {legalFormik.values.legalForm === 'COMPANY' && (
            <CompanyAbout formik={companyAboutFormik} onBack={onBackClick} />
          )}
          {legalFormik.values.legalForm === 'INDIVIDUAL' && (
            <IndividualAbout
              formik={individualAboutFormik}
              onBack={onBackClick}
            />
          )}
        </>
      )}
      {activeStep === 3 && (
        <div>
          <Sumsub applicationId={applicationId} />
          <div className="mt-7 flex gap-3">
            <Button
              size="large"
              type="button"
              variant="outlined"
              onClick={async () => {
                await API.Merchant.revokeApplication({ applicationId });
                onBackClick();
              }}
            >
              {t('common.back')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
