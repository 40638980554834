export function IconInfoCircleFill24({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.43a9.43 9.43 0 1 0 0-18.86 9.43 9.43 0 0 0 0 18.86ZM13.07 7.5a1.07 1.07 0 1 1-2.14 0 1.07 1.07 0 0 1 2.14 0Zm-3.5 3.21c0-.4.32-.71.72-.71h1.92c.4 0 .72.32.72.71V16h1.21a.72.72 0 0 1 0 1.43H10.3a.72.72 0 1 1 0-1.43h1.2v-4.57h-1.2a.72.72 0 0 1-.72-.72Z"
        fill="currentColor"
      />
    </svg>
  );
}
