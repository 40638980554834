import { CreateStoreForm } from './CreateStoreForm';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { AppContext } from 'AppContext';
import API from 'api';
import { StorePreviewDto } from 'api/generated';
import { Modal } from 'components/Modal';
import { useMerchant } from 'queries/merchant/useMerchant';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from 'react-router-dom';
import { setStoreId } from 'reducers/userSlice';
import { routePaths } from 'router';
import { useAppDispatch } from 'store';

export const CreateStoreModal: FC<{
  open: boolean;
  onClose: VoidFunction;
}> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);
  const { showSnackbar } = useContext(AppContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { refetch } = useMerchant();

  const create: (params: {
    name: string;
    telegramBotUsername: string;
  }) => unknown = async ({ name, telegramBotUsername }) => {
    try {
      setCreating(true);
      const createStoreResponse = await API.Store.createStore({
        name,
        telegramBotUsername,
      });

      if (createStoreResponse.data.status === 'SUCCESS') {
        const store = createStoreResponse.data.data as StorePreviewDto;
        dispatch(setStoreId(store.id));
        await refetch();
        showSnackbar({
          autoHideDuration: 4000,
          message: t('create_store.created', { storeName: store.name }),
        });
        navigate({
          pathname: generatePath(routePaths.STORE, { id: store.id }),
          search: createSearchParams({
            onboarding: 'true',
          }).toString(),
        });
        onClose();
      } else {
        throw new Error();
      }
    } catch (e) {
      showSnackbar({
        autoHideDuration: 4000,
        message: t('common.something_went_wrong', { storeName: name }),
      });
    } finally {
      setCreating(false);
    }
  };

  return (
    <Modal className="px-6 pt-4 pb-2 w-[444px]" open={open} onClose={onClose}>
      <Typography variant="h6" mb={2}>
        {t('store.create_new')}
      </Typography>
      <CreateStoreForm
        onSubmit={create}
        renderActions={({ submit, valid }) => {
          return (
            <div className="flex justify-end mt-2 gap-2">
              <Button type="button" onClick={onClose}>
                {t('common.cancel')}
              </Button>
              <LoadingButton
                disabled={!valid}
                type="submit"
                onClick={submit}
                loading={creating}
              >
                {t('create_store.create')}
              </LoadingButton>
            </div>
          );
        }}
      />
    </Modal>
  );
};
