import { BusinessActivitySelect } from './BusinessActivitySelect';
import { LoadingButton } from '@mui/lab';
import { Button, InputAdornment, TextField } from '@mui/material';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const CompanyAbout: FC<{
  formik: FormikProps<{
    marketingChannels?: string;
    telegramBots?: string;
    businessActivity?: string;
    businessActivityDetails?: string;
    expectedAveragePaymentAmount?: string;
    licenseNumber?: string;
    expectedRevenue?: string;
  }>;
  onBack: VoidFunction;
}> = ({ formik, onBack }) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-6">
        <BusinessActivitySelect
          onChange={formik.handleChange}
          value={formik.values.businessActivity}
        />
        {formik.values.businessActivity === 'Other' && (
          <TextField
            name="businessActivityDetails"
            value={formik.values.businessActivityDetails}
            error={!!formik.errors.businessActivityDetails}
            helperText={formik.errors.businessActivityDetails}
            onChange={formik.handleChange}
            required
            placeholder={t('questionnaire.activity_details') as string}
          />
        )}
        <TextField
          name="expectedAveragePaymentAmount"
          value={formik.values.expectedAveragePaymentAmount}
          error={!!formik.errors.expectedAveragePaymentAmount}
          helperText={formik.errors.expectedAveragePaymentAmount}
          onChange={formik.handleChange}
          required
          placeholder={t('questionnaire.purchase_amount') as string}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
        <TextField
          name="expectedRevenue"
          value={formik.values.expectedRevenue}
          error={!!formik.errors.expectedRevenue}
          helperText={formik.errors.expectedRevenue}
          onChange={formik.handleChange}
          required
          placeholder={t('questionnaire.expected_revenue') as string}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
        <TextField
          name="licenseNumber"
          value={formik.values.licenseNumber}
          error={!!formik.errors.licenseNumber}
          helperText={formik.errors.licenseNumber}
          onChange={formik.handleChange}
          placeholder={t('questionnaire.license') as string}
        />
        <TextField
          multiline
          name="telegramBots"
          value={formik.values.telegramBots}
          error={!!formik.errors.telegramBots}
          helperText={formik.errors.telegramBots}
          onChange={formik.handleChange}
          required
          placeholder={t('questionnaire.telegram') as string}
        />
        <TextField
          multiline
          name="marketingChannels"
          value={formik.values.marketingChannels}
          error={!!formik.errors.marketingChannels}
          helperText={formik.errors.marketingChannels}
          onChange={formik.handleChange}
          required
          placeholder={t('questionnaire.marketing') as string}
        />
      </div>
      <div className="mt-7 flex gap-3">
        <Button size="large" type="button" variant="outlined" onClick={onBack}>
          {t('common.back')}
        </Button>
        <LoadingButton
          loading={formik.isSubmitting}
          type="submit"
          size="large"
          variant="contained"
          disabled={Object.keys(formik.errors).length > 0}
        >
          {t('common.next')}
        </LoadingButton>
      </div>
    </form>
  );
};
