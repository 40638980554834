import { Person } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { InitialsAvatar } from 'components/InitialsAvatar';
import { FC } from 'react';

export const UserAvatar: FC<{
  firstName?: string;
  lastName?: string;
  username?: string;
  size?: number;
  userId: number;
}> = ({ firstName, lastName, username, size, userId }) => {
  if (firstName && lastName) {
    return (
      <InitialsAvatar
        size={size}
        name={[firstName, lastName].join(' ')}
        id={userId}
      />
    );
  } else if (username) {
    return <InitialsAvatar size={size} name={username} id={userId} />;
  } else {
    return (
      <Avatar sx={{ width: size, height: size }}>
        <Person />
      </Avatar>
    );
  }
};
