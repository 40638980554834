import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TGUser } from 'utils/auth';

type UserSlice = {
  tgUser?: TGUser;
  jwtToken?: string;
  storeId?: string | null;
  logoutReason?: LogoutReasons;
};

const initialState: UserSlice = {};

export enum LogoutReasons {
  expired = 'EXPIRED',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.jwtToken = action.payload;
      state.logoutReason = undefined;
    },
    setTGUser: (state, action: PayloadAction<TGUser>) => {
      state.tgUser = action.payload;
    },
    setStoreId: (state, action: PayloadAction<string | null>) => {
      state.storeId = action.payload;
    },
    logout: (state, action: PayloadAction<LogoutReasons | undefined>) => {
      if (action.payload) {
        return {
          ...initialState,
          logoutReason: action.payload,
        };
      }
      return initialState;
    },
  },
});

export const { login, logout, setStoreId, setTGUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
