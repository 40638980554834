import { userReducer } from './userSlice';
import { withAppPrefix } from 'config';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: withAppPrefix('redux-persist'),
  keyPrefix: '',
  version: 1,
  storage,
};

export const rootReducer = persistReducer(
  persistConfig,
  combineReducers({ user: userReducer }),
);
