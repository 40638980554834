import { Add, ContentCopy, Launch } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { AppContext } from 'AppContext';
import API from 'api';
import { MUIButtonSkeleton } from 'components/MUIButtonSkeleton';
import { Placeholder } from 'components/Placeholder';
import { DOCUMENTATION_LINK } from 'config';
import { useLocale } from 'hooks/useLocale';
import { useTimeout } from 'hooks/useTimeout';
import { useStore, useStoreIfExists } from 'queries/store/useStore';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { copyToClipboard } from 'utils/copyToClipboard';

const API_KEY_ROW_ID = 1;

export const StoreIntegrationSettings: FC = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const gridApiRef = useGridApiRef();
  const [setApiKeyRowHighlightTimeout] = useTimeout();

  const [enablingWebhook, setEnablingWebhook] = useState(false);
  const [webhookEnabled, setWebhookEnabled] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');

  const [generatingApiKey, setGeneratingApiKey] = useState(false);
  const [apiKeyShown, setApiKeyShown] = useState(false);
  const [decryptedApiKey, setDecryptedApiKey] = useState('');

  const { showSnackbar } = useContext(AppContext);
  const { id } = useParams();
  const { data, refetch } = useStore(id);
  const store = useStoreIfExists(data);

  useEffect(() => {
    if (store) {
      setWebhookEnabled(store.webhookConfig.enabled);
      if (store.webhookConfig.url) {
        setWebhookUrl(store.webhookConfig.url);
      }
    }
  }, [store]);

  /**
   * Делаем декрипт последнего ключа, чтобы при копировании не ждать этого запроса.
   * В противном случае Safari отказывается класть ключ в буфер обмена.
   */
  useEffect(() => {
    if (store && store.apiKeys.length) {
      API.Store.decryptApiKey({
        storeId: store.id,
        apiKeyId: store.apiKeys[store.apiKeys.length - 1].id,
      }).then((response) => {
        if (response.data.data) {
          setDecryptedApiKey(response.data.data.decryptedValue);
        }
      });
    }
  }, [store]);

  async function generateAPIKey(alreadyHasApiKey: boolean) {
    if (store) {
      setGeneratingApiKey(true);
      try {
        const response = await API.Store.recreateApiKey({ storeId: store.id });
        if (response.data.data) {
          setDecryptedApiKey('');
          await refetch();
          showSnackbar({
            message: alreadyHasApiKey
              ? t('settings.api_key_regenerated')
              : t('settings.api_key_generated'),
          });
          gridApiRef.current?.selectRow(API_KEY_ROW_ID);
          setApiKeyRowHighlightTimeout(() => {
            gridApiRef.current?.selectRow(API_KEY_ROW_ID, false);
          }, 2000);
        } else {
          throw new Error();
        }
      } catch (e) {
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      } finally {
        setGeneratingApiKey(false);
      }
    }
  }

  async function copyAPIKey() {
    await copyToClipboard(decryptedApiKey);
    showSnackbar({
      message: t('settings.api_key_copied'),
    });
  }

  async function disableWebhook() {
    if (store) {
      try {
        await API.Store.updateWebhookConfig({
          storeId: store.id,
          newWebhookConfig: {
            enabled: false,
          },
        });
        await refetch();
        showSnackbar({
          message: t('settings.saved'),
        });
      } catch (e) {
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      }
    }
  }

  async function enableWebhook() {
    if (webhookUrl && store) {
      setEnablingWebhook(true);
      try {
        await API.Store.updateWebhookConfig({
          storeId: store.id,
          newWebhookConfig: {
            enabled: true,
            url: webhookUrl,
          },
        });
        await refetch();
        showSnackbar({
          message: t('settings.saved'),
        });
      } catch (e) {
        showSnackbar({
          message: t('common.something_went_wrong'),
        });
      } finally {
        setEnablingWebhook(false);
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'key',
      headerName: t('settings.api_key') as string,
      sortable: false,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="pt-1.5">
            <div className="font-mono font-medium text-sm">
              {apiKeyShown
                ? decryptedApiKey
                : `${params.value.decryptedPrefix}****`}
            </div>
            {apiKeyShown ? (
              <Button
                onClick={() => setApiKeyShown(false)}
                sx={{ left: -5, minWidth: 'auto' }}
                size="small"
              >
                {t('settings.hide_api_key')}
              </Button>
            ) : (
              <LoadingButton
                onClick={() => setApiKeyShown(true)}
                sx={{ left: -5, minWidth: 'auto' }}
                size="small"
              >
                {t('settings.show_api_key')}
              </LoadingButton>
            )}
          </div>
        );
      },
    },
    {
      field: 'copy',
      flex: 1,
      headerName: t('settings.api_copy') as string,
      sortable: false,
      renderCell: () => {
        return (
          <IconButton onClick={copyAPIKey} sx={{ marginInlineStart: -1 }}>
            <ContentCopy />
          </IconButton>
        );
      },
    },
    {
      field: 'created',
      headerName: t('settings.api_created') as string,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          minute: '2-digit',
          hour: '2-digit',
        }).format(new Date(params.value));
      },
    },
  ];

  if (store) {
    const apiKey = store.apiKeys[store.apiKeys.length - 1];

    return (
      <>
        <Typography variant="h5">{t('settings.api')}</Typography>
        <div className="flex gap-3 mt-5 mb-4">
          <LoadingButton
            startIcon={<Add />}
            variant="contained"
            loading={generatingApiKey}
            onClick={() => generateAPIKey(!!apiKey)}
          >
            {apiKey
              ? t('settings.regenerate_api_key')
              : t('settings.generate_api_key')}
          </LoadingButton>
          <Button
            component="a"
            href={DOCUMENTATION_LINK}
            target="_blank"
            startIcon={<Launch />}
          >
            {t('settings.documents')}
          </Button>
        </div>
        <DataGrid
          apiRef={gridApiRef}
          autoHeight={!!apiKey}
          disableRowSelectionOnClick
          rows={
            apiKey
              ? [
                  {
                    id: API_KEY_ROW_ID,
                    key: apiKey,
                    copy: apiKey.id,
                    created: apiKey.createdDatetime,
                  },
                ]
              : []
          }
          columns={columns}
          hideFooter
          disableColumnMenu
          slots={{
            noRowsOverlay: () => null,
          }}
          getRowHeight={() => 72}
          sx={{
            borderWidth: 0,
            height: apiKey ? undefined : 'auto',
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus':
              {
                outline: 'none',
              },
            '& .MuiDataGrid-row': {
              transition: 'background .3s ease',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent',
            },
          }}
        />
        {!apiKey && (
          <Placeholder
            title={t('settings.no_api_key_title')}
            text={t('settings.no_api_key_text')}
            icon="🔑"
            className="pt-10"
          />
        )}
        <Typography variant="h5" mt={6} mb={2.5}>
          {t('settings.webhooks')}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                onChange={async (e) => {
                  setWebhookEnabled(e.target.checked);
                  if (!e.target.checked && store.webhookConfig.enabled) {
                    await disableWebhook();
                  }
                }}
                checked={webhookEnabled}
              />
            }
            label={t('settings.enable_webhooks')}
          />
        </FormGroup>

        <Collapse in={webhookEnabled} timeout="auto" unmountOnExit>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await enableWebhook();
            }}
          >
            <div className="mb-6 mt-8 w-[570px]">
              <TextField
                label={t('settings.callback_url')}
                placeholder="https://"
                variant="outlined"
                fullWidth
                value={webhookUrl}
                onChange={(e) => {
                  setWebhookUrl(e.target.value);
                }}
              />
            </div>
            <LoadingButton
              type="submit"
              disabled={
                !webhookUrl.length ||
                (store.webhookConfig.enabled &&
                  store.webhookConfig.url === webhookUrl)
              }
              variant="contained"
              size="large"
              loading={enablingWebhook}
            >
              {t('settings.save')}
            </LoadingButton>
          </form>
        </Collapse>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h5">
          <Skeleton width={100} variant="rounded" />
        </Typography>
        <div className="flex gap-3 mt-5 mb-4">
          <MUIButtonSkeleton startIcon={<Add />} variant="contained">
            {t('settings.generate_api_key')}
          </MUIButtonSkeleton>
        </div>
        <Skeleton height={128} variant="rounded" />
        <Typography variant="h5" mt={6} mb={2.5}>
          <Skeleton width={300} variant="rounded" />
        </Typography>
        <FormControlLabel
          control={<Switch disabled />}
          label={
            <Typography variant="body1">
              {<Skeleton width={150} variant="rounded" />}
            </Typography>
          }
        />
      </>
    );
  }
};
